
import {
  AgentFilter,
  AgentLeaderboard,
  AgentSummaries,
  DefaultAgentFilter,
} from '../../api/agents/summary';

import Vue from 'vue';

export default Vue.component('agent-summary', {
  props: ['isMonthly', 'title', 'date'],
  data() {
    return {
      agentFilter: DefaultAgentFilter() as AgentFilter,
      data: {} as AgentSummaries,
      colors: [] as string[],
    };
  },
  mounted() {
    this.setColors();
    this.load();
  },
  methods: {
    getColors(i: number) {
      return this.colors[i] ?? 'yellow';
    },
    setColors() {
      let tempColors: string[] = [];
      for (let i = 100; i <= 255; i += 5) {
        for (let j = 0; j <= 255; j += 15) {
          for (let k = 0; k <= 255; k += 15) {
            const luma = 0.2126 * i + 0.7152 * j + 0.0722 * k;
            if (luma < 150) {
              continue;
            }
            tempColors.push(`rgb(${i}, ${j}, ${k})`);
          }
        }
      }
      // Randomize the colors.
      this.colors = tempColors
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
    },
    load() {
      if (!this.date) {
        return;
      }
      AgentLeaderboard.get(this.date, this.agentFilter).then((response) => {
        this.data = response;
        setTimeout(() => {
          this.load();
        }, 500000); // Five minutes.
      });
    },
  },
});
