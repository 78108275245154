import axios from 'axios';
import { CustomerFilter } from '../customers/customer_filter';
import { RateFilter } from '../rates/rate_filter';
import { TeeTime } from './tee_time';

export interface TeeTimeIndexFilters {
  agent: string;
  created_at: string;
  play_date: string;
  play_time: string;
  players: string;
  course_name: string;
  confirmation: string;
  customer: CustomerFilter;
  notes: string;
  status: string;
  rate: RateFilter;
}

export interface TeeTimeIndexOptions {
  sortBy: string[];
  sortDesc: boolean[];
  itemsPerPage: number;
  page: number;
  filters: TeeTimeIndexFilters;
  queryType?: string;
}

export class TeeTimeIndex {
  public static PER_PAGE: number = 30;

  public static async getCount(
    teeTimeIndexOptions?: TeeTimeIndexOptions,
  ): Promise<any> {
    return axios.get('/api/tee_times/count', {
      params: {
        filters: teeTimeIndexOptions?.filters,
        query_type: teeTimeIndexOptions?.queryType,
      },
    });
  }

  public static async getData(
    teeTimeIndexOptions?: TeeTimeIndexOptions,
  ): Promise<any> {
    if (teeTimeIndexOptions == null) {
      return axios.get('/api/tee_times/index', {
        params: {
          per_page: this.PER_PAGE,
        },
      });
    }

    return axios.get('/api/tee_times/index', {
      params: {
        per_page: teeTimeIndexOptions.itemsPerPage,
        sort_fields: teeTimeIndexOptions.sortBy,
        sort_desc: teeTimeIndexOptions.sortDesc,
        page: teeTimeIndexOptions.page,
        filters: teeTimeIndexOptions.filters,
        query_type: teeTimeIndexOptions?.queryType,
      },
    });
  }

  public static parseResponse(response: any): TeeTime[] {
    const teeTimeData = [] as TeeTime[];

    for (const k in response) {
      if (!response[k]) {
        continue;
      }

      const item = response[k];
      const customer = item.customers[0];
      const courseName = item.course ? item.course.course_name : '';
      const teeTime = {} as TeeTime;

      teeTime.id = item.id;
      teeTime.agent = item.agent;
      teeTime.created_at = item.created_at;
      teeTime.play_date = item.play_date;
      teeTime.play_time = item.play_time;
      teeTime.course_name = courseName;
      teeTime.confirmation = item.confirmation;
      teeTime.notes = item.notes;
      teeTime.internal_notes = item.internal_notes;
      teeTime.folio = item.folio;
      teeTime.customer = customer;
      teeTime.rate = item.golf_rate;
      teeTime.players = item.players;
      teeTime.transaction_record = item.transaction_record;
      teeTime.cost = item.cost;
      teeTime.status = item.status;
      teeTimeData.push(teeTime);
    }
    return teeTimeData;
  }
}
