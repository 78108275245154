export function formatDate(date: string): string {
  if (!date) {
    return '';
  }

  const [year, month, day] = date.split('-');
  return `${month}/${day}/${year}`;
}

export function parseDate(date: string): string {
  if (!date) {
    return '';
  }

  const [month, day, year] = date.split('/');
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
}
