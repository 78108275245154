import axios from 'axios';
import { Course } from '../courses/course';
import { RateDateRange } from '../rates/rate_date_range';

export interface RateCopyData {
  rateId: number;
  rateDateRanges: RateDateRange[];
  courses: Course[];
}

export class RateCopy {
  public static async saveData(rateCopyData: RateCopyData): Promise<any> {
    const rateIds = rateCopyData.rateDateRanges.map((r) => {
      if (!r.should_copy) {
        return;
      }
      return r.id;
    });
    const courseIds = rateCopyData.courses.map((c) => {
      return c.id;
    });
    return axios.put('/api/rate_date_ranges/copy', {
      golf_rate_id: rateCopyData.rateId,
      rate_ids: rateIds,
      course_ids: courseIds,
    });
  }
}
