export interface GolfnowCourse {
  id: number;
  golfnow_id: number;
}

const DEFAULT_GOLFNOW_COURSE: GolfnowCourse = {
  id: 0,
  golfnow_id: 0,
};

export function DefaultGolfnowCourse(): GolfnowCourse {
  return JSON.parse(JSON.stringify(DEFAULT_GOLFNOW_COURSE));
}
