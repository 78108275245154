import axios from 'axios';

export interface TeeTimeSummaryData {
  internal_revenue: string;
  external_revenue: string;
  misc_revenue: string;
  revenue: string;
  previous_internal_revenue: string;
  previous_external_revenue: string;
  previous_misc_revenue: string;
  previous_revenue: string;
  internal_top_courses: string[];
  external_top_courses: string[];
  internal_booking_count: number;
  external_booking_count: number;
  misc_booking_count: number;
  internal_round_count: number;
  external_round_count: number;
  misc_round_count: number;
  previous_internal_booking_count: number;
  previous_external_booking_count: number;
  previous_misc_booking_count: number;
  previous_internal_round_count: number;
  previous_external_round_count: number;
  previous_misc_round_count: number;
}

export class TeeTimeSummary {
  public static async getData(
    isMonthly: boolean,
    date: string,
  ): Promise<TeeTimeSummaryData> {
    const response = await axios.get(
      `/api/tee_times/summary/${isMonthly}/${date}`,
    );
    const summary = {} as TeeTimeSummaryData;
    summary.internal_top_courses = [];
    summary.external_top_courses = [];

    summary.internal_booking_count = response.data.internal_booking_count;
    summary.external_booking_count = response.data.external_booking_count;
    summary.misc_booking_count = response.data.misc_booking_count;
    summary.internal_round_count = response.data.internal_round_count;
    summary.external_round_count = response.data.external_round_count;
    summary.misc_round_count = response.data.misc_round_count;
    summary.previous_internal_revenue = response.data.previous_internal_revenue;
    summary.previous_external_revenue = response.data.previous_external_revenue;
    summary.previous_misc_revenue = response.data.previous_misc_revenue;
    summary.previous_revenue = response.data.previous_revenue;
    summary.internal_revenue = response.data.internal_revenue;
    summary.external_revenue = response.data.external_revenue;
    summary.misc_revenue = response.data.misc_revenue;
    summary.revenue = response.data.revenue;
    summary.previous_internal_booking_count =
      response.data.previous_internal_booking_count;
    summary.previous_external_booking_count =
      response.data.previous_external_booking_count;
    summary.previous_misc_booking_count =
      response.data.previous_misc_booking_count;
    summary.previous_internal_round_count =
      response.data.previous_internal_round_count;
    summary.previous_external_round_count =
      response.data.previous_external_round_count;
    summary.previous_misc_round_count = response.data.previous_misc_round_count;

    for (const course in response.data.internal_courses) {
      if (!course) {
        continue;
      }
      summary.internal_top_courses.push(course);
    }
    for (const course in response.data.external_courses) {
      if (!course) {
        continue;
      }
      summary.external_top_courses.push(course);
    }
    return summary;
  }
}
