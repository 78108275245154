import axios from 'axios';

export class GiftCertificateSaleConfigurationActions {
  public static async getData(): Promise<any> {
    return axios.get('/api/gift_certificates/sales');
  }
  public static parseResponse(response: any): GiftCertificateSaleConfiguration {
    const saleConfiguration = DefaultGiftCertificateSaleConfiguration();
    if (!response.data) {
      return saleConfiguration;
    }

    saleConfiguration.discount = Number(response.data.discount);

    return saleConfiguration;
  }

  public static async saveData(saleConfiguration: GiftCertificateSaleConfiguration) {
    return axios.put(`/api/gift_certificates/sales/`, {
      saleConfiguration,
    });
  }
}

export interface GiftCertificateSaleConfiguration {
  discount: number;
}

const DEFAULT_GIFT_CERTIFICATE_SALES_CONFIGURATION: GiftCertificateSaleConfiguration = {
  discount: 0,
};

export function DefaultGiftCertificateSaleConfiguration(): GiftCertificateSaleConfiguration {
  return JSON.parse(
    JSON.stringify(DEFAULT_GIFT_CERTIFICATE_SALES_CONFIGURATION),
  );
}
