
import Auth from '../api/auth/Auth';
import DiscountIndex from '../components/discounts/DiscountIndex.vue';
import Vue from 'vue';

Vue.component('discount-index', DiscountIndex);

export default Vue.extend({
  mounted() {
    if (!Auth.isAdmin()) {
      this.$router.push({ name: 'home' });
    }
  },
});
