
import Auth from '../api/auth/Auth';
import { Reporting } from '../api/reports/reporting';
import Vue from 'vue';

export default Vue.extend({
  methods: {
    getMaintenance() {
      Reporting.getMaintenance();
    },
    getOldRateSheet() {
      Reporting.getOldRateSheet();
    },
    getRateSheet() {
      Reporting.getRateSheet();
    },
  },
  computed: {
    isAdmin() {
      return Auth.isAdmin();
    },
  },
});
