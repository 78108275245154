import axios from 'axios';

export interface RicMessage {
  message: string;
}

const DEFAULT_RIC_MESSAGE: RicMessage = {
  message: '',
};

export function DefaultRicMessage(): RicMessage {
  return JSON.parse(JSON.stringify(DEFAULT_RIC_MESSAGE));
}

export class RicMessages {
  static get(): Promise<any> {
    return axios.get('/api/ric_messages/get');
  }

  static save(message: string) {
    return axios.put('/api/ric_messages/update', {
      message,
    });
  }
}
