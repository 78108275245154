
import { CourseList, CourseListItem } from '../../api/courses/list';
import Vue from 'vue';

export default Vue.component('course-check-boxes', {
  props: ['preExpand', 'readOnly', 'preselectedCourses', 'heading'],
  data() {
    return {
      courseList: [] as CourseListItem[],
      expanded: this.preExpand ? [0] : [],
    };
  },
  methods: {
    selectAll() {
      if (this.readOnly) {
        return;
      }
      this.courseList.forEach((course: CourseListItem) => {
        course.selected = true;
      });
      this.updateValue();
    },
    unselectAll() {
      if (this.readOnly) {
        return;
      }
      this.courseList.forEach((course: CourseListItem) => {
        course.selected = false;
      });
      this.updateValue();
    },
    updateValue() {
      this.$emit('update:selectedCourses', this.courseList);
    },
    updateFromPreselected() {
      if (!Array.isArray(this.preselectedCourses)) {
        return;
      }
      const courseIds = this.preselectedCourses.map((course) => {
        return course.id;
      });
      this.courseList.forEach((course: CourseListItem) => {
        if (courseIds.indexOf(course.value) > -1) {
          course.selected = true;
        }
      });
    },
  },
  mounted() {
    CourseList.get().then((response) => {
      this.courseList = CourseList.parseResponse(response);
      if (
        Array.isArray(this.preselectedCourses) &&
        this.preselectedCourses.length >= 0
      ) {
        this.updateFromPreselected();
      } else {
        this.selectAll();
      }
    });
  },
  watch: {
    preselectedCourses: {
      handler() {
        this.updateFromPreselected();
      },
    },
  },
});
