import { Course, DefaultCourse } from '../courses/course';

export interface Holiday {
  id: number;
  start_date: string;
  end_date: string;
  name: string;
  courses: Course[];
}

const DEFAULT_HOLIDAY: Holiday = {
  id: -1,
  start_date: '',
  end_date: '',
  name: '',
  courses: [],
};

export function DefaultHoliday(): Holiday {
  return JSON.parse(JSON.stringify(DEFAULT_HOLIDAY));
}
