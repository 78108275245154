
import DefaultButtons from '../forms/DefaultButtons.vue';
import FormAlerts from '../alerts/FormAlerts.vue';
import { FormProcessor } from '../../api/forms/processor';
import {
  Maintenance,
  DefaultMaintenance,
} from '../../api/maintenances/maintenance';
import { MaintenanceShow } from '../../api/maintenances/show';
import { formatDate, parseDate } from '../../api/dates/date_formatter';
import Vue from 'vue';

Vue.component('default-buttons', DefaultButtons);
Vue.component('form-alerts', FormAlerts);

export default Vue.component('maintenance-show', {
  props: ['maintenanceId'],
  data() {
    return {
      id: -1 as number,
      isNew: false,
      maintenanceData: DefaultMaintenance() as Maintenance,
      processor: new FormProcessor(),
      startDate: '',
      startDateFormatted: '',
      endDate: '',
      endDateFormatted: '',
    };
  },
  methods: {
    parseDateInternal(date: string): string {
      return parseDate(date);
    },
    handleCourseUpdate(courseId: number) {
      this.maintenanceData.course.id = courseId;
      this.processor.setModified();
    },
    loadForm() {
      if (this.id <= 0) {
        return;
      }
      MaintenanceShow.getData(this.id).then((response) => {
        this.maintenanceData = MaintenanceShow.parseResponse(response);
        this.startDate = this.maintenanceData.start_date;
        this.endDate = this.maintenanceData.end_date;
      });
    },
    deleteForm() {
      if (this.processor === null || !this.processor.shouldDelete()) {
        return;
      }
      this.processor.deleteData(MaintenanceShow, this.id);
    },
    deleteCallback() {
      this.$router.replace({ name: 'manage-maintenances' });
    },
    saveForm() {
      this.maintenanceData.id = this.id;
      if (this.processor === null || !this.processor.shouldSave()) {
        return;
      }
      this.processor.saveData(
        MaintenanceShow,
        this.maintenanceData,
        this.$refs.submitForm,
      );
    },
    undoForm() {
      if (this.processor === null || !this.processor.undoForm()) {
        return;
      }
      this.loadForm();
    },
    handleNew() {
      this.id = -1;
      this.isNew = true;
      if (this.$route.params.course_id) {
        this.maintenanceData.course.id = Number(this.$route.params.course_id);
      }
      this.processor.setParams(
        this.id,
        this.maintenanceData,
        DefaultMaintenance(),
        this.updateCallback,
        this.saveCallback,
        this.deleteCallback,
      );
      this.processor.readOnly = false;
    },
    resetForm() {
      const id = this.$route.params.id;
      if (!id || id === 'new') {
        this.handleNew();
        return;
      }
      this.id = Number(id);
      if (this.id <= 0) {
        return;
      }
      this.processor.setParams(
        this.id,
        this.maintenanceData,
        DefaultMaintenance(),
        this.updateCallback,
        this.saveCallback,
        this.deleteCallback,
      );
      this.loadForm();
    },
    saveCallback(data: any) {
      // Reload the form on save so the serializers can
      // format any $/% data properly.
      if (this.isNew && data.id) {
        this.id = data.id;
        this.maintenanceData.id = data.id;
        this.isNew = false;
      }
      this.loadForm();
    },
    updateCallback(formData: Maintenance) {
      this.maintenanceData = formData;
    },
  },
  mounted() {
    if (this.maintenanceId) {
      this.id = this.maintenanceId;
    }
    this.resetForm();
  },
  watch: {
    endDate: {
      handler() {
        this.endDateFormatted = formatDate(this.endDate);
        this.maintenanceData.end_date = this.endDate;
      },
    },
    startDate: {
      handler() {
        this.startDateFormatted = formatDate(this.startDate);
        this.maintenanceData.start_date = this.startDate;
      },
    },
  },
});
