import AuthToken from '../auth/Auth';

export class Reporting {
  public static getApMonthly(
    startDate: string,
    endDate: string,
    courses: number[],
    onlyPlayed: boolean,
  ) {
    const link = document.createElement('a');
    link.href = `/api/reports/ap-monthly.xlsx?only_played=${onlyPlayed}&${this.getTokenString()}&start_date=${startDate}&end_date=${endDate}&courses=${JSON.stringify(
      courses,
    )}`;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public static getOldRateSheet() {
    const link = document.createElement('a');
    link.href = `/api/reports/old-rate-sheet.xlsx?${this.getTokenString()}`;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public static getRateSheet() {
    const link = document.createElement('a');
    link.href = `/api/reports/rate-sheet.xlsx?${this.getTokenString()}`;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public static getGiftCerts(
    allDates: boolean,
    startDate: string,
    endDate: string,
  ) {
    const link = document.createElement('a');
    link.href = `/api/reports/gift-certs.xlsx?${this.getTokenString()}&allDates=${allDates}&startDate=${startDate}&endDate=${endDate}`;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public static getMaintenance() {
    const link = document.createElement('a');
    link.href = `/api/reports/maintenance.xlsx?${this.getTokenString()}`;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public static getSalesReport(month: number, year: number) {
    const link = document.createElement('a');
    link.href = `/api/reports/sales_report.xlsx?${this.getTokenString()}&month=${month}&year=${year}`;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public static getCommission(startDate: string, endDate: string) {
    const link = document.createElement('a');
    link.href = `/api/reports/commission.xlsx?${this.getTokenString()}&start_date=${startDate}&end_date=${endDate}`;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public static getNewSales(startDate: string, endDate: string) {
    const link = document.createElement('a');
    link.href = `/api/reports/new_sales_report.xlsx?${this.getTokenString()}&start_date=${startDate}&end_date=${endDate}`;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public static getRevenueComparison(
    startDate: string,
    endDate: string,
    compareStartDate: string,
    compareEndDate: string,
    dateType: string,
  ) {
    const link = document.createElement('a');
    link.href = `/api/reports/revenue-comparison.xlsx?${this.getTokenString()}&start_date=${startDate}&end_date=${endDate}&compare_start_date=${compareStartDate}&compare_end_date=${compareEndDate}&date_type=${dateType}`;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public static getTeeTimeReport(
    startDate: string,
    endDate: string,
    dateType: string,
  ) {
    const link = document.createElement('a');
    link.href = `/api/reports/tee-time.xlsx?${this.getTokenString()}&start_date=${startDate}&end_date=${endDate}&date_type=${dateType}`;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public static getWeekly(startDate: string, endDate: string) {
    const link = document.createElement('a');
    link.href = `/api/reports/weekly.xlsx?${this.getTokenString()}&start_date=${startDate}&end_date=${endDate}`;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  private static getTokenString() {
    const authHeader = AuthToken.getAuthHeader();
    const accessToken = encodeURI(authHeader['access-token']);
    const uid = encodeURI(authHeader.uid);
    const client = encodeURI(authHeader.client);

    return `access_token=${accessToken}&uid=${uid}&client=${client}`;
  }
}
