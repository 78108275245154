import axios from 'axios';
import { Discount } from './discount';

export interface DiscountIndexFilters {
  code: string;
  start_date: string;
  end_date: string;
  amount?: number;
  is_dollars?: boolean;
  min_discount?: number;
  max_discount?: number;
  gift_certificate_stack?: boolean;
  enabled?: boolean;
  min_players?: number;
  max_players?: number;
}

export interface DiscountIndexOptions {
  itemsPerPage: number;
  sortBy: string[];
  sortDesc: boolean[];
  page: number;
  filters: DiscountIndexFilters;
}

export class DiscountIndex {
  public static PER_PAGE: number = 30;

  public static async getCount(
    discountIndexOptions?: DiscountIndexOptions,
  ): Promise<any> {
    return axios.get('/api/discounts/count', {
      params: {
        filters: discountIndexOptions?.filters,
      },
    });
  }

  public static async getData(
    discountIndexOptions?: DiscountIndexOptions,
  ): Promise<any> {
    if (discountIndexOptions == null) {
      return axios.get('/api/discounts/index', {
        params: {
          per_page: this.PER_PAGE,
        },
      });
    }

    const perPage =
      discountIndexOptions.itemsPerPage <= 0
        ? this.PER_PAGE
        : discountIndexOptions.itemsPerPage;
    return axios.get('/api/discounts/index', {
      params: {
        per_page: perPage,
        sort_fields: discountIndexOptions.sortBy,
        sort_desc: discountIndexOptions.sortDesc,
        page: discountIndexOptions.page,
        filters: discountIndexOptions.filters,
      },
    });
  }

  public static parseResponse(response: any): Discount[] {
    const discountData = [] as Discount[];
    for (const k in response) {
      if (!response[k]) {
        continue;
      }
      const item = response[k];
      const discount = {} as Discount;
      discount.id = item.id;
      discount.code = item.code;
      discount.start_date = item.start_date;
      discount.end_date = item.end_date;
      discount.amount = item.amount;
      discount.is_dollars = item.is_dollars;
      discount.is_dollars_display = item.is_dollars ? '$' : '%';
      discount.min_discount = item.min_discount;
      discount.max_discount = item.max_discount;
      discount.gift_certificate_stack = item.gift_certificate_stack;
      (discount.enabled = item.enabled),
        (discount.min_players = item.min_players);
      discount.max_players = item.max_players;
      discountData.push(discount);
    }
    return discountData;
  }
}
