import axios from 'axios';
import { DefaultCourse } from '../courses/course';
import { Rate } from './rate';

export class RateShow {
  public static async getData(id: number): Promise<any> {
    return axios.get(`/api/rates/show/${id}`);
  }

  public static async deleteData(id: number): Promise<any> {
    return axios.delete(`/api/rates/${id}`);
  }

  public static async saveData(rateData: Rate): Promise<any> {
    const cleanRateData = JSON.parse(JSON.stringify(rateData));
    cleanRateData.course = { id: rateData.course.id };

    if (rateData.id === -1) {
      return axios.post('/api/rates', {
        rate: cleanRateData,
      });
    }
    return axios.put('/api/rates/update', {
      id: cleanRateData.id,
      rate: cleanRateData,
    });
  }

  public static parseResponse(response: any): Rate {
    const rate = {} as Rate;
    rate.id = response.data.id;
    rate.rate = response.data.rate;
    rate.deposit = response.data.deposit;
    rate.service_fee = response.data.service_fee;
    rate.wholesale_rate = response.data.wholesale_rate;
    rate.max_refund = response.data.max_refund;
    rate.non_refundable = response.data.non_refundable;
    rate.cancel_hours = response.data.cancel_hours;
    rate.course_balance = response.data.course_balance;
    rate.max_advance_days = response.data.max_advance_days;
    rate.disabled = response.data.disabled;
    rate.commission = response.data.commission;
    rate.internal_memo = response.data.internal_memo;
    rate.external_memo = response.data.external_memo;
    rate.course = response.data.course ? response.data.course : DefaultCourse();
    rate.rate_date_ranges = response.data.rate_date_ranges ? response.data.rate_date_ranges : [];
    rate.archived = response.data.archived;
    rate.rate_sheet_note = response.data.rate_sheet_note;
    rate.is_commission_dollars = response.data.is_commission_dollars;
    rate.type = response.data.type;
    rate.humanized_type = response.humanized_type;
    rate.golfswitch_commission = response.data.golfswitch_commission;
    rate.is_golfswitch_commission_dollars = response.data.is_golfswitch_commission_dollars;
    rate.type = response.data.type;
    rate.is_dynamic = response.data.is_dynamic;
    rate.is_dynamic_greater = response.data.is_dynamic_greater;
    return rate;
  }
}
