import axios from 'axios';
import {
  DefaultTeeTimeAvailability,
  DefaultTeeTimeAvailabilitySummary,
  TeeTimeAvailabilityFilter,
  TeeTimeAvailabilitySummary,
} from './tee_time_availability_summary';

export interface TeeTimeAvailabilityIndexOptions {
  sortBy: string[];
  sortDesc: boolean[];
  itemsPerPage: number;
  page: number;
  filters: TeeTimeAvailabilityFilter;
}


export class TeeTimeAvailabilityIndex {
  public static async getData(
    options?: TeeTimeAvailabilityIndexOptions,
  ): Promise<any> {
    return axios.get('/api/fore_up_availability/index', {
      params: {
        filters: options?.filters,
        per_page: options?.itemsPerPage,
        sort_fields: options?.sortBy,
        sort_desc: options?.sortDesc,
        page: options?.page,
      },
    });
  }

  public static async getCount(
    options?: TeeTimeAvailabilityIndexOptions,
  ): Promise<any> {
    return axios.get('/api/fore_up_availability/count', {
      params: {
        filters: options?.filters,
      },
    });
  }

  public static async getWaitListQueueData(): Promise<any> {
    return axios.get('/api/fore_up_availability/wait_list_queues');
  }

  public static parseWaitlistQueueResponse(
    response: any,
  ): TeeTimeAvailabilitySummary {
    const teeTimeAvailabilitySummaries = DefaultTeeTimeAvailabilitySummary();

    for (const k in response) {
      const availability = response[k];
      const teeTimeAvailability = DefaultTeeTimeAvailability();
      teeTimeAvailability.id = availability.id;
      teeTimeAvailability.courseId = availability.course.id;
      teeTimeAvailability.courseName = availability.course.course_name;
      teeTimeAvailability.date = availability.wordy_available_date;
      teeTimeAvailability.sortingDate = availability.available_date;
      teeTimeAvailability.time = availability.play_time_for_humans;
      teeTimeAvailability.slots = availability.spots;
      teeTimeAvailability.scrapedAt = availability.scraped_at;
      teeTimeAvailability.createdAt = availability.created_at;
      teeTimeAvailabilitySummaries.availabilities.push(teeTimeAvailability);
    }

    return teeTimeAvailabilitySummaries;
  }

  public static parseForeResponse(response: any): TeeTimeAvailabilitySummary {
    const teeTimeAvailabilitySummaries = DefaultTeeTimeAvailabilitySummary();

    for (const k in response) {
      const record = response[k];
      const teeTimeAvailability = DefaultTeeTimeAvailability();
      teeTimeAvailability.courseId = Number(record.course_id);
      teeTimeAvailability.courseName = record.course_name;
      teeTimeAvailability.time = Number(record.available_time);
      teeTimeAvailability.id = record.id;
      teeTimeAvailability.date = record.read_date;
      teeTimeAvailability.slots = record.spots;
      teeTimeAvailability.isNew = record.is_new;
      teeTimeAvailability.readDate = record.read_date;
      teeTimeAvailability.createdAt = record.created_at;
      teeTimeAvailability.updatedAt = record.updated_at;
      teeTimeAvailability.scrapedAt = record.scraped_at;
      teeTimeAvailability.sortingDate = record.available_date;
      teeTimeAvailability.rate = record.rate;
      teeTimeAvailability.rateId = record.rateId;
      teeTimeAvailability.group_name = record.group_name;

      teeTimeAvailabilitySummaries.availabilities.push(teeTimeAvailability);
    }

    return teeTimeAvailabilitySummaries;
  }
}
