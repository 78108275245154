
import { TransactionItem } from '../../api/transaction_items/transaction_item';
import { TransactionRecord } from '../../api/transaction_record/transaction_record';
import { TransactionRecordIndex } from '../../api/transaction_record/index';
import Vue from 'vue';

export default Vue.extend({
  props: ['type', 'updateCounter'],
  data() {
    return {
      id: 0,
      transactionItemHeaders: [
        { text: 'Created At', value: 'created_at' },
        { text: 'Total', value: 'total' },
        { text: 'Type', value: 'transaction_itemable_type' },
        { text: 'View', value: 'view' },
      ],
      transactionRecordHeaders: [
        { text: 'Created At', value: 'created_at' },
        { text: 'Total', value: 'total' },
      ],
      transactionRecords: [] as TransactionRecord[],
      transactionItems: [] as TransactionItem[],
    };
  },
  mounted() {
    this.id = Number(this.$route.params.id);
    if (this.id > 0 && this.type !== '') {
      this.getData();
    }
  },
  methods: {
    getData() {
      TransactionRecordIndex.getData(this.type, this.id).then((response) => {
        if (this.type === 'TeeTime') {
          this.transactionRecords = [
            TransactionRecordIndex.parseTeeTimeResponse(response),
          ];
          this.buildTransactionItems();
        }
        if (this.type === 'GolfDiscount') {
          this.transactionItems = TransactionRecordIndex.parseDiscountResponse(
            response,
          );
        }
        if (this.type === 'GiftCertificate') {
          this.transactionItems = TransactionRecordIndex.parseGiftCertificateResponse(
            response,
          );
        }
      });
    },
    getLink(data: TransactionItem): string {
      switch (data.transaction_itemable_type) {
        case 'Golf discount':
          return `/manage-discount/${data.transaction_itemable_id}`;
        case 'Gift certificate':
          return `/manage-gift-certificate/${data.transaction_itemable_id}`;
        case 'Tee time':
          return `/manage-tee-time/${data.transaction_itemable_id}`;
        default:
          return '';
      }
    },
    buildTransactionItems() {
      this.transactionItems = [];
      for (const tr of this.transactionRecords) {
        this.transactionItems = tr.transaction_items.concat(
          this.transactionItems,
        );
      }
    },
  },
  watch: {
    updateCounter() {
      this.getData();
    },
  },
});
