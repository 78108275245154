
import Auth from './api/auth/Auth';
import { AUTH_NAME } from './api/auth/Auth';
import {
  HelpfulLinkIndex,
  HelpfulLinkIndexFilters,
} from './api/helpful_links/index';
import { HelpfulLink } from './api/helpful_links/HelpfulLink';

import Component from 'vue-class-component';
import { RicMessages } from './api/ric_messages/ric_message';
import Vue from 'vue';
import router from './router';

const CUSTOMER_ROUTES = ['manage-customer', 'manage-customers'];
const DISCOUNT_ROUTES = ['manage-discount', 'manage-discounts'];
const GIFT_CERTIFICATE_ROUTES = [
  'manage-gift-certificates',
  'gift-certificate-sales',
];
const RATE_ROUTES = ['manage-rate', 'manage-rates'];

@Component
export default class App extends Vue {
  private shouldShowDrawer = false;
  private helpfulLinkData: HelpfulLink[] = [];
  private hotelLinkData: HelpfulLink[] = [];
  private psgrLinkData: HelpfulLink[] = [];
  private ricMessage: string | null = null;

  constructor() {
    super();
  }
  private isAdmin(): boolean {
    return Auth.isAdmin();
  }
  private mounted() {
    this.loadRicMessage();
    setTimeout(() => {
      this.loadRicMessage();
    }, 5 * 60 * 1000);
    HelpfulLinkIndex.getData({ filters: { type: 'helpful' } }).then(
      (response) => {
        this.helpfulLinkData = HelpfulLinkIndex.parseResponse(response.data);
      },
    );
    HelpfulLinkIndex.getData({ filters: { type: 'hotel' } }).then(
      (response) => {
        this.hotelLinkData = HelpfulLinkIndex.parseResponse(response.data);
      },
    );
    HelpfulLinkIndex.getData({ filters: { type: 'psgr' } }).then(
      (response) => {
        this.psgrLinkData = HelpfulLinkIndex.parseResponse(response.data);
      },
    );
  }
  private loadRicMessage() {
    RicMessages.get().then((response) => {
      if (response.data.message) {
        this.ricMessage = response.data.message;
      }
    });
  }
  private showBar() {
    return this.$router.currentRoute.name !== 'login';
  }
  private isStaging(): boolean {
    return (
      location.host === 'staging-ric.sdgr.com' || location.host === 'localhost'
    );
  }
  private isGiftCertificates() {
    return this.includesRoute(GIFT_CERTIFICATE_ROUTES);
  }
  private isManageCustomers() {
    return this.includesRoute(CUSTOMER_ROUTES);
  }
  private isManageDiscounts() {
    return this.includesRoute(DISCOUNT_ROUTES);
  }
  private isHelpfulLinks() {
    return this.includesRoute(['helpful-links']);
  }
  private isRates() {
    return this.includesRoute(RATE_ROUTES);
  }
  private isCourse() {
    return this.includesRoute(['manage-course', 'manage-courses']);
  }
  private isCourseGroup() {
    return this.includesRoute(['manage-course-group', 'manage-course-groups']);
  }
  private includesRoute(routes: string[]): boolean {
    // TODO Replace with nullable queries when available.
    const currentRoute: string =
      this.$router &&
      this.$router.currentRoute &&
      this.$router.currentRoute.name
        ? this.$router.currentRoute.name
        : '';
    return routes.indexOf(currentRoute) >= 0;
  }
  private isRicUsers() {
    return this.$router.currentRoute.name === 'ric-users';
  }
  private isMaintenance() {
    return this.$router.currentRoute.name === 'manage-maintenances';
  }
  private newMaintenance() {
    if (this.isCourse()) {
      // This should be used when the route param is the course id.
      return `/maintenance/new/${this.$route.params.id}`;
    }

    return '/maintenance/new';
  }
  private isHoliday() {
    return this.$router.currentRoute.name === 'manage-holidays';
  }
  private newHoliday() {
    if (this.isCourse()) {
      // This should be used when the route param is the course id.
      return `/holiday/new/${this.$route.params.id}`;
    }

    return '/holiday/new';
  }
  private isRentalClub() {
    return this.$router.currentRoute.name === 'manage-rental-clubs';
  }
  private newRentalClub() {
    if (this.isCourse()) {
      // This should be used when the route param is the course id.
      return `/rental-club/new/${this.$route.params.id}`;
    }

    return '/rental-club/new';
  }
  private getToken() {
    return `https://www.torreypines.com/book-a-tee-time/?token=${localStorage.getItem(
      AUTH_NAME,
    )}`;
  }
}
