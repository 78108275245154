
import { RicMessages } from '../api/ric_messages/ric_message';
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      error: false,
      saveResponse: null as string | null,
      ricMessage: null as string | null,
    };
  },
  mounted() {
    RicMessages.get().then((response) => {
      if (response.data.message) {
        this.ricMessage = response.data.message;
      }
    });
  },
  methods: {
    saveMessage() {
      const message = this.ricMessage || '';
      RicMessages.save(message).then((response) => {
        if (response.status == 200 && response.data) {
          this.saveResponse = 'Success!';
        }
      });
    },
  },
});
