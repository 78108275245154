
import { EmailTemplateShow } from '../api/email_templates/show';
import { TemplateItem, DefaultTemplateItem } from '../api/email_templates/email_template';
import TemplateList from '../components/email_templates/TemplateList.vue';
import TemplateShow from '../components/email_templates/TemplateShow.vue';
import Vue from 'vue';

Vue.component('template-list', TemplateList);
Vue.component('template-show', TemplateShow);

export default Vue.extend({
  data() {
    return {
      error: '',
      saveMessage: '',
      templateItem: DefaultTemplateItem(),
    };
  },
  methods: {
    handleSaveResponse(message: string) {
      this.saveMessage = message;
    },
    saveTemplate() {
      this.error = '';
      this.saveMessage = '';
      if (!this.templateItem) {
        this.error = 'Please select a template before saving.';
        return;
      }
      const template: any = this.$refs.templateShow;
      template.saveTemplate();
    },
    setTemplate(templateItem: TemplateItem) {
      this.templateItem = templateItem;
    },
  },
});
