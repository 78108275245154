
import { Course } from '../../api/courses/course';
import CourseCheckBoxes from '../courses/CourseCheckBoxes.vue';
import Vue from 'vue';
import { CourseListItem } from '../../api/courses/list';
import { FormProcessor } from '../../api/forms/processor';
import { RateCopy, RateCopyData } from '../../api/rates/rate_copy';

Vue.component('course-check-boxes', CourseCheckBoxes);

export default Vue.component('rate-copy', {
  props: ['closeHandler', 'openDialog', 'rateId', 'rateDateRanges'],
  data() {
    return {
      courses: [] as Course[],
      processor: new FormProcessor(),
      shouldOpenDialog: false,
    };
  },
  methods: {
    closeDialog() {
      this.shouldOpenDialog = false;
      this.closeHandler();
    },
    copyCourse() {
      if (this.courses.length === 0) {
        alert('Please select a course first.');
        return;
      }

      if (this.processor === null || !this.processor.shouldSave()) {
        return;
      }
      const rateCopyData = {
        rateDateRanges: this.rateDateRanges,
        courses: this.courses,
        rateId: this.rateId,
      } as RateCopyData;
      this.processor.saveData(RateCopy, rateCopyData, this.$refs.submitForm);
    },
    handleCoursesUpdate(courseListItems: []) {
      this.courses = courseListItems
        .filter((c: CourseListItem) => c.selected === true)
        .map((c: CourseListItem) => {
          return {
            id: Number(c.value),
          } as Course;
        });

      this.processor.setModified();
    },
  },
  watch: {
    openDialog: {
      handler() {
        // The v-dialog mutates the model when it closes. Avoid using a prop.
        this.shouldOpenDialog = this.openDialog;
      },
    },
  },
});
