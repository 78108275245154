
import { QueueManagementIndex } from '../../api/queue/index';
import {
  QueueSummary,
  DefaultQueueSummary,
} from '../../api/queue/queue_summary';
import { FormProcessor } from '../../api/forms/processor';
import Vue from 'vue';
export default Vue.component('queue-management-index', {
  data() {
    return {
      processor: new FormProcessor(),
      itemsPerPage: -1,
      loading: true,
      loadingText: 'Loading Data...',
      headers: [
        { text: 'First Name', value: 'customer.first_name' },
        { text: 'Last Name', value: 'customer.last_name' },
        { text: 'Phone', value: 'customer.primary_phone' },
        { text: 'Email', value: 'customer.email' },
        { text: 'Players', value: 'lostLead.players' },
        { text: 'Queue Position', value: 'queue_position' },
        { text: 'Tee Time', value: 'teeTime' },
        { text: 'Book', value: 'book' },
      ],
      courseName: '',
      summary: DefaultQueueSummary() as QueueSummary,
      notBookedCustomers: [] as any,
    };
  },
  methods: {
    closeQueue() {
      QueueManagementIndex.loadQueue(Number(this.$route.query.fupId)).then(
        (response) => {
          QueueManagementIndex.closeQueue(Number(this.$route.query.fupId));
          this.summary = DefaultQueueSummary();
        });
    },
  },
  mounted() {
    QueueManagementIndex.loadQueue(Number(this.$route.query.fupId)).then(
      (response) => { 
        this.summary = QueueManagementIndex.parseResponse(response);
        this.loading = false;
      },
    );
  },
});
