import AuthToken from './api/auth/Auth';
import Vue from 'vue';
import 'vuetify/dist/vuetify.min.css';
import App from './App.vue';
import axios from 'axios';
import router from './router';
import vuetify from './plugins/vuetify';
import '@babel/polyfill';

Vue.config.productionTip = false;

// If a stored token is present, ensure it's added to the header
// of all axios requests.
axios.interceptors.request.use((config) => {
  const authHeader = AuthToken.getAuthHeader();
  if (authHeader == null) {
    return config;
  }
  for (const authHeaderField of Object.keys(authHeader)) {
    config.headers[authHeaderField] = authHeader[authHeaderField];
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    // Do nothing with a normal response.
    return response;
  },
  (error) => {
    if (error.response.status === 401 && router.currentRoute.name !== 'login') {
      router.replace({ name: 'login' });
      return;
    }
    return error.response;
  },
);

// Ensure that the user is authenticated before allowing them to use the app.
router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !AuthToken.hasToken()) {
    next('/login');
    return;
  }
  const title = to.meta ? to.meta.title ? to.meta.title : '' : '';
  document.title = `RIC - ${title}`;
  next();
});

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
