
import { EmailTemplateIndex } from '../../api/email_templates/index';
import { TemplateItem, DefaultTemplateItem } from '../../api/email_templates/email_template';
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      selectedItem: DefaultTemplateItem(),
      templateItems: [] as TemplateItem[],
    };
  },
  mounted() {
    EmailTemplateIndex.getFileList().then((response) => {
      this.templateItems = [];
      for (const path of Object.keys(response.data.files)) {
        const templateItem = {} as TemplateItem;
        templateItem.value = path;
        templateItem.text = response.data.files[path];
        this.templateItems.push(templateItem);
      }
    });
  },
  methods: {
    onSelectTemplate() {
      this.$emit('update:selected', this.selectedItem);
    },
  },
});
