
import CountrySelector from '../countries/CountrySelector.vue';
import DefaultButtons from '../forms/DefaultButtons.vue';
import FormAlerts from '../alerts/FormAlerts.vue';
import { FormProcessor } from '../../api/forms/processor';
import Vue from 'vue';
import { Course, DefaultCourse } from '../../api/courses/course';
import { CourseShow } from '../../api/courses/show';
import { DefaultGolfswitchCourse } from '../../api/courses/golfswitch_course';

Vue.component('country-selector', CountrySelector);
Vue.component('default-buttons', DefaultButtons);
Vue.component('form-alerts', FormAlerts);

export default Vue.component('course-show', {
  props: ['courseId'],
  data() {
    return {
      courseData: DefaultCourse() as Course,
      isNew: false,
      processor: new FormProcessor(),
      gsId: '',
      gsXid: '',
      gnId: 0,
      cpId: 0,
    };
  },
  methods: {
    golfswitchCourseRules(field: string): any {
      if (this.processor?.errorHandler.ruleMap?.course?.golfswitch_course) {
        return this.processor.errorHandler.ruleMap.course.golfswitch_course[
          field
        ];
      }
      return [];
    },
    handleCountryUpdate(country: string) {
      this.courseData.country_alpha2 = country;
      this.processor.setModified();
    },
    deleteForm() {
      if (this.processor === null || !this.processor.shouldDelete()) {
        return;
      }
      this.processor.deleteData(CourseShow, this.courseData.id);
    },
    deleteCallback() {
      this.$router.replace({ name: 'manage-courses' });
    },
    loadForm() {
      if (this.isNew || this.courseId <= 0) {
        this.courseData.id = -1;
        return;
      }
      CourseShow.getData(this.courseId).then((response) => {
        this.courseData = CourseShow.parseResponse(response);
        if (this.courseData.golfswitch_course === undefined) {
          this.courseData.golfswitch_course = DefaultGolfswitchCourse();
        }
        this.gsId = this.courseData.golfswitch_course?.gs_id;
        this.gsXid = this.courseData.golfswitch_course?.gs_xid;
        this.gnId = this.courseData.golfnow_course?.golfnow_id;
        this.cpId = this.courseData.club_prophet_course?.club_prophet_id;
      });
    },
    saveCallback(data: any) {
      // Reload the form on save so the serializers can
      // format any $/% data properly.
      if (this.isNew && data.id) {
        this.courseData.id = data.id;
        this.isNew = false;
      }
      this.loadForm();
    },
    resetForm() {
      this.courseData = DefaultCourse();
      if (!Number(this.$route.params.id)) {
        this.isNew = true;
      }
      this.processor.setParams(
        this.courseId,
        this.courseData,
        DefaultCourse(),
        this.updateCallback,
        this.saveCallback,
        this.deleteCallback,
      );
      this.loadForm();
    },
    saveForm() {
      if (this.processor === null || !this.processor.shouldSave()) {
        return;
      }
      this.courseData.golfswitch_course.gs_id = this.gsId;
      this.courseData.golfswitch_course.gs_xid = this.gsXid;
      this.courseData.golfnow_course.golfnow_id = this.gnId;
      this.courseData.club_prophet_course.club_prophet_id = this.cpId;
      this.processor.saveData(
        CourseShow,
        this.courseData,
        this.$refs.submitForm,
      );
    },
    undoForm() {
      if (this.processor === null || !this.processor.undoForm()) {
        return;
      }
      this.loadForm();
    },
    updateCallback(formData: Course) {
      this.courseData = formData;
    },
  },
  mounted() {
    this.resetForm();
  },
  watch: {
    $route() {
      this.resetForm();
    },
  },
});
