
import Auth from '../api/auth/Auth';
import DiscountShow from '../components/discounts/DiscountShow.vue';
import TransactionIndex from '../components/transactions/TransactionIndex.vue';
import Vue from 'vue';

Vue.component('discount-show', DiscountShow);
Vue.component('transaction-index', TransactionIndex);

export default Vue.extend({
  data() {
    return {
      updateCounter: 0,
    };
  },
  methods: {
    handleDiscountSave() {
      this.updateCounter++;
    },
  },
  mounted() {
    if (!Auth.isAdmin()) {
      this.$router.push({ name: 'home' });
    }
  },
});
