import axios from 'axios';
import { ApiAction, ApiActionItem } from './api_action';
import { ApiUser, ApiUserItem } from './api_user';

export class ApiList {
  // Actions

  public static async getActionList(): Promise<any> {
    return axios.get('/api/api_actions/index');
  }

  public static parseActionItemResponse(response: any): ApiActionItem[] {
    const actionItemData = [] as ApiActionItem[];
    response.forEach((action: any) => {
      // The actions aren't persisted, just a static array in the model.
      actionItemData.push({ selected: false, action });
    });
    return actionItemData;
  }

  // Users

  public static async getUserList(): Promise<any> {
    return axios.get('/api/api_users/index');
  }

  public static parseActionUserResponse(response: any): ApiUserItem[] {
    const actionUserData = [] as ApiUserItem[];
    response.forEach((apiUser: any) => {
      // The actions aren't persisted, just a static array in the model.
      actionUserData.push({ selected: false, api_user: apiUser });
    });
    return actionUserData;
  }
}
