import axios from 'axios';
import { Rate } from './rate';
import { RateDay } from './rate_days';
import { RateDateRange } from './rate_date_range';
import { RateFilter } from './rate_filter';
import { DefaultCourse } from '../courses/course';

export interface RateIndexOptions {
  sortBy: string[];
  sortDesc: boolean[];
  itemsPerPage: number;
  page: number;
  filters: RateFilter;
}

export class RateIndex {
  public static PER_PAGE: number = 30;

  public static async getCount(
    fieldFilters: RateFilter,
    live = false,
  ): Promise<any> {
    const uri = live ? '/api/rates/count_live' : '/api/rates/count';
    return axios.get(uri, {
      params: {
        filters: fieldFilters,
      },
    });
  }

  public static async getData(
    rateIndexOptions?: RateIndexOptions,
    live = false,
  ): Promise<any> {
    const uri = live ? '/api/rates/index_live' : '/api/rates/index';
    if (rateIndexOptions == null) {
      return axios.get(uri, {
        params: {
          per_page: this.PER_PAGE,
        },
      });
    }

    return axios.get(uri, {
      params: {
        per_page: rateIndexOptions.itemsPerPage,
        sort_fields: rateIndexOptions.sortBy,
        sort_desc: rateIndexOptions.sortDesc,
        page: rateIndexOptions.page,
        filters: rateIndexOptions.filters,
      },
    });
  }

  public static parseResponse(response: any): Rate[] {
    const rateData = [] as Rate[];

    for (const k in response) {
      if (!response[k]) {
        continue;
      }

      const item = response[k];
      const days = [] as string[];
      const times = [] as string[];
      const ranges = [] as string[];
      for (const rdr of item.rate_date_ranges) {
        ranges.push(`${rdr.start} - ${rdr.end}`);
        times.push(`${rdr.time_start} - ${rdr.time_end}`);
        for (const r of rdr.rate_days) {
          days.push(r.name.slice(0, 2));
        }
      }
      const rate = {} as Rate;
      rate.id = item.id;
      rate.course = item.course ? item.course : DefaultCourse();
      rate.days = days.join(', ');
      rate.dates = ranges.join(', ');
      rate.rate = item.rate;
      rate.times = times.join(', ');
      rate.type = item.type;
      rate.humanized_type = item.humanized_type;
      rate.rawRate = item.raw_rate;
      rate.internal_memo = item.internal_memo;
      rate.external_memo = item.external_memo;
      rate.archived = item.archived;
      rate.disabled = item.disabled;
      rate.golfswitch_commission = response.golfswitch_commission;
      rate.is_golfswitch_commission_dollars = response.is_golfswitch_commission_dollars;
      rateData.push(rate);
    }
    return rateData;
  }
}
