
import { FilterPersistence } from '../../api/filter/filter_persistence';
import {
  DiscountIndex,
  DiscountIndexFilters,
  DiscountIndexOptions,
} from '../../api/discounts/index';
import { Discount } from '../../api/discounts/discount';
import { formatDate, parseDate } from '../../api/dates/date_formatter';

import Vue from 'vue';

export default Vue.component('discount-index', {
  data() {
    return {
      codeFilter: '',
      startDateFilter: '',
      startDateFilterFormatted: '',
      endDateFilter: '',
      endDateFilterFormatted: '',
      amountFilter: undefined,
      isDollarFilter: '',
      minDiscountFilter: undefined,
      maxDiscountFilter: undefined,
      giftCertificateStackFilter: undefined,
      enabledFilter: undefined,
      minPlayersFilter: undefined,
      maxPlayersFilter: undefined,
      loading: true,
      loadingText: 'Loading Data...',
      headers: [
        { text: 'Code', value: 'code' },
        { text: 'Start Date', value: 'start_date' },
        { text: 'End Date', value: 'end_date' },
        { text: 'Amount', value: 'amount' },
        { text: '$ / %', value: 'is_dollars_display' },
        { text: 'Min Discount', value: 'min_discount' },
        { text: 'Max Discount', value: 'max_discount' },
        { text: 'Gift Cert Stack', value: 'gift_certificate_stack' },
        { text: 'Enabled?', value: 'enabled' },
        { text: 'Min Players', value: 'min_players' },
        { text: 'Max Players', value: 'max_players' },
        { text: 'View', value: 'view' },
      ],
      fieldFilters: {} as DiscountIndexFilters,
      options: {} as DiscountIndexOptions,
      discountData: [] as Discount[],
      discountCount: 0 as number,
      perPage: DiscountIndex.PER_PAGE as number,
      pageNo: 1 as number,
      sortDesc: [] as boolean[],
      sortBy: [] as string[],
    };
  },
  mounted() {
    this.loadFilters();
    this.refreshTable();
  },
  methods: {
    parseDateInternal(date: string): string {
      return parseDate(date);
    },
    refreshTable() {
      this.options.sortDesc = this.sortDesc;
      this.options.sortBy = this.sortBy;
      this.options.page = this.pageNo;
      this.options.itemsPerPage = this.perPage;
      this.options.filters = this.fieldFilters;
      DiscountIndex.getData(this.options).then((response) => {
        this.discountData = DiscountIndex.parseResponse(response.data);
        this.loading = false;
      });
      DiscountIndex.getCount(this.options).then((response) => {
        this.discountCount = response.data;
      });
    },
    search() {
      this.pageNo = 1;
      this.refreshTable();
    },
    maybeSearch(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        this.updateFilters();
        FilterPersistence.saveFieldFilterState(
          this.$router,
          'manage-discounts',
          this.fieldFilters,
        );
        this.search();
      }
    },
    loadFilters() {
      const filter = FilterPersistence.getFieldFiltersFromUrl(this.$route);
      if (!filter) {
        return;
      }
      this.fieldFilters = filter as DiscountIndexFilters;

      this.codeFilter = this.fieldFilters.code;
      this.startDateFilter = this.fieldFilters.start_date;
      this.endDateFilter = this.fieldFilters.end_date;
      this.amountFilter = this.fieldFilters.amount as undefined;
      this.isDollarFilter = this.fieldFilters.is_dollars ? '$' : '%';
      this.minDiscountFilter = this.fieldFilters.min_discount as undefined;
      this.maxDiscountFilter = this.fieldFilters.max_discount as undefined;
      this.giftCertificateStackFilter = this.fieldFilters.gift_certificate_stack as undefined;
      this.enabledFilter = this.fieldFilters.enabled as undefined;
      this.minPlayersFilter = this.fieldFilters.min_players as undefined;
      this.maxPlayersFilter = this.fieldFilters.max_players as undefined;
    },
    updateFilters() {
      let dollarFilter;
      if (this.isDollarFilter === '$') {
        dollarFilter = true;
      } else if (this.isDollarFilter === '%') {
        dollarFilter = false;
      }
      this.fieldFilters = {
        code: this.codeFilter,
        start_date: this.startDateFilter,
        end_date: this.endDateFilter,
        amount: this.amountFilter,
        is_dollars: dollarFilter,
        min_discount: this.minDiscountFilter,
        max_discount: this.maxDiscountFilter,
        gift_certificate_stack: this.giftCertificateStackFilter,
        enabled: this.enabledFilter,
        min_players: this.minPlayersFilter,
        max_players: this.maxPlayersFilter,
      };
    },
  },
  computed: {
    discounts(): Discount[] {
      return this.discountData;
    },
  },
  watch: {
    endDateFilter: {
      handler() {
        this.endDateFilterFormatted = formatDate(this.endDateFilter);
        const e = this.$refs.endDate as HTMLElement;
        e.focus();
      },
    },
    startDateFilter: {
      handler() {
        this.startDateFilterFormatted = formatDate(this.startDateFilter);
        const e = this.$refs.startDate as HTMLElement;
        e.focus();
      },
    },
    pageNo: {
      handler() {
        this.refreshTable();
      },
    },
    perPage: {
      handler() {
        this.refreshTable();
      },
    },
    sortDesc: {
      handler() {
        this.refreshTable();
      },
    },
  },
});
