import axios from 'axios';
import { Customer } from './customer';
import { CustomerFilter } from './customer_filter';

export interface CustomerIndexOptions {
  itemsPerPage: number;
  sortBy: string[];
  sortDesc: boolean[];
  page: number;
  customerFilter: CustomerFilter;
}

export class CustomerIndex {
  public static PER_PAGE: number = 30;

  public static async getCount(
    customerIndexOptions?: CustomerIndexOptions,
  ): Promise<any> {
    return axios.get('/api/customers/count', {
      params: {
        filters: customerIndexOptions?.customerFilter,
      },
    });
  }

  public static async getData(
    customerIndexOptions?: CustomerIndexOptions,
  ): Promise<any> {
    if (customerIndexOptions == null) {
      return axios.get('/api/customers/index', {
        params: {
          per_page: this.PER_PAGE,
        },
      });
    }

    const perPage =
      customerIndexOptions.itemsPerPage <= 0
        ? this.PER_PAGE
        : customerIndexOptions.itemsPerPage;
    return axios.get('/api/customers/index', {
      params: {
        per_page: perPage,
        sort_fields: customerIndexOptions.sortBy,
        sort_desc: customerIndexOptions.sortDesc,
        page: customerIndexOptions.page,
        filters: customerIndexOptions.customerFilter,
      },
    });
  }

  public static parseResponse(response: any): Customer[] {
    const customerData = [] as Customer[];
    for (const k in response) {
      if (!response[k]) {
        continue;
      }
      const item = response[k];
      const customer = {} as Customer;
      customer.id = item.id;
      customer.type = item.type;
      customer.first_name = item.first_name;
      customer.last_name = item.last_name;
      customer.email = item.email;
      customer.primary_phone = item.primary_phone;
      customerData.push(customer);
    }
    return customerData;
  }
}
