import { DefaultRateDay, RateDay } from './rate_days';

export interface RateDateRange {
  id?: number;
  start?: string;
  end?: string;
  time_start: number;
  time_end: number;
  description: string;
  rate_days: RateDay[];
  should_copy: boolean;
  should_delete: boolean;
  golf_rate_id: number;
}

const DEFAULT_RATE_DATE_RANGE = {
  time_start: 0,
  time_end: 0,
  description: '',
  rate_days: [DefaultRateDay()],
  should_copy: false,
  should_delete: false,
  golf_rate_id: 0,
};

export function DefaultRateDateRange(): RateDateRange {
  return JSON.parse(JSON.stringify(DEFAULT_RATE_DATE_RANGE));
}
