import { render, staticRenderFns } from "./CourseSelector.vue?vue&type=template&id=89493efe"
import script from "./CourseSelector.vue?vue&type=script&lang=ts"
export * from "./CourseSelector.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports