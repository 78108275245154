import axios from 'axios';

export interface Country {
  text: string;
  value: string;
}

export class CountryList {
  public static async get(): Promise<any> {
    return axios.get('/api/countries/list');
  }

  public static parseResponse(response: any): Country[] {
    const countries = [] as Country[];
    if (!response.data) {
      return [];
    }
    for (const countryEntry of response.data) {
      const country = { text: countryEntry.name, value: countryEntry.alpha2 };
      countries.push(country);
    }
    return countries;
  }
}
