
import ApiActionsCheckBoxes from '../api/ApiActionsCheckBoxes.vue';
import ApiUsersCheckBoxes from '../api/ApiUsersCheckBoxes.vue';
import { ApiAction, ApiActionItem } from '../../api/api/api_action';
import { ApiUser, ApiUserItem } from '../../api/api/api_user';
import DefaultButtons from '../forms/DefaultButtons.vue';
import FormAlerts from '../alerts/FormAlerts.vue';
import { FormProcessor } from '../../api/forms/processor';
import Vue from 'vue';
import { Course } from '../../api/courses/course';
import CourseCheckBoxes from '../courses/CourseCheckBoxes.vue';
import { CourseListItem } from '../../api/courses/list';
import {
  CourseGroup,
  DefaultCourseGroup,
} from '../../api/course_groups/course_group';
import { CourseGroupShow } from '../../api/course_groups/show';
import { DefaultCourse } from '../../api/courses/course';

Vue.component('api-actions-check-boxes', ApiActionsCheckBoxes);
Vue.component('api-users-check-boxes', ApiUsersCheckBoxes);
Vue.component('course-check-boxes', CourseCheckBoxes);
Vue.component('default-buttons', DefaultButtons);
Vue.component('form-alerts', FormAlerts);

export default Vue.component('course-group-show', {
  props: ['courseGroupId'],
  data() {
    return {
      courseGroupData: DefaultCourseGroup() as CourseGroup,
      isNew: false,
      processor: new FormProcessor(),
    };
  },
  methods: {
    deleteForm() {
      if (this.processor === null || !this.processor.shouldDelete()) {
        return;
      }
      this.processor.deleteData(CourseGroupShow, this.courseGroupData.id);
    },
    deleteCallback() {
      this.$router.replace({ name: 'manage-course_groups' });
    },
    handleCoursesUpdate(courseListItems: []) {
      this.courseGroupData.courses = courseListItems
        .filter((c: CourseListItem) => c.selected === true)
        .map((c: CourseListItem) => {
          return {
            id: Number(c.value),
          } as Course;
        });

      this.processor.setModified();
    },
    handleApiActionUpdate(apiActionItems: []) {
      this.courseGroupData.actions = apiActionItems
        .filter((c: ApiActionItem) => c.selected === true)
        .map((c: ApiActionItem) => {
          return {
            action: { id: Number(c.action.id) },
          } as ApiActionItem;
        });

      this.processor.setModified();
    },
    handleApiUserUpdate(apiUserItems: []) {
      this.courseGroupData.users = apiUserItems
        .filter((c: ApiUserItem) => c.selected === true)
        .map((c: ApiUserItem) => {
          return {
            api_user: { id: Number(c.api_user.id) },
          } as ApiUserItem;
        });

      this.processor.setModified();
    },
    loadForm() {
      if (this.isNew || this.courseGroupId <= 0) {
        this.courseGroupData.id = -1;
        this.processor.readOnly = false;
        return;
      }
      CourseGroupShow.getData(this.courseGroupId).then((response) => {
        this.courseGroupData = CourseGroupShow.parseResponse(response);
      });
    },
    saveCallback(data: any) {
      // Reload the form on save so the serializers can
      // format any $/% data properly.
      if (this.isNew && data.id) {
        this.courseGroupData.id = data.id;
        this.isNew = false;
      }
      this.loadForm();
    },
    resetForm() {
      this.courseGroupData = DefaultCourseGroup();
      if (!Number(this.$route.params.id)) {
        this.isNew = true;
      }
      this.processor.setParams(
        this.courseGroupId,
        this.courseGroupData,
        DefaultCourseGroup(),
        this.updateCallback,
        this.saveCallback,
        this.deleteCallback,
      );
      this.loadForm();
    },
    saveForm() {
      if (this.processor === null || !this.processor.shouldSave()) {
        return;
      }
      this.processor.saveData(
        CourseGroupShow,
        this.courseGroupData,
        this.$refs.submitForm,
      );
    },
    undoForm() {
      if (this.processor === null || !this.processor.undoForm()) {
        return;
      }
      this.loadForm();
    },
    updateCallback(formData: CourseGroup) {
      this.courseGroupData = formData;
    },
  },
  mounted() {
    this.resetForm();
  },
  watch: {
    $route() {
      this.resetForm();
    },
  },
});
