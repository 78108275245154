import { TransactionItem } from '../transaction_items/transaction_item';

export interface TransactionRecord {
  created_at: string;
  payment_id: string;
  total: string;
  transaction_items: TransactionItem[];
}

const DEFAULT_TRANSACTION_RECORD: TransactionRecord = {
  created_at: '',
  payment_id: '',
  total: '',
  transaction_items: [],
};

export function DefaultTransactionRecord(): TransactionRecord {
  return JSON.parse(JSON.stringify(DEFAULT_TRANSACTION_RECORD));
}
