
import { TeeTimeAvailabilityIndex } from '../../api/tee_time_availability/index';
import {
  TeeTimeAvailabilitySummary,
  DefaultTeeTimeAvailabilitySummary,
} from '../../api/tee_time_availability/tee_time_availability_summary';
import Vue from 'vue';
export default Vue.component('wait-list-queue-index', {
  data() {
    return {
      itemsPerPage: -1,
      loading: true,
      loadingText: 'Loading Data...',
      headers: [
        { text: 'Course name', value: 'courseName' },
        { text: 'Last scrape', value: 'scrapedAt' },
        { text: 'Date', value: 'date' },
        { text: 'Sorting Date', value: 'sortingDate' },
        { text: 'Time', value: 'time' },
        { text: 'Players', value: 'slots' },
        { text: 'Queue', value: 'book' },
      ],
      summary: DefaultTeeTimeAvailabilitySummary() as TeeTimeAvailabilitySummary,
    };
  },
  mounted() {
    TeeTimeAvailabilityIndex.getWaitListQueueData().then((response) => {
      if (!response.data) {
        return;
      }
      this.summary = TeeTimeAvailabilityIndex.parseWaitlistQueueResponse(response.data);
      this.loading = false;
    });
  },
});
