import { Course, DefaultCourse } from '../courses/course';
import { Customer, DefaultCustomer } from '../customers/customer';

export interface LostLead {
  id: number;
  created_at: string;
  lead_date: string;
  play_time: number;
  players: number;
  customer: Customer;
  course: Course;
  tee_time_availability_summary: any[];
}

const DEFAULT_LOST_LEAD: LostLead = {
  id: -1,
  players: 0,
  play_time: 0,
  created_at: '',
  lead_date: '',
  customer: DefaultCustomer(),
  course: DefaultCourse(),
  tee_time_availability_summary: [],
};

export function DefaultLostLead(): LostLead {
  return JSON.parse(JSON.stringify(DEFAULT_LOST_LEAD));
}
