
import CourseSelector from '../courses/CourseSelector.vue';
import DefaultButtons from '../forms/DefaultButtons.vue';
import FormAlerts from '../alerts/FormAlerts.vue';
import OverlaySpinner from '../io/OverlaySpinner.vue';
import TeeTimeCancel from './TeeTimeCancel.vue';
import { FormProcessor } from '../../api/forms/processor';
import { TeeTime, DefaultTeeTime } from '../../api/tee_times/tee_time';
import { TeeTimeShow } from '../../api/tee_times/show';
import { formatDate, parseDate } from '../../api/dates/date_formatter';
import Vue from 'vue';

Vue.component('course-selector', CourseSelector);
Vue.component('default-buttons', DefaultButtons);
Vue.component('form-alerts', FormAlerts);
Vue.component('overlay-spinner', OverlaySpinner);
Vue.component('tee-time-cancel', TeeTimeCancel);

export default Vue.component('tee-time-show', {
  data() {
    return {
      id: -1 as number,
      cancelTeeTimeDialog: false,
      changeCustomerDialog: false,
      showSpinner: false,
      teeTimeData: DefaultTeeTime() as TeeTime,
      processor: new FormProcessor(),
      playDate: '',
      playDateFormatted: '',
    };
  },
  methods: {
    clearText(element: Element) {
      const htmlElement = element as HTMLElement;
      htmlElement.textContent = '';
    },
    clearRefs() {
      this.clearText(this.$refs.sendSuccess as Element);
      this.clearText(this.$refs.sendError as Element);
      this.clearText(this.$refs.backendError as Element);
    },
    setText(element: Element, text: string) {
      const htmlElement = element as HTMLElement;
      htmlElement.textContent = text;
    },
    sendReceipt() {
      if (!confirm('Send the receipt again?')) {
        return;
      }
      this.clearRefs();
      this.showSpinner = true;
      TeeTimeShow.sendReceipt(this.teeTimeData.id).then((response) => {
        this.showSpinner = false;
        if (response.status !== 200) {
          this.setText(this.$refs.sendError as Element, response.data.message);
          return;
        }
        this.setText(this.$refs.sendSuccess as Element, 'Success');
      });
    },
    cancelTeeTime() {
      if (!confirm('Cancel this tee time. Are you sure?')) {
        return;
      }
      this.showSpinner = true;
      this.clearRefs();
      TeeTimeShow.cancelTeeTime(this.teeTimeData.id).then((response) => {
        this.showSpinner = false;
        if (response.status !== 200) {
          this.setText(this.$refs.sendError as Element, response.data.message);
          this.setText(
            this.$refs.backendError as Element,
            response.data.backend_message,
          );
          return;
        }
        this.setText(this.$refs.sendSuccess as Element, 'Success');
      });
    },
    handleOverlayActive(state: boolean) {
      this.showSpinner = state;
    },
    sendCancelEmail() {
      if (!confirm('Send cancel email. Are you sure?')) {
        return;
      }
      this.clearRefs();
      this.showSpinner = true;
      TeeTimeShow.sendCancelEmail(this.teeTimeData.id).then((response) => {
        this.showSpinner = false;
        if (response.status !== 200) {
          this.setText(this.$refs.sendError as Element, response.data.message);
          return;
        }
        this.setText(this.$refs.sendSuccess as Element, 'Success');
      });
    },
    sendVoucher() {
      if (!confirm('Send the voucher again?')) {
        return;
      }
      this.clearRefs();
      this.showSpinner = true;
      TeeTimeShow.sendVoucher(this.teeTimeData.id).then((response) => {
        this.showSpinner = false;
        if (response.status !== 200) {
          this.setText(this.$refs.sendError as Element, response.data.message);
          return;
        }
        this.setText(this.$refs.sendSuccess as Element, 'Success');
      });
    },
    parseDateInternal(date: string): string {
      return parseDate(date);
    },
    handleCourseUpdate(courseId: number) {
      this.teeTimeData.course.id = courseId;
      this.processor.setModified();
    },
    loadForm() {
      if (this.id <= 0) {
        return;
      }
      TeeTimeShow.getData(this.id).then((response) => {
        this.teeTimeData = TeeTimeShow.parseResponse(response);
        this.playDate = this.teeTimeData.play_date;
      });
    },
    giftCertRules(field: string) {
      if (
        this.processor.errorHandler.ruleMap.tee_time &&
        this.processor.errorHandler.ruleMap.tee_time.gift_certificate
      ) {
        return this.processor.errorHandler.ruleMap.tee_time.gift_certificate[
          field
        ];
      }

      return [];
    },
    teeTimeRules(field: string) {
      if (this.processor.errorHandler.ruleMap.tee_time) {
        return this.processor.errorHandler.ruleMap.tee_time[field];
      }

      return [];
    },
    saveForm() {
      if (this.processor === null || !this.processor.shouldSave()) {
        return;
      }
      this.processor.saveData(
        TeeTimeShow,
        this.teeTimeData,
        this.$refs.submitForm,
      );
    },
    viewCustomer() {
      this.$router.push({
        name: 'manage-customer',
        params: {
          id: this.teeTimeData.customer.id.toString(),
        },
      });
    },
    undoForm() {
      if (this.processor === null || !this.processor.undoForm()) {
        return;
      }
      this.loadForm();
    },
    updateCallback(formData: TeeTime) {
      this.teeTimeData = formData;
      this.$emit('update:teeTimeSaved');
    },
    updateCustomer(customerId: number) {
      this.changeCustomerDialog = false;
      if (customerId <= 0) {
        return;
      }
      this.processor.saveData(
        /* dataProcessor= */ undefined,
        customerId,
        this.$refs.submitForm,
        () => {
          return TeeTimeShow.updateCustomer(this.teeTimeData.id, customerId);
        },
      );
    },
    saveCallback(formData: TeeTime) {
      this.$emit('update:teeTimeSaved');
      this.loadForm();
    },
  },
  mounted() {
    this.id = Number(this.$route.params.id);
    if (this.id <= 0) {
      return;
    }
    this.processor.setParams(
      this.id,
      this.teeTimeData,
      DefaultTeeTime(),
      this.updateCallback,
      this.saveCallback,
    );
    this.loadForm();
  },
  computed: {
    customerName(): string {
      if (!this.teeTimeData.customer) {
        return 'N/A';
      }

      const customer = this.teeTimeData.customer;
      return `${customer.first_name} ${customer.last_name}`;
    },
    customerEmail(): string {
      if (!this.teeTimeData.customer) {
        return 'N/A';
      }

      return this.teeTimeData.customer.email;
    },
    customerPhone(): string {
      if (!this.teeTimeData.customer) {
        return 'N/A';
      }

      return this.teeTimeData.customer.primary_phone;
    },
  },
  watch: {
    playDate: {
      handler() {
        this.playDateFormatted = formatDate(this.playDate);
        this.teeTimeData.play_date = this.playDate;
      },
    },
  },
});
