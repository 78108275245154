
import Auth from '../api/auth/Auth';
import RateCopy from '../components/rates/RateCopy.vue';
import RateDate from '../components/rates/RateDate.vue';
import Vue from 'vue';
import {
  DefaultRateDateRange,
  RateDateRange,
} from '../api/rates/rate_date_range';

Vue.component('rate-copy', RateCopy);
Vue.component('rate-date', RateDate);

// TODO: Shared form processors are bad.
export default Vue.extend({
  props: ['rateDateRanges', 'rateId'],
  data() {
    return {
      openDialog: false,
      rangeDataConsumed: false,
      rateDateRangesInternal: [] as RateDateRange[],
    };
  },
  methods: {
    copyRate() {
      this.openDialog = true;
    },
    handleRateDateDeleted(index: number) {
      this.rateDateRangesInternal.splice(index, 1);
    },
    handleCopyRateClose() {
      this.openDialog = false;
    },
    newDateRange() {
      this.rateDateRangesInternal.push(DefaultRateDateRange());
    },
    updateRateId(_rateId: number) {
      // Just hard-reload the page once we have a new rate ID to force all other ranges to update.
      location.reload();
    },
  },
  computed: {
    isAdmin() {
      return Auth.isAdmin();
    },
  },
  watch: {
    rateDateRanges() {
      // We only copy the data once, then ignore anything upstream.
      if (this.rangeDataConsumed) {
        return;
      }
      this.rateDateRangesInternal = JSON.parse(
        JSON.stringify(this.rateDateRanges),
      );
      this.rangeDataConsumed = true;
    },
  },
});
