import { FormProcessor } from '../../api/forms/processor';
import { Rate } from '../../api/rates/rate';
import { RateShow } from '../../api/rates/show';

export class CommonRateShow {
  private type: string;

  constructor(type: string) {
    this.type = type;
  }

  public courseRules(): string[] {
    return [];
  }

  public deleteForm(processor: FormProcessor, rateData: Rate) {
    if (processor === null || !processor.shouldDelete()) {
      return;
    }
    processor.deleteData(RateShow, rateData.id);
  }

  public loadForm(rateData: Rate) {
    if (rateData.id <= 0) {
      return;
    }
    RateShow.getData(rateData.id).then((response) => {
      rateData = RateShow.parseResponse(response);
    });
  }

  public saveForm(processor: FormProcessor, formRef: any, rateData: Rate) {
    if (processor === null || !processor.shouldSave()) {
      return;
    }
    processor.saveData(RateShow, rateData, formRef.submitForm);
  }

  public rateMap(processor: FormProcessor): any {
    if (processor.errorHandler.ruleMap.rate) {
      return processor.errorHandler.ruleMap.rate;
    }
    return {};
  }
}
