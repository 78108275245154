import { Course, DefaultCourse } from '../courses/course';

export interface Maintenance {
  id: number;
  start_date: string;
  end_date: string;
  description: string;
  course: Course;
}

const DEFAULT_MAINTENANCE: Maintenance = {
  id: -1,
  start_date: '',
  end_date: '',
  description: '',
  course: DefaultCourse(),
};

export function DefaultMaintenance(): Maintenance {
  return JSON.parse(JSON.stringify(DEFAULT_MAINTENANCE));
}
