
import DefaultButtons from '../forms/DefaultButtons.vue';
import FormAlerts from '../alerts/FormAlerts.vue';
import { FormProcessor } from '../../api/forms/processor';
import {
  RentalClub,
  DefaultRentalClub,
} from '../../api/rental_clubs/rental_club';
import { RentalClubShow } from '../../api/rental_clubs/show';
import Vue from 'vue';

Vue.component('default-buttons', DefaultButtons);
Vue.component('form-alerts', FormAlerts);

export default Vue.component('rental-club-show', {
  props: ['rentalClubId'],
  data() {
    return {
      id: -1 as number,
      isNew: false,
      rentalClubData: DefaultRentalClub(),
      processor: new FormProcessor(),
    };
  },
  methods: {
    handleCourseUpdate(courseId: number) {
      this.rentalClubData.course.id = courseId;
      this.processor.setModified();
    },
    loadForm() {
      if (this.id <= 0) {
        return;
      }
      RentalClubShow.getData(this.id).then((response) => {
        this.rentalClubData = RentalClubShow.parseResponse(response);
      });
    },
    deleteForm() {
      if (this.processor === null || !this.processor.shouldDelete()) {
        return;
      }
      this.processor.deleteData(RentalClubShow, this.id);
    },
    deleteCallback() {
      this.$router.replace({ name: 'manage-rental-clubs' });
    },
    saveForm() {
      this.rentalClubData.id = this.id;
      if (this.processor === null || !this.processor.shouldSave()) {
        return;
      }
      this.processor.saveData(
        RentalClubShow,
        this.rentalClubData,
        this.$refs.submitForm,
      );
    },
    undoForm() {
      if (this.processor === null || !this.processor.undoForm()) {
        return;
      }
      this.loadForm();
    },
    handleNew() {
      this.id = -1;
      this.isNew = true;
      if (this.$route.params.course_id) {
        this.rentalClubData.course.id = Number(this.$route.params.course_id);
      }
      this.processor.setParams(
        this.id,
        this.rentalClubData,
        DefaultRentalClub(),
        this.updateCallback,
        this.saveCallback,
        this.deleteCallback,
      );
      this.processor.readOnly = false;
    },
    resetForm() {
      const id = this.$route.params.id;
      if (!id || id === 'new') {
        this.handleNew();
        return;
      }
      this.id = Number(id);
      if (this.id <= 0) {
        return;
      }
      this.processor.setParams(
        this.id,
        this.rentalClubData,
        DefaultRentalClub(),
        this.updateCallback,
        this.saveCallback,
        this.deleteCallback,
      );
      this.loadForm();
    },
    saveCallback(data: any) {
      // Reload the form on save so the serializers can
      // format any $/% data properly.
      if (this.isNew) {
        this.rentalClubData.id = data.id;
        this.isNew = false;
      }
      if (data.id) {
        this.id = data.id;
        this.$router.push({ name: 'rental-club', params: { id: `${this.id}` } });
      }
      this.loadForm();
    },
    updateCallback(formData: RentalClub) {
      this.rentalClubData = formData;
    },
  },
  mounted() {
    if (this.rentalClubId) {
      this.id = this.rentalClubId;
    }
    this.resetForm();
  },
});
