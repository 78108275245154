import axios from 'axios';
import { CustomerFilter } from '../customers/customer_filter';
import { GiftCertificate } from './gift_certificate';
import { TransactionItemFilter } from '../transaction_items/transaction_item_filter';

export interface GiftCertificateIndexFilters {
  created_at: string;
  serial: string;
  balance: string;
  transaction_item: TransactionItemFilter;
  customer: CustomerFilter;
  internal_memo: string;
}

export interface GiftCertificateIndexOptions {
  itemsPerPage: number;
  sortBy: string[];
  sortDesc: boolean[];
  page: number;
  filters: GiftCertificateIndexFilters;
  queryType?: string;
}

export class GiftCertificateIndex {
  public static PER_PAGE: number = 30;

  public static async getCount(
    giftCertificateIndexOptions?: GiftCertificateIndexOptions,
  ): Promise<any> {
    return axios.get('/api/gift_certificates/count', {
      params: {
        filters: giftCertificateIndexOptions?.filters,
        query_type: giftCertificateIndexOptions?.queryType,
      },
    });
  }

  public static async getData(
    giftCertificateIndexOptions?: GiftCertificateIndexOptions,
  ): Promise<any> {
    if (giftCertificateIndexOptions == null) {
      return axios.get('/api/gift_certificates/index', {
        params: {
          per_page: this.PER_PAGE,
        },
      });
    }

    const perPage =
      giftCertificateIndexOptions.itemsPerPage <= 0
        ? this.PER_PAGE
        : giftCertificateIndexOptions.itemsPerPage;
    return axios.get('/api/gift_certificates/index', {
      params: {
        per_page: perPage,
        sort_fields: giftCertificateIndexOptions.sortBy,
        sort_desc: giftCertificateIndexOptions.sortDesc,
        page: giftCertificateIndexOptions.page,
        filters: giftCertificateIndexOptions.filters,
        query_type: giftCertificateIndexOptions?.queryType,
      },
    });
  }

  public static parseResponse(response: any): GiftCertificate[] {
    const giftCertificateData = [] as GiftCertificate[];
    for (const k in response) {
      if (!response[k]) {
        continue;
      }
      const item = response[k];
      const giftCertificate = {} as GiftCertificate;
      giftCertificate.id = item.id;
      giftCertificate.created_at = item.created_at;
      giftCertificate.serial = item.serial;
      giftCertificate.balance = item.balance;
      giftCertificate.raw_balance = item.raw_balance;
      giftCertificate.customer = item.customer;
      giftCertificate.transaction_item = item.transaction_item;
      giftCertificate.internal_memo = item.internal_memo;
      giftCertificateData.push(giftCertificate);
    }
    return giftCertificateData;
  }
}
