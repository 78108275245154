import { ApiActionItem, DefaultApiAction } from '../api/api_action';
import { ApiUserItem, DefaultApiUser } from '../api/api_user';
import { Course, DefaultCourse } from '../courses/course';

export interface CourseGroup {
  id: number;
  group_name: string;
  actions: ApiActionItem[];
  users: ApiUserItem[];
  courses: Course[];
  course_names: string;
}

const DEFAULT_COURSE_GROUP: CourseGroup = {
  id: 0,
  group_name: '',
  actions: [{ action: DefaultApiAction() } as ApiActionItem],
  users: [{ api_user: DefaultApiUser() } as ApiUserItem],
  courses: [DefaultCourse()],
  course_names: '',
};

export function DefaultCourseGroup(): CourseGroup {
  return JSON.parse(JSON.stringify(DEFAULT_COURSE_GROUP));
}
