var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-text',[_c('div',[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"display-1 text--primary"},[_vm._v(" Booked: "+_vm._s(this.data.internal_round_count + this.data.external_round_count)+" "),_c('br'),_vm._v(" Previous: "+_vm._s(this.data.previous_internal_round_count + this.data.previous_external_round_count)+" ")]),_c('v-row',[_c('v-col',[_c('div',{staticClass:"text--primary"},[_vm._v("Revenue: "+_vm._s(this.data.revenue))]),_c('div',{staticClass:"text--primary"},[_vm._v(" Previous: "+_vm._s(this.data.previous_revenue)+" ")])]),_c('v-col',[_c('div',{staticClass:"text--primary"},[_vm._v(" Internal vs. External ")]),_c('div',[_vm._v(" Internal: "+_vm._s(this.data.internal_revenue)+" "),_c('br'),_vm._v(" Previous: "+_vm._s(this.data.previous_internal_revenue)+" ")]),_c('br'),_c('div',[_vm._v(" External: "+_vm._s(this.data.external_revenue)+" "),_c('br'),_vm._v(" Previous: "+_vm._s(this.data.previous_external_revenue)+" ")]),_c('br'),_c('div',[_vm._v(" Misc: "+_vm._s(this.data.misc_revenue)+" "),_c('br'),_vm._v(" Previous: "+_vm._s(this.data.previous_misc_revenue)+" ")])])],1),_c('v-row',[(
            this.data.internal_top_courses &&
              this.data.internal_top_courses.length > 0
          )?_c('v-col',[_c('div',{staticClass:"text--primary"},[_vm._v(" Top 3 Internal Courses ")]),_vm._l((this.data.internal_top_courses),function(course){return _c('div',{key:course},[_vm._v(" "+_vm._s(course)+" ")])})],2):_vm._e(),(
            this.data.external_top_courses &&
              this.data.external_top_courses.length > 0
          )?_c('v-col',[_c('div',{staticClass:"text--primary"},[_vm._v(" Top 3 External Courses ")]),_vm._l((this.data.external_top_courses),function(course){return _c('div',{key:course},[_vm._v(" "+_vm._s(course)+" ")])})],2):_vm._e()],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"text--primary"},[_vm._v(" Internal ")]),_c('div',[_vm._v(" "+_vm._s(this.data.internal_booking_count)+" bookings, "+_vm._s(this.data.internal_round_count)+" rounds ")])]),_c('v-col',[_c('div',{staticClass:"text--primary"},[_vm._v(" External ")]),_c('div',[_vm._v(" "+_vm._s(this.data.external_booking_count)+" bookings, "+_vm._s(this.data.external_round_count)+" rounds ")])]),_c('v-col',[_c('div',{staticClass:"text--primary"},[_vm._v(" Misc ")]),_c('div',[_vm._v(" "+_vm._s(this.data.misc_booking_count)+" bookings, "+_vm._s(this.data.misc_round_count)+" rounds ")])])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"text--primary"},[_vm._v(" Previous Internal ")]),_c('div',[_vm._v(" "+_vm._s(this.data.previous_internal_booking_count)+" bookings, "+_vm._s(this.data.previous_internal_round_count)+" rounds ")])]),_c('v-col',[_c('div',{staticClass:"text--primary"},[_vm._v(" Previous External ")]),_c('div',[_vm._v(" "+_vm._s(this.data.previous_external_booking_count)+" bookings, "+_vm._s(this.data.previous_external_round_count)+" rounds ")])]),_c('v-col',[_c('div',{staticClass:"text--primary"},[_vm._v(" Previous Misc ")]),_c('div',[_vm._v(" "+_vm._s(this.data.previous_misc_booking_count)+" bookings, "+_vm._s(this.data.previous_misc_round_count)+" rounds ")])])],1)],1),_c('v-card-actions',[_c('router-link',{attrs:{"text":"","color":"deep-purple accent-4","to":_vm.getLink()}},[_vm._v("View all")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }