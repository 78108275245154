import {
  TransactionItem, DefaultTransactionItem,
} from '../transaction_items/transaction_item';

import { Course, DefaultCourse } from '../courses/course';
import { Customer, DefaultCustomer } from '../customers/customer';

export interface GiftCertificate {
  created_at: string;
  id: number;
  serial: string;
  balance: string;
  raw_balance: number;
  who_from: string;
  email_delivery: string;
  mail_recipient: string;
  greeting: string;
  transaction_item: TransactionItem;
  course: Course;
  customer: Customer;
  recipient: Customer;
  internal_memo: string;
}

const DEFAULT_GIFT_CERTIFICATE: GiftCertificate = {
  id: -1,
  serial: '',
  created_at: '',
  balance: '',
  raw_balance: 0,
  who_from: '',
  email_delivery: 'mail',
  mail_recipient: 'recipient',
  greeting: '',
  internal_memo: '',
  transaction_item: DefaultTransactionItem(),
  course: DefaultCourse(),
  customer: DefaultCustomer(),
  recipient: DefaultCustomer(),
};

export function DefaultGiftCertificate(): GiftCertificate {
  return JSON.parse(JSON.stringify(DEFAULT_GIFT_CERTIFICATE));
}
