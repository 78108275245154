export interface EmailTemplateFile {
  file_name: string;
}

const DEFAULT_EMAIL_TEMPLATE_FILE: EmailTemplateFile = {
  file_name: '',
};

export function DefaultEmailTemplateFile(): EmailTemplateFile {
  return JSON.parse(JSON.stringify(DEFAULT_EMAIL_TEMPLATE_FILE));
}

export interface TemplateItem {
  text: string;
  value: string;
}

const DEFAULT_TEMPLATE_ITEM: TemplateItem = {
  text: '',
  value: '',
};

export function DefaultTemplateItem(): TemplateItem {
  return JSON.parse(JSON.stringify(DEFAULT_TEMPLATE_ITEM));
}