import axios from 'axios';
import { DefaultCourse } from '../courses/course';
import { Maintenance } from './maintenance';

export class MaintenanceShow {
  public static async getData(id: number): Promise<any> {
    return axios.get(`/api/maintenances/show/${id}`);
  }

  public static async deleteData(id: number): Promise<any> {
    return axios.delete(`/api/maintenances/${id}`);
  }

  public static async saveData(maintenanceData: Maintenance): Promise<any> {
    if (maintenanceData.id === -1) {
      return axios.post('/api/maintenances', {
        maintenance: maintenanceData,
      });
    }
    return axios.put('/api/maintenances/update', {
      id: maintenanceData.id,
      maintenance: maintenanceData,
    });
  }

  public static parseResponse(response: any): Maintenance {
    const maint = {} as Maintenance;
    maint.start_date = response.data.start_date;
    maint.end_date = response.data.end_date;
    maint.description = response.data.description;
    maint.course = response.data.course
      ? response.data.course
      : DefaultCourse();
    return maint;
  }
}
