
import Auth from '../../api/auth/Auth';
import Vue from 'vue';

export default Vue.component('default-buttons', {
  props: ['isNew', 'readOnly', 'adminOnly', 'showDelete'],
  methods: {
    enableEdit() {
      this.$emit('enable-edit');
    },
    saveForm() {
      this.$emit('save-form', 'saveForm');
    },
    lockEdit() {
      this.$emit('lock-edit', 'lockEdit');
    },
    reloadForm() {
      this.$emit('reload-form', 'reloadForm');
    },
    clearForm() {
      this.$emit('clear-form', 'clearForm');
    },
    deleteForm() {
      this.$emit('delete-form', 'deleteForm');
    },
  },
  computed: {
    buttonsVisible() {
      if (this.adminOnly === true) {
        return Auth.isAdmin();
      }
      return true;
    },
  },
});
