import axios from 'axios';
import { Course } from './course';
import { DefaultGolfswitchCourse } from './golfswitch_course';
import { DefaultGolfnowCourse } from './golfnow_course';
import { DefaultClubProphetCourse } from './club_prophet_course';

export class CourseShow {
  public static async getData(id: number): Promise<any> {
    return axios.get(`/api/courses/show/${id}`);
  }

  public static async deleteData(id: number): Promise<any> {
    return axios.delete(`/api/courses/${id}`);
  }

  public static async saveData(courseData: Course): Promise<any> {
    if (courseData.id === -1) {
      return axios.post('/api/courses', {
        course: courseData,
      });
    }

    return axios.put('/api/courses/update', {
      id: courseData.id,
      course: courseData,
    });
  }

  public static parseResponse(response: any): Course {
    const course = {} as Course;
    course.id = response.data.id;
    course.course_name = response.data.course_name;
    course.display_name = response.data.display_name;
    course.address = response.data.address;
    course.address_2 = response.data.address_2;
    course.city = response.data.city;
    course.subdivision = response.data.subdivision;
    course.country_alpha2 = response.data.country_alpha2;
    course.phone = response.data.phone;
    course.postcode = response.data.postcode;
    course.email = response.data.email;
    course.rate_required = response.data.rate_required;
    course.inactive = response.data.inactive;
    course.internal_only = response.data.internal_only;
    course.is_realtime = response.data.is_realtime;
    course.cart_rate = response.data.cart_rate;
    course.days_out_to_scrape = response.data.days_out_to_scrape
    course.round_limit = response.data.round_limit
    course.golfswitch_course = response.data.golfswitch_course
      ? response.data.golfswitch_course
      : DefaultGolfswitchCourse();
    course.golfnow_course = response.data.golfnow_course
      ? response.data.golfnow_course
      : DefaultGolfnowCourse();
      course.club_prophet_course = response.data.club_prophet_course
      ? response.data.club_prophet_course
      : DefaultClubProphetCourse();
    return course;
  }
}
