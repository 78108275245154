import axios from 'axios';
import { DefaultCourse } from '../courses/course';
import { Holiday } from './holiday';

export class HolidayShow {
  public static async getData(id: number): Promise<any> {
    return axios.get(`/api/holidays/show/${id}`);
  }

  public static async deleteData(id: number): Promise<any> {
    return axios.delete(`/api/holidays/${id}`);
  }

  public static async saveData(holidayData: Holiday): Promise<any> {
    if (holidayData.id === -1) {
      return axios.post('/api/holidays', {
        holiday: holidayData,
      });
    }
    return axios.put('/api/holidays/update', {
      id: holidayData.id,
      holiday: holidayData,
    });
  }

  public static parseResponse(response: any): Holiday {
    const holiday = {} as Holiday;
    holiday.start_date = response.data.start_date;
    holiday.end_date = response.data.end_date;
    holiday.name = response.data.name;
    holiday.courses = response.data.courses
      ? response.data.courses
      : [DefaultCourse()];
    return holiday;
  }
}
