
import { CustomerFilter } from '../../api/customers/customer_filter';
import { FilterPersistence } from '../../api/filter/filter_persistence';
import { formatDate, parseDate } from '../../api/dates/date_formatter';
import { RateFilter } from '../../api/rates/rate_filter';
import {
  TeeTimeIndex,
  TeeTimeIndexFilters,
  TeeTimeIndexOptions,
} from '../../api/tee_times/index';
import { TeeTime } from '../../api/tee_times/tee_time';

import Vue from 'vue';

export default Vue.component('tee-time-index', {
  data() {
    return {
      agentFilter: '',
      customerFilter: {} as CustomerFilter,
      createdAtFilter: '',
      createdAtFilterFormatted: '',
      playDateFilter: '',
      playDateFilterFormatted: '',
      playTimeFilter: '',
      playersFilter: '',
      courseFilter: '',
      notesFilter: '',
      statusFilter: '',
      rateFilter: {} as RateFilter,
      confirmationFilter: '',
      fieldFilters: {} as TeeTimeIndexFilters,
      picker: new Date().toISOString().substr(0, 10),
      createdAt: '' as string,
      loading: true,
      loadingText: 'Loading Data...',
      headers: [
        { text: 'View', value: 'view' },
        {
          text: 'First Name',
          value: 'customer.first_name',
        },
        {
          text: 'Last Name',
          value: 'customer.last_name',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Created At',
          value: 'created_at',
        },
        {
          text: 'Play Date',
          value: 'play_date',
        },
        { text: 'Play Time', value: 'play_time' },
        { text: 'Players', value: 'players' },
        { text: 'Paid', value: 'transaction_record.total' },
        { text: 'Cost', value: 'cost' },
        { text: 'Course', value: 'course_name' },
        { text: 'Confirmation', value: 'confirmation' },
        { text: 'CC Conf', value: 'transaction_record.payment_id' },
        { text: 'Agent', value: 'agent' },
        { text: 'Notes', value: 'notes' },
        { text: 'Internal Notes', value: 'internal_notes' },
      ],
      options: {} as TeeTimeIndexOptions,
      teeTimeData: [] as TeeTime[],
      teeTimeCount: 0 as number,
      perPage: TeeTimeIndex.PER_PAGE as number,
      pageNo: 1 as number,
      sortDesc: [true] as boolean[],
      sortBy: ['created_at'] as string[],
    };
  },
  mounted() {
    this.loadFilters();
    this.refreshTable();
  },
  computed: {
    teeTimes(): TeeTime[] {
      return this.teeTimeData;
    },
  },
  methods: {
    parseDateInternal(date: string): string {
      return parseDate(date);
    },
    refreshTable() {
      this.options.sortDesc = this.sortDesc;
      this.options.sortBy = this.sortBy;
      this.options.page = this.pageNo;
      this.options.itemsPerPage = this.perPage;
      this.options.queryType = this.getQueryType();
      this.options.filters = this.fieldFilters;
      TeeTimeIndex.getData(this.options).then((response) => {
        this.teeTimeData = TeeTimeIndex.parseResponse(response.data);
        this.loading = false;
      });
      TeeTimeIndex.getCount(this.options).then((response) => {
        this.teeTimeCount = response.data;
      });
    },
    getQueryType(): string {
      if (this.$route.query.monthly === undefined) {
        return '';
      }
      if (this.$route.query.monthly === 'true') {
        return 'monthly';
      }
      return 'daily';
    },
    maybeSearch(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        this.updateFilters();
        FilterPersistence.saveFieldFilterState(this.$router, 'manage-tee-times', this.fieldFilters);
        this.search();
      }
    },
    loadFilters() {
      const filter = FilterPersistence.getFieldFiltersFromUrl(this.$route);
      if (!filter) {
        return;
      }
      this.fieldFilters = filter;

      this.customerFilter = this.fieldFilters.customer;
      this.agentFilter = this.fieldFilters.agent;
      this.createdAtFilter = this.fieldFilters.created_at;
      this.playDateFilter = this.fieldFilters.play_date;
      this.playTimeFilter = this.fieldFilters.play_time;
      this.playersFilter = this.fieldFilters.players;
      this.courseFilter = this.fieldFilters.course_name;
      this.confirmationFilter = this.fieldFilters.confirmation;
      this.statusFilter = this.fieldFilters.status;
      this.rateFilter = this.fieldFilters.rate;
    },
    search() {
      this.pageNo = 1;
      this.refreshTable();
    },
    updateFilters() {
      // TODO - Convert this to an interface so it's easier to read elsewhere.
      this.fieldFilters = {
        agent: this.agentFilter,
        created_at: this.createdAtFilter,
        play_date: this.playDateFilter,
        play_time: this.playTimeFilter,
        players: this.playersFilter,
        course_name: this.courseFilter,
        confirmation: this.confirmationFilter,
        notes: this.notesFilter,
        status: this.statusFilter,
        rate: this.rateFilter,
        customer: this.customerFilter,
      };
    },
  },
  watch: {
    createdAt: {
      handler() {
        this.createdAtFilter = this.createdAt;
      },
    },
    createdAtFilter: {
      handler() {
        this.createdAtFilterFormatted = formatDate(this.createdAtFilter);
        const e = this.$refs.createdAt as HTMLElement;
        e.focus();
      },
    },
    playDateFilter: {
      handler() {
        this.playDateFilterFormatted = formatDate(this.playDateFilter);
        const e = this.$refs.playDate as HTMLElement;
        e.focus();
      },
    },
    pageNo: {
      handler() {
        this.refreshTable();
      },
    },
    perPage: {
      handler() {
        this.refreshTable();
      },
    },
    sortDesc: {
      handler() {
        this.refreshTable();
      },
    },
  },
});
