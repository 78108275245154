
import GiftCertificateSales from '../components/gift_certificates/GiftCertificateSales.vue';
import Vue from 'vue';

Vue.component('gift-certificate-sales', GiftCertificateSales);

export default Vue.extend({
  components: { GiftCertificateSales },
  methods: {},
});
