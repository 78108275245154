
import {
  TeeTimeSummary,
  TeeTimeSummaryData,
} from '../../api/tee_times/summary';

import Vue from 'vue';

export default Vue.component('tee-time-summary', {
  props: ['isMonthly', 'title', 'date'],
  data() {
    return {
      revenue: '',
      data: {} as TeeTimeSummaryData,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      if (!this.date) {
        return;
      }
      TeeTimeSummary.getData(this.isMonthly, this.date).then((response) => {
        this.data = response;
        setTimeout(() => {
          this.load();
        }, 500000); // Five minutes.
      });
    },
    getLink() {
      return `/manage-tee-times?monthly=${this.isMonthly}`;
    },
  },
});
