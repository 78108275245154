
import { CourseListItem } from '../../api/courses/list';
import CourseSelector from '../courses/CourseSelector.vue';
import DefaultButtons from '../forms/DefaultButtons.vue';
import FormAlerts from '../alerts/FormAlerts.vue';
import { FormProcessor } from '../../api/forms/processor';
import {
  GiftCertificate,
  DefaultGiftCertificate,
} from '../../api/gift_certificates/gift_certificate';
import { GiftCertificateShow } from '../../api/gift_certificates/show';
import Vue from 'vue';

Vue.component('course-selector', CourseSelector);
Vue.component('default-buttons', DefaultButtons);
Vue.component('form-alerts', FormAlerts);

export default Vue.component('gift-certificate-show', {
  data() {
    return {
      changePurchaserDialog: false,
      changeRecipientDialog: false,
      id: -1 as number,
      giftCertData: DefaultGiftCertificate(),
      processor: new FormProcessor(),
      recipientId: -1,
    };
  },
  methods: {
    handleCourseUpdate(courseId: number) {
      this.giftCertData.course.id = courseId;
      this.processor.setModified();
    },
    loadForm() {
      if (this.id <= 0) {
        return;
      }
      GiftCertificateShow.getData(this.id).then((response) => {
        this.giftCertData = GiftCertificateShow.parseResponse(response);
      });
    },
    giftCertRules(field: string): string[] {
      if (this.processor.errorHandler.ruleMap.gift_certificate) {
        return this.processor.errorHandler.ruleMap.gift_certificate[field];
      }

      return [];
    },
    clearText(element: Element) {
      const htmlElement = element as HTMLElement;
      htmlElement.textContent = '';
    },
    setText(element: Element, text: string) {
      const htmlElement = element as HTMLElement;
      htmlElement.textContent = text;
    },
    sendReceipt() {
      this.clearText(this.$refs.sendCertSuccess as Element);
      this.clearText(this.$refs.sendCertError as Element);
      GiftCertificateShow.sendReceipt(this.giftCertData.serial).then(
        (response) => {
          if (response.status !== 200) {
            this.setText(
              this.$refs.sendCertError as Element,
              response.data.message,
            );
            return;
          }
          this.setText(this.$refs.sendCertSuccess as Element, 'Success');
        },
      );
    },
    sendGiftCert() {
      this.clearText(this.$refs.sendCertSuccess as Element);
      this.clearText(this.$refs.sendCertError as Element);
      GiftCertificateShow.sendCert(this.giftCertData.serial).then(
        (response) => {
          if (response.status !== 200) {
            this.setText(
              this.$refs.sendCertError as Element,
              response.data.message,
            );
            return;
          }
          this.setText(this.$refs.sendCertSuccess as Element, 'Success');
        },
      );
    },
    saveForm() {
      if (this.processor === null || !this.processor.shouldSave()) {
        return;
      }
      this.processor.saveData(
        GiftCertificateShow,
        this.giftCertData,
        this.$refs.submitForm,
      );
    },
    undoForm() {
      if (this.processor === null || !this.processor.undoForm()) {
        return;
      }
      this.loadForm();
    },
    updateCallback(formData: GiftCertificate) {
      this.giftCertData = formData;
      this.$emit('update:giftCertSaved');
      this.loadForm();
    },
    saveCallback(formData: GiftCertificate) {
      this.$emit('update:giftCertSaved');
      this.loadForm();
    },
    updatePurchaser(purchaserId: number) {
      this.changePurchaserDialog = false;
      if (purchaserId <= 0) {
        return;
      }
      this.processor.saveData(
        /* dataProcessor= */ undefined,
        purchaserId,
        this.$refs.submitForm,
        () => {
          return GiftCertificateShow.updatePurchaser(
            this.giftCertData.serial,
            purchaserId,
          );
        },
      );
    },
    updateRecipient(recipientId: number) {
      this.changeRecipientDialog = false;
      if (recipientId <= 0) {
        return;
      }
      this.processor.saveData(
        /* dataProcessor= */ undefined,
        recipientId,
        this.$refs.submitForm,
        () => {
          return GiftCertificateShow.updateRecipient(
            this.giftCertData.serial,
            recipientId,
          );
        },
      );
    },
    viewPurchaser() {
      this.$router.push({
        name: 'manage-customer',
        params: {
          id: this.giftCertData.customer.id.toString(),
        },
      });
    },
    viewRecipient() {
      this.$router.push({
        name: 'manage-customer',
        params: {
          id: this.giftCertData.recipient.id.toString(),
        },
      });
    },
  },
  mounted() {
    this.id = Number(this.$route.params.id);
    if (this.id <= 0) {
      return;
    }
    this.processor.setParams(
      this.id,
      this.giftCertData,
      DefaultGiftCertificate(),
      this.updateCallback,
      this.saveCallback,
    );
    this.loadForm();
  },
  computed: {
    purchaserName(): string {
      if (!this.giftCertData || !this.giftCertData.customer) {
        return '';
      }
      const customer = this.giftCertData.customer;
      return `${customer.first_name} ${customer.last_name}`;
    },
    recipientName(): string {
      if (!this.giftCertData || !this.giftCertData.recipient) {
        return '';
      }
      const recipient = this.giftCertData.recipient;
      return `${recipient.first_name} ${recipient.last_name}`;
    },

    // TODO: Use optional chaining in the template once it's supported.
    transactionItemTotalRule(): string[] {
      if (this.processor.errorHandler.ruleMap.transaction_item) {
        return this.processor.errorHandler.ruleMap.transaction_item.total;
      }

      return [];
    },
  },
});
