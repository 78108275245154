export interface AvailabilityQueueSummary {
  availabilityQueue: AvailabilityQueue[];
}

export interface AvailabilityQueue {
  courseName: string;
  courseId: number;
  date: string;
  time: number;
  slots: number[];
  isNew: boolean;
  readDate: string;
  createdAt: string;
  updatedAt: string;
  scrapedAt: string;
}

const DEFAULT_AVAILABILITY_QUEUE_SUMMARY: AvailabilityQueueSummary = {
  availabilityQueue: [],
};

export function DefaultAvailabilityQueueSummary(): AvailabilityQueueSummary {
  return JSON.parse(JSON.stringify(DEFAULT_AVAILABILITY_QUEUE_SUMMARY));
}

const DEFAULT_TEE_TIME_AVAILABILITY_TIME: AvailabilityQueue = {
  courseName: '',
  courseId: 0,
  date: '',
  time: 0,
  slots: [],
  isNew: false,
  readDate: '',
  createdAt: '',
  updatedAt: '',
  scrapedAt: '',
};

export function DefaultAvailabilityQueue(): AvailabilityQueue {
  return JSON.parse(JSON.stringify(DEFAULT_TEE_TIME_AVAILABILITY_TIME));
}
