import AccountsPayableMonthly from './views/reports/AccountsPayableMonthly.vue';
import LostLeads from './views/ManageLostLeads.vue';
import GiftCertificateReport from './views/reports/GiftCertificate.vue';
import TeeTimeAvailability from './views/TeeTimeAvailability.vue';
import AvailabilityQueue from './views/AvailabilityQueue.vue';
import EmailTemplates from './views/EmailTemplates.vue';
import TeeTimeReport from './views/reports/TeeTime.vue';
import AuthToken from './api/auth/Auth';
import SalesReport from './views/reports/SalesReport.vue';
import NewSalesReport from './views/reports/NewSalesReport.vue';
import Commission from './views/reports/Commission.vue';
import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import InternalBookingEngine from './views/InternalBookingEngine.vue';
import Login from './views/Login.vue';
import ManageCourse from './views/ManageCourse.vue';
import ManageCourses from './views/ManageCourses.vue';
import ManageCourseGroup from './views/ManageCourseGroup.vue';
import ManageCourseGroups from './views/ManageCourseGroups.vue';
import ManageCustomer from './views/ManageCustomer.vue';
import ManageCustomers from './views/ManageCustomers.vue';
import ManageDiscount from './views/ManageDiscount.vue';
import ManageDiscounts from './views/ManageDiscounts.vue';
import ManageGiftCertificateSales from './views/ManageGiftCertificateSales.vue';
import ManageGiftCertificate from './views/ManageGiftCertificate.vue';
import ManageGiftCertificates from './views/ManageGiftCertificates.vue';
import ManageHelpfulLink from './views/ManageHelpfulLink.vue';
import ManageHelpfulLinks from './views/ManageHelpfulLinks.vue';
import ManageHoliday from './views/ManageHoliday.vue';
import ManageHolidays from './views/ManageHolidays.vue';
import ManageMaintenance from './views/ManageMaintenance.vue';
import ManageMaintenances from './views/ManageMaintenances.vue';
import ManageRate from './views/ManageRate.vue';
import ManageRates from './views/ManageRates.vue';
import ManageRentalClub from './views/ManageRentalClub.vue';
import ManageRentalClubs from './views/ManageRentalClubs.vue';
import ManageRicUser from './views/ManageRicUser.vue';
import ManageRicUsers from './views/ManageRicUsers.vue';
import ManageTeeTime from './views/ManageTeeTime.vue';
import ManageTeeTimes from './views/ManageTeeTimes.vue';
import Reports from './views/Reports.vue';
import RevenueComparison from './views/reports/RevenueComparison.vue';
import RicMessages from './views/RicMessages.vue';
import Weekly from './views/reports/Weekly.vue';
import QueueManagement from './views/QueueManagement.vue';
import WaitListQueue from './views/WaitListQueue.vue';


Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'root',
      component: Home,
      meta: {
        title: 'Home',
      },
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        title: 'Home',
      },
    },
    {
      path: '/ibe',
      name: 'internal_booking_engine',
      props: true,
      component: InternalBookingEngine,
      meta: {
        title: 'Internal Booking Engine',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        title: 'Login',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => {
        AuthToken.clearAuthHeader();
        new Router().go(-1);
      },
    },
    {
      path: '/manage-courses',
      name: 'manage-courses',
      component: ManageCourses,
      meta: {
        title: 'Courses',
      },
    },
    {
      path: '/course/new',
      name: 'new-course',
      component: ManageCourse,
      meta: {
        title: 'New Course',
      },
    },
    {
      path: '/manage-course/:id',
      name: 'manage-course',
      component: ManageCourse,
      meta: {
        title: 'Manage Course',
      },
    },
    {
      path: '/manage-course-groups',
      name: 'manage-course-groups',
      component: ManageCourseGroups,
      meta: {
        title: 'Course Groups',
      },
    },
    {
      path: '/course-group/new',
      name: 'new-course-group',
      component: ManageCourseGroup,
      meta: {
        title: 'New Course Group',
      },
    },
    {
      path: '/manage-course-group/:id',
      name: 'manage-course-group',
      component: ManageCourseGroup,
      meta: {
        title: 'Manage Course Group',
      },
    },
    {
      path: '/manage-tee-times',
      name: 'manage-tee-times',
      component: ManageTeeTimes,
      meta: {
        title: 'Tee Times',
      },
    },
    {
      path: '/manage-tee-time/:id',
      name: 'manage-tee-time',
      component: ManageTeeTime,
      meta: {
        title: 'Tee Time',
      },
    },
    {
      path: '/manage-customers',
      name: 'manage-customers',
      component: ManageCustomers,
      meta: {
        title: 'Customers',
      },
    },
    {
      path: '/manage-customer/:id',
      name: 'manage-customer',
      component: ManageCustomer,
      meta: {
        title: 'Manage Customer',
      },
    },
    {
      path: '/manage-discounts',
      name: 'manage-discounts',
      component: ManageDiscounts,
      meta: {
        title: 'Discounts',
      },
    },
    {
      path: '/manage-discount/:id',
      name: 'manage-discount',
      component: ManageDiscount,
      meta: {
        title: 'Manage Discount',
      },
    },
    {
      path: '/gift-certificate-sales',
      name: 'gift-certificte-sales',
      component: ManageGiftCertificateSales,
      meta: {
        title: 'Gift Certificate Sales',
      },
    },
    {
      path: '/manage-gift-certificates',
      name: 'manage-gift-certificates',
      component: ManageGiftCertificates,
      meta: {
        title: 'Gift Certificates',
      },
    },
    {
      path: '/manage-gift-certificate/:id',
      name: 'manage-gift-certificate',
      component: ManageGiftCertificate,
      meta: {
        title: 'Gift Certificate',
      },
    },
    {
      path: '/manage-rates',
      name: 'manage-rates',
      component: ManageRates,
      meta: {
        title: 'Rates',
      },
    },
    {
      path: '/manage-rate/:id',
      name: 'manage-rate',
      component: ManageRate,
      meta: {
        title: 'Rate',
      },
    },
    {
      path: '/manage-rental-clubs',
      name: 'manage-rental-clubs',
      component: ManageRentalClubs,
      meta: {
        title: 'Rental Clubs',
      },
    },
    {
      path: '/rental-club/new/:course_id',
      name: 'new-rental-club',
      component: ManageRentalClub,
      meta: {
        title: 'Rental Club',
      },
    },
    {
      path: '/rental-club/:id',
      name: 'rental-club',
      component: ManageRentalClub,
      meta: {
        title: 'Rental Club',
      },
    },

    {
      path: '/reports',
      name: 'reports',
      component: Reports,
      meta: {
        title: 'Reports',
      },
    },
    {
      path: '/helpful-links',
      name: 'helpful-links',
      component: ManageHelpfulLinks,
      meta: {
        title: 'Helpful Links',
      },
    },
    {
      path: '/helpful-link/:id',
      name: 'helpful-link',
      component: ManageHelpfulLink,
      meta: {
        title: 'Helpful Links',
      },
    },
    {
      path: '/maintenance/new/:course_id',
      name: 'maintenance_new_course',
      component: ManageMaintenance,
      meta: {
        title: 'Maintenance',
      },
    },
    {
      path: '/maintenance/:id',
      name: 'maintenance',
      component: ManageMaintenance,
      meta: {
        title: 'Maintenance',
      },
    },
    {
      path: '/manage-maintenances',
      name: 'manage-maintenances',
      component: ManageMaintenances,
      meta: {
        title: 'Maintenances',
      },
    },
    {
      path: '/holiday/new/:course_id',
      name: 'manage-new-holiday',
      component: ManageHoliday,
      meta: {
        title: 'Holiday',
      },
    },
    {
      path: '/holiday/:id',
      name: 'holiday',
      component: ManageHoliday,
      meta: {
        title: 'Holiday',
      },
    },
    {
      path: '/manage-holidays',
      name: 'manage-holidays',
      component: ManageHolidays,
      meta: {
        title: 'Holidays',
      },
    },
    {
      path: '/ric-users',
      name: 'ric-users',
      component: ManageRicUsers,
      meta: {
        title: 'RIC Users',
      },
    },
    {
      path: '/reports/gift-certificate',
      name: 'gift-certificate-report',
      component: GiftCertificateReport,
      meta: {
        title: 'Gift Certificate Report',
      },
    },
    {
      path: '/reports/tee_time',
      name: 'ap-monthly',
      component: TeeTimeReport,
      meta: {
        title: 'Tee Time Report',
      },
    },
    {
      path: '/reports/ap_monthly',
      name: 'ap-monthly',
      component: AccountsPayableMonthly,
      meta: {
        title: 'Accounts Payable Monthly',
      },
    },
    {
      path: '/reports/sales_report',
      name: 'sales_report',
      component: SalesReport,
      meta: {
        title: 'Sales Report',
      },
    },
    {
      path: '/reports/new_sales_report',
      name: 'new_sales_report',
      component: NewSalesReport,
      meta: {
        title: 'New Sales Report',
      },
    },
    {
      path: '/reports/commission',
      name: 'commission',
      component: Commission,
      meta: {
        title: 'Commission',
      },
    },
    {
      path: '/reports/revenue-comparison',
      name: 'revenue-comparison',
      component: RevenueComparison,
      meta: {
        title: 'Revenue Comparison',
      },
    },
    {
      path: '/reports/weekly',
      name: 'weekly',
      component: Weekly,
      meta: {
        title: 'Weekly Report',
      },
    },
    {
      path: '/ric-user/:id',
      name: 'ric-user',
      component: ManageRicUser,
      meta: {
        title: 'Manage RIC User',
      },
    },
    {
      path: '/tee-time-availability',
      name: 'tee-time-availability',
      component: TeeTimeAvailability,
      meta: {
        title: 'Tee Time Availability',
      },
    },
    {
      path: '/wait-list-queue',
      name: 'wait-list-queue',
      component: WaitListQueue,
      meta: {
        title: 'Wait List Queue',
      },
    },
    {
      path: '/availability_queue',
      name: 'availability_queue',
      component: AvailabilityQueue,
      meta: {
        title: 'Availability Queue',
      },
    },
      {
      path: '/lost-leads',
      name: 'lost-leads',
      component: LostLeads,
      meta: {
        title: 'Lost Leads',
      },
    },
    {
      path: '/email-templates',
      name: 'email-templates',
      component: EmailTemplates,
      meta: {
        title: 'Email Templates',
      },
    },
    {
      path: '/ric-messages',
      name: 'ric-messages',
      component: RicMessages,
      meta: {
        title: 'RIC Messages',
      },
    },
    {
      path: '/queue-management',
      name: 'queue-management',
      component: QueueManagement,
      meta: {
        title: 'Queue',
      },
    },
  ],
});
