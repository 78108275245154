import axios from 'axios';
import { Course } from '../courses/course';
import { Holiday } from './holiday';

export interface HolidayIndexFilters {
  start_date: string;
  end_date: string;
  name: string;
  course_id: number;
  course_name: string;
}

export interface HolidayIndexOptions {
  itemsPerPage: number;
  sortBy: string[];
  sortDesc: boolean[];
  page: number;
  filters: HolidayIndexFilters;
}

export class HolidayIndex {
  public static PER_PAGE: number = 30;

  public static async getCount(filters?: HolidayIndexFilters): Promise<any> {
    return axios.get('/api/holidays/count', {
      params: {
        filters,
      },
    });
  }

  public static async getData(
    holidayIndexOptions?: HolidayIndexOptions,
  ): Promise<any> {
    if (holidayIndexOptions == null) {
      return axios.get('/api/holidays/index', {
        params: {
          per_page: this.PER_PAGE,
        },
      });
    }

    const perPage =
      holidayIndexOptions.itemsPerPage <= 0
        ? this.PER_PAGE
        : holidayIndexOptions.itemsPerPage;
    return axios.get('/api/holidays/index', {
      params: {
        per_page: perPage,
        sort_fields: holidayIndexOptions.sortBy,
        sort_desc: holidayIndexOptions.sortDesc,
        page: holidayIndexOptions.page,
        filters: holidayIndexOptions.filters,
      },
    });
  }

  public static parseResponse(response: any): Holiday[] {
    const holidayData = [] as Holiday[];
    for (const k in response) {
      if (!response[k]) {
        continue;
      }
      const item = response[k];
      const holiday = {} as Holiday;
      holiday.id = item.id;
      holiday.start_date = item.start_date;
      holiday.end_date = item.end_date;
      holiday.name = item.name;
      holiday.courses = item.courses;
      holidayData.push(holiday);
    }
    return holidayData;
  }
}
