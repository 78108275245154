
import ManageRateDates from './ManageRateDates.vue';
import ApiGolfRateShow from '../components/rates/ApiGolfRateShow.vue';
import RicGolfRateShow from '../components/rates/RicGolfRateShow.vue';
import Vue from 'vue';
import { RateDateRange } from '../api/rates/rate_date_range';
import { RateShow } from '../api/rates/show';
import { Rate, DefaultRate } from '../api/rates/rate';

Vue.component('rate-dates', ManageRateDates);
Vue.component('api-golf-rate-show', ApiGolfRateShow);
Vue.component('ric-rate-show', RicGolfRateShow);

export default Vue.extend({
  data() {
    return {
      rateData: DefaultRate(),
      rateDateRanges: [] as RateDateRange[],
      type: '',
    };
  },
  methods: {
    loadRateData() {
      RateShow.getData(this.rateData.id).then((response) => {
        this.rateData = RateShow.parseResponse(response);
        this.rateDateRanges = this.rateData.rate_date_ranges;
      });
    },
    isNew(): boolean {
      return this.$route.params.id === 'new';
    },
  },
  mounted() {
    if (!this.isNew()) {
      this.rateData.id = Number(this.$route.params.id);
      this.loadRateData();
    } else {
      this.rateData.id = 0;
    }
  },
  watch: {
    type() {
      this.rateData.type = this.type;
    },
    $route(_to: string, _from: string) {
      if (this.isNew()) {
        location.reload();
      }
    },
  },
});
