
import Vue from 'vue';

export default Vue.component('overlay-spinner', {
  props: ['show'],
  data() {
    return {
      active: false,
    };
  },
  watch: {
    show: {
      handler() {
        this.active = this.show;
      },
    },
    active: {
      handler() {
        this.$emit('update:overlayActive', this.active);
      },
    },
  },
});
