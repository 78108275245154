
import Auth from '../api/auth/Auth';
import RicUserIndex from '../components/ric_users/RicUserIndex.vue';
import Vue from 'vue';

Vue.component('ric-user-index', RicUserIndex);

export default Vue.extend({
  mounted() {
    if (!Auth.isAdmin()) {
      this.$router.push({ name: 'home' });
    }
  },
});
