
import { FilterPersistence } from '../../api/filter/filter_persistence';
import {
  HolidayIndex,
  HolidayIndexFilters,
  HolidayIndexOptions,
} from '../../api/holidays/holiday_index';
import { Holiday } from '../../api/holidays/holiday';
import { formatDate, parseDate } from '../../api/dates/date_formatter';

import Vue from 'vue';

export default Vue.component('holiday-index', {
  props: ['courseId'],
  data() {
    return {
      fieldFilters: {} as HolidayIndexFilters,
      startFilter: '',
      startFilterFormatted: '',
      endFilter: '',
      endFilterFormatted: '',
      nameFilter: '',
      courseFilter: '',
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Start', value: 'start_date' },
        { text: 'End', value: 'end_date' },
        { text: 'Courses', value: 'course_name' },
        { text: 'View', value: 'view' },
      ],
      loading: true,
      loadingText: 'Loading Data...',
      holidayCount: -1,
      holidays: [] as Holiday[],
      options: {} as HolidayIndexOptions,
      perPage: HolidayIndex.PER_PAGE,
      pageNo: 1 as number,
      showFilters: true as boolean,
      sortDesc: [] as boolean[],
      sortBy: [] as string[],
    };
  },
  mounted() {
    this.loadFilters();
    // We call this after loadFilters since some field filters are provided as props.
    this.updateFilters();
    this.refreshTable();
  },
  methods: {
    parseDateInternal(date: string): string {
      return parseDate(date);
    },
    refreshTable() {
      if (this.courseId) {
        this.showFilters = false;
      }
      this.options.sortDesc = this.sortDesc;
      this.options.sortBy = this.sortBy;
      this.options.page = this.pageNo;
      this.options.itemsPerPage = this.perPage;
      this.options.filters = this.fieldFilters;
      HolidayIndex.getData(this.options).then((response) => {
        this.holidays = HolidayIndex.parseResponse(response.data);
        this.loading = false;
      });
      HolidayIndex.getCount(this.fieldFilters).then((response) => {
        this.holidayCount = response.data;
      });
    },
    search() {
      this.pageNo = 1;
      this.refreshTable();
    },
    maybeSearch(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        this.updateFilters();
        FilterPersistence.saveFieldFilterState(
          this.$router,
          'manage-holidays',
          this.fieldFilters,
        );
        this.search();
      }
    },
    loadFilters() {
      const filter = FilterPersistence.getFieldFiltersFromUrl(this.$route);
      if (!filter) {
        return;
      }
      this.fieldFilters = filter;
      this.startFilter = this.fieldFilters.start_date;
      this.endFilter = this.fieldFilters.end_date;
      this.nameFilter = this.fieldFilters.name;
      this.courseFilter = this.fieldFilters.course_name;
    },
    updateFilters() {
      this.fieldFilters = {
        start_date: this.startFilter,
        end_date: this.endFilter,
        name: this.nameFilter,
        course_id: this.courseId,
        course_name: this.courseFilter,
      };
    },
  },
  watch: {
    endFilter: {
      handler() {
        this.endFilterFormatted = formatDate(this.endFilter);
        const e = this.$refs.endDate as HTMLElement;
        e.focus();
      },
    },
    startFilter: {
      handler() {
        this.startFilterFormatted = formatDate(this.startFilter);
        const e = this.$refs.startDate as HTMLElement;
        e.focus();
      },
    },
    pageNo: {
      handler() {
        this.refreshTable();
      },
    },
    perPage: {
      handler() {
        this.refreshTable();
      },
    },
    sortDesc: {
      handler() {
        this.refreshTable();
      },
    },
  },
});
