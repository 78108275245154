import axios from 'axios';
import { RicUser } from './ric_user';

export interface RicUserIndexFilters {
  email: string;
  groups: string[];
}

export interface RicUserIndexOptions {
  itemsPerPage: number;
  sortBy: string[];
  sortDesc: boolean[];
  page: number;
  filters: RicUserIndexFilters;
}

export class RicUserIndex {
  public static PER_PAGE: number = 30;

  public static async getCount(
    ricUserIndexOptions?: RicUserIndexOptions,
  ): Promise<any> {
    return axios.get('/api/ric_users/count', {
      params: {
        filters: ricUserIndexOptions?.filters,
      },
    });
  }

  public static async getData(
    ricUserIndexOptions?: RicUserIndexOptions,
  ): Promise<any> {
    if (ricUserIndexOptions == null) {
      return axios.get('/api/ric_users/index', {
        params: {
          per_page: this.PER_PAGE,
        },
      });
    }

    const perPage =
      ricUserIndexOptions.itemsPerPage <= 0
        ? this.PER_PAGE
        : ricUserIndexOptions.itemsPerPage;
    return axios.get('/api/ric_users/index', {
      params: {
        per_page: perPage,
        sort_fields: ricUserIndexOptions.sortBy,
        sort_desc: ricUserIndexOptions.sortDesc,
        page: ricUserIndexOptions.page,
        filters: ricUserIndexOptions.filters,
      },
    });
  }

  public static parseResponse(response: any): RicUser[] {
    const ricUserData = [] as RicUser[];
    for (const k in response) {
      if (!response[k]) {
        continue;
      }
      const item = response[k];
      const ricUser = {} as RicUser;
      const groups = item.admin_user_groups
        ? item.admin_user_groups.map((group: any) => group.level)
        : [];
      ricUser.email = item.email;
      ricUser.id = item.id;
      ricUser.groups = groups;
      ricUserData.push(ricUser);
    }
    return ricUserData;
  }
}
