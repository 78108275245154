import axios from 'axios';
import { CreditCard, DefaultCreditCard } from '../credit_card/credit_card';
import { RentalClub } from '../rental_clubs/rental_club';
import { Customer, DefaultCustomer } from '../customers/customer';
import { TeeTime, DefaultTeeTime } from '../tee_times/tee_time';

export interface BookingData {
  charge?: number;
  discount_code: string;
  gift_cert_serial: string;
  rental_clubs: RentalClub[];
  customer: Customer;
  tee_time: TeeTime;
  credit_card: CreditCard;
  screenshot: string;
  deal_id: number;
}

const DEFAULT_BOOKING_DATA: BookingData = {
  discount_code: '',
  gift_cert_serial: '',
  rental_clubs: [],
  customer: DefaultCustomer(),
  tee_time: DefaultTeeTime(),
  credit_card: DefaultCreditCard(),
  screenshot: '',
  deal_id: 0,
};

export function DefaultBookingData(): BookingData {
  return JSON.parse(JSON.stringify(DEFAULT_BOOKING_DATA));
}

export class BookingController {
  public static async loadRate(booking: BookingData): Promise<any | null> {
    return axios.post('/api/ibe/rate', {
      booking,
    });
    return null;
  }

  public static teeTimeFieldsValid(booking: BookingData): boolean {
    if (
      booking.tee_time !== undefined &&
      booking.tee_time.play_date !== '' &&
      booking.tee_time.play_time !== '' &&
      booking.tee_time.players > 0 &&
      booking.tee_time.course.id > 0
    ) {
      return true;
    }
    return false;
  }

  public static async applyGiftCert(booking: BookingData) {
    return axios.post('/api/ibe/validate_gift_cert', {
      booking,
    });
  }

  public static async applyDiscount(booking: BookingData) {
    return axios.post('/api/ibe/validate_discount', {
      booking,
    });
  }

  public static async book(booking: BookingData) {
    const r = await axios.post('/api/ibe/book', {
      booking,
    });
    return r;
  }
}
