
import { Customer, DefaultCustomer } from '../customers/customer';
import { LostLead, DefaultLostLead } from '../lost_leads/lost_lead';
import { TeeTime, DefaultTeeTime } from '../tee_times/tee_time';

export interface QueueSummary {
  courseName: string,
  date: string,
  humanDate: string,
  humanTime: string,
  time: number,
  spots: number,
  queue: QueueManagement[];
}

export interface QueueManagement {
  id: number;
  customer: Customer;
  lostLead: LostLead;
  queue_position: Number;
  book: string;
  teeTime: TeeTime;
}

const DEFAULT_QUEUE_SUMMARY: QueueSummary = {
  courseName: '',
  humanDate: '',
  date: '',
  time: 0,
  humanTime: '',
  spots: 0,
  queue: [],
};

export function DefaultQueueSummary(): QueueSummary {
  return JSON.parse(JSON.stringify(DEFAULT_QUEUE_SUMMARY));
}

const DEFAULT_QUEUE: QueueManagement = {
  id: 0,
  customer: DefaultCustomer(),
  lostLead: DefaultLostLead(),
  queue_position: 0,
  book: '',
  teeTime: DefaultTeeTime(),
};

export function DefaultQueueManagement(): QueueManagement {
  return JSON.parse(JSON.stringify(DEFAULT_QUEUE));
}
