import VueRouter, { Route } from 'vue-router'
import { filter } from 'vue/types/umd';

declare module 'vue/types/vue' {
  interface Vue {
    $router: VueRouter,
    $route: Route
  }
}

export class FilterPersistence {
  public static getFieldFiltersFromUrl(route: Route) {
    const filters = route.query.filters;
    if (!filters) {
      return null;
    }
    return JSON.parse(decodeURIComponent(String(filters)));
  }

  public static saveFieldFilterState(router: VueRouter, routeName: string, filterStateObj: object) {
    router.replace({
      name: routeName,
      query: {
        filters: encodeURIComponent(JSON.stringify(filterStateObj)),
      }
    });
  }
}