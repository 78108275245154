
import Auth from '../api/auth/Auth';
import RicUserShow from '../components/ric_users/RicUserShow.vue';
import Vue from 'vue';

Vue.component('ric_user-show', RicUserShow);

export default Vue.extend({
  mounted() {
    if (!Auth.isAdmin()) {
      this.$router.push({ name: 'home' });
    }
  },
});
