import axios from 'axios';
import { GiftCertificate } from './gift_certificate';
import { DefaultCourse } from '../courses/course';

export class GiftCertificateShow {
  public static async getData(id: number): Promise<any> {
    return axios.get(`/api/gift_certificates/show/${id}`);
  }

  public static async saveData(giftCertData: GiftCertificate): Promise<any> {
    const cleanGiftCertData = JSON.parse(JSON.stringify(giftCertData));

    // We don't post any customer data back for updates.
    cleanGiftCertData.recipient = null;
    cleanGiftCertData.customer = null;

    return axios.put('/api/gift_certificates/update', {
      id: cleanGiftCertData.id,
      gift_certificate: cleanGiftCertData,
    });
  }

  public static async sendReceipt(serial: string): Promise<any> {
    return axios.get(`/api/gift_certificates/receipt/${serial}`);
  }

  public static async sendCert(serial: string): Promise<any> {
    return axios.get(`/api/gift_certificates/certificate/${serial}`);
  }

  public static async updatePurchaser(serial: string, purchaserId: number) {
    return axios.put(
      `/api/gift_certificates/certificate/${serial}/update_purchaser`,
      {
        purchaser_id: purchaserId,
        serial,
      },
    );
  }

  public static async updateRecipient(serial: string, recipientId: number) {
    return axios.put(
      `/api/gift_certificates/certificate/${serial}/update_recipient`,
      {
        recipient_id: recipientId,
        serial,
      },
    );
  }

  public static parseResponse(response: any): GiftCertificate {
    const giftCert = {} as GiftCertificate;
    giftCert.id = response.data.id;
    giftCert.serial = response.data.serial;
    giftCert.who_from = response.data.who_from;
    giftCert.balance = response.data.balance;
    giftCert.raw_balance = response.data.raw_balance;
    giftCert.email_delivery = response.data.email_delivery ? 'email' : 'mail';
    giftCert.mail_recipient = response.data.mail_recipient
      ? 'recipient'
      : 'purchaser';
    giftCert.transaction_item = response.data.transaction_item;
    giftCert.customer = response.data.customer;
    giftCert.recipient = response.data.recipient;
    giftCert.greeting = response.data.greeting;
    giftCert.internal_memo = response.data.internal_memo;
    giftCert.course = response.data.course
      ? response.data.course
      : DefaultCourse();
    return giftCert;
  }
}
