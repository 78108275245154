import axios from 'axios';
import { Course } from './course';

export interface CourseIndexFilters {
  course_name: string;
  address: string;
  city: string;
  phone: string;
  course_email: string;
  internal_only: string;
  inactive: string;
}

export interface CourseIndexOptions {
  sortBy: string[];
  sortDesc: boolean[];
  filters: CourseIndexFilters;
}

export class CourseIndex {
  public static async getCount(
    courseIndexOptions?: CourseIndexOptions,
  ): Promise<any> {
    return axios.get('/api/courses/count', {
      params: {
        filters: courseIndexOptions?.filters,
      },
    });
  }

  public static async getData(
    courseIndexOptions?: CourseIndexOptions,
  ): Promise<any> {
    if (courseIndexOptions == null) {
      return axios.get('/api/courses/index');
    }

    return axios.get('/api/courses/index', {
      params: {
        sort_fields: courseIndexOptions.sortBy,
        sort_desc: courseIndexOptions.sortDesc,
        filters: courseIndexOptions.filters,
      },
    });
  }

  public static parseResponse(response: any): Course[] {
    const courseData = [] as Course[];
    for (const k in response) {
      if (!response[k]) {
        continue;
      }
      const item = response[k];
      const course = {} as Course;
      course.id = item.id;
      course.course_name = item.course_name;
      course.address = item.address;
      course.city = item.city;
      course.phone = item.phone;
      course.email = item.email;
      course.inactive = item.inactive;
      course.internal_only = item.internal_only;
      courseData.push(course);
    }
    return courseData;
  }
}
