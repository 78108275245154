
import {
  GiftCertificateSaleConfigurationActions,
  GiftCertificateSaleConfiguration,
  DefaultGiftCertificateSaleConfiguration,
} from '../../api/gift_certificates/gift_certificate_sales';
import Vue from 'vue';

import { FormProcessor } from '../../api/forms/processor';

export default Vue.component('gift-certificate-sales', {
  data() {
    return {
      saleConfigurationData: DefaultGiftCertificateSaleConfiguration(),
      processor: new FormProcessor(),
    };
  },
  methods: {
    loadForm() {
      GiftCertificateSaleConfigurationActions.getData().then((response) => {
        this.saleConfigurationData = GiftCertificateSaleConfigurationActions.parseResponse(
          response,
        );
      });
    },
    saveForm() {
      if (this.processor === null || !this.processor.shouldSave()) {
        return;
      }
      this.processor.saveData(
        GiftCertificateSaleConfigurationActions,
        this.saleConfigurationData,
        this.$refs.submitForm,
      );
    },
    undoForm() {
      if (this.processor === null || !this.processor.undoForm()) {
        return;
      }
      this.loadForm();
    },
  },
  mounted() {
    this.loadForm();
  },
  computed: {
    discountErrors(): string[] {
      if (
        !this.processor.errorHandler.ruleMap.gift_certificate_sale_configuration
      ) {
        return [];
      }
      return this.processor.errorHandler.ruleMap
        .gift_certificate_sale_configuration.discount;
    },
  },
});
