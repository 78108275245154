
import TeeTimeShow from '../components/tee_times/TeeTimeShow.vue';
import TransactionIndex from '../components/transactions/TransactionIndex.vue';
import Vue from 'vue';

Vue.component('tee-time-show', TeeTimeShow);
Vue.component('tee-time-transaction-index', TransactionIndex);

export default Vue.extend({
  data() {
    return {
      updateCounter: 0,
    };
  },
  methods: {
    handleTeeTimeSave() {
      this.updateCounter++;
    },
  },
});
