import axios from 'axios';
import { Course } from '../courses/course';
import { Customer } from '../customers/customer';
import { LostLead } from './lost_lead';

export interface LostLeadIndexOptions {
  itemsPerPage: number;
  sortBy: string[];
  sortDesc: boolean[];
  page: number;
  filters: {};
}

export class LostLeadIndex {
  public static PER_PAGE: number = 30;

  public static async getCount(): Promise<any> {
    return axios.get('/api/lost_leads/count');
  }

  public static async getData(
    lostLeadIndexOptions?: LostLeadIndexOptions,
  ): Promise<any> {
    if (lostLeadIndexOptions == null) {
      return axios.get('/api/lost_leads/index', {
        params: {
          per_page: this.PER_PAGE,
        },
      });
    }

    const perPage =
      lostLeadIndexOptions.itemsPerPage <= 0
        ? this.PER_PAGE
        : lostLeadIndexOptions.itemsPerPage;
    return axios.get('/api/lost_leads/index', {
      params: {
        per_page: perPage,
        sort_fields: lostLeadIndexOptions.sortBy,
        sort_desc: lostLeadIndexOptions.sortDesc,
        page: lostLeadIndexOptions.page,
      },
    });
  }

  public static parseResponse(response: any): LostLead[] {
    const lostLeads = [] as LostLead[];

    for (const k in response) {
      if (!response[k]) {
        continue;
      }
      const item = response[k];
      const lostLead = {} as LostLead;
      const course = {} as Course;
      const customer = {} as Customer;

      lostLead.id = item.id;
      lostLead.created_at = item.created_at;
      lostLead.players = item.players;
      lostLead.play_time = item.play_time;
      lostLead.lead_date = item.lead_date;

      course.id = item.id;
      if (item.course) {
        course.id = item.course.id;
        course.course_name = item.course.course_name;
      }

      if (item.customer) {
        customer.id = item.customer.id;
        customer.first_name = item.customer.first_name ?? '';
        customer.last_name = item.customer.last_name ?? '';
        customer.email = item.customer.email ?? '';
        customer.primary_phone = item.customer.primary_phone ?? '';
      }

      lostLead.course = course;
      lostLead.customer = customer;
      lostLead.tee_time_availability_summary = item.fore_up_availabilities;

      lostLeads.push(lostLead);
    }
    return lostLeads;
  }
}
