
import { Reporting } from '../../api/reports/reporting';
import { formatDate, parseDate } from '../../api/dates/date_formatter';
import AuthToken from '../../api/auth/Auth';
import MonthYearPicker from '../../components/dates/MonthYearPicker.vue';
import Vue from 'vue';

Vue.component('month-year-picker', MonthYearPicker);

export default Vue.extend({
  data() {
    return {
      compareStartDate: '',
      compareStartDateFormatted: '',
      compareEndDate: '',
      compareEndDateFormatted: '',
      startDate: '',
      startDateFormatted: '',
      endDate: '',
      endDateFormatted: '',
      dateType: 'created',
    };
  },
  methods: {
    parseDateInternal(date: string): string {
      return parseDate(date);
    },
    runReport() {
      Reporting.getRevenueComparison(
        this.startDate,
        this.endDate,
        this.compareStartDate,
        this.compareEndDate,
        this.dateType,
      );
    },
  },
  watch: {
    startDate() {
      this.startDateFormatted = formatDate(this.startDate);
    },
    endDate() {
      this.endDateFormatted = formatDate(this.endDate);
    },
    compareStartDate() {
      this.compareStartDateFormatted = formatDate(this.compareStartDate);
    },
    compareEndDate() {
      this.compareEndDateFormatted = formatDate(this.compareEndDate);
    },
  },
});
