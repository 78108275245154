import axios from 'axios';
import { TransactionRecord } from './transaction_record';
import { TransactionItem } from '../transaction_items/transaction_item';
import { TransactionItemIndex } from '../transaction_items/index';

export class TransactionRecordIndex {
  public static async getData(type: string, id: number): Promise<any> {
    if (type === 'TeeTime') {
      return axios.get(`/api/transaction_records/tee_time/${id}`);
    }
    if (type === 'GiftCertificate') {
      return axios.get(`/api/transaction_records/gift_certificate/${id}`);
    }
    if (type === 'GolfDiscount') {
      return axios.get(`/api/transaction_records/discount/${id}`);
    }
    return Promise.reject('Bad type');
  }

  public static parseTeeTimeResponse(response: any): TransactionRecord {
    const tr = {} as TransactionRecord;
    tr.created_at = response.data.created_at;
    tr.total = response.data.total;
    tr.transaction_items = TransactionItemIndex.parseResponse(response.data.transaction_items);
    return tr;
  }

  public static parseDiscountResponse(response: any): TransactionItem[] {
    return TransactionItemIndex.parseResponse(response.data);
  }

  public static parseGiftCertificateResponse(response: any): TransactionItem[] {
    return TransactionItemIndex.parseResponse(response.data);
  }
}
