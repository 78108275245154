
import { FilterPersistence } from '../../api/filter/filter_persistence';
import { RateIndex, RateIndexOptions } from '../../api/rates/index';
import { Rate } from '../../api/rates/rate';
import { RateFilter } from '../../api/rates/rate_filter';
import { formatDate, parseDate } from '../../api/dates/date_formatter';
import Vue from 'vue';

export default Vue.component('rate-index', {
  props: ['courseId', 'live', 'holidayId'],
  data() {
    return {
      courseNameFilter: '',
      daysFilter: '',
      timeStartFilter: '',
      timeEndFilter: '',
      startDateFilter: '',
      startDateFilterFormatted: '',
      endDateFilter: '',
      endDateFilterFormatted: '',
      picker: new Date().toISOString().substr(0, 10),
      rateFilter: '',
      internalMemoFilter: '',
      archivedFilter: 'false',
      disabledFilter: 'false',
      typeFilter: '',
      loading: true,
      loadingText: 'Loading Data...',
      headers: [
        { text: 'Course', value: 'course.course_name' },
        { text: 'Days', value: 'days' },
        { text: 'Times', value: 'times' },
        { text: 'Dates', value: 'dates' },
        { text: 'Rate', value: 'rate' },
        { text: 'Disabled?', value: 'disabled' },
        { text: 'Archived?', value: 'archived' },
        { text: 'I/Memo', value: 'internal_memo' },
        { text: 'Type', value: 'humanized_type' },
        { text: 'View', value: 'view' },
      ],
      fieldFilters: {} as RateFilter,
      options: {} as RateIndexOptions,
      perPage: RateIndex.PER_PAGE as number,
      pageNo: 1 as number,
      rateData: [] as Rate[],
      rateCount: 0 as number,
      showFilters: true,
      sortDesc: [true] as boolean[],
      sortBy: ['dates', 'course.course_name'] as string[],
      startDate: '' as string,
      endDate: '' as string,
    };
  },
  mounted() {
    this.loadFilters();
    // We call this after loadFilters since some field filters are provided as props.
    this.updateFilters();
    this.refreshTable();
  },
  methods: {
    parseDateInternal(date: string): string {
      return parseDate(date);
    },
    refreshTable() {
      if (this.courseId) {
        this.showFilters = false;
      }
      this.options.sortDesc = this.sortDesc;
      this.options.sortBy = this.sortBy;
      this.options.page = this.pageNo;
      this.options.itemsPerPage = this.perPage;
      this.options.filters = this.fieldFilters;
      RateIndex.getData(this.options, this.live).then((response) => {
        this.rateData = RateIndex.parseResponse(response.data);
        this.loading = false;
      });
      RateIndex.getCount(this.fieldFilters, this.live).then((response) => {
        this.rateCount = response.data;
      });
    },
    maybeSearch(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        this.updateFilters();
        FilterPersistence.saveFieldFilterState(
          this.$router,
          'manage-rates',
          this.fieldFilters,
        );
        this.search();
      }
    },
    search() {
      this.pageNo = 1;
      this.refreshTable();
    },
    loadFilters() {
      const filter = FilterPersistence.getFieldFiltersFromUrl(this.$route);
      if (!filter) {
        return;
      }
      this.fieldFilters = filter;

      this.courseNameFilter = this.fieldFilters.course_name;
      this.daysFilter = this.fieldFilters.days;
      this.startDateFilter = this.fieldFilters.start_date;
      this.endDateFilter = this.fieldFilters.end_date;
      this.timeStartFilter = this.fieldFilters.time_start;
      this.timeEndFilter = this.fieldFilters.time_end;
      this.rateFilter = this.fieldFilters.rate;
      this.internalMemoFilter = this.fieldFilters.internal_memo;
      this.disabledFilter = this.fieldFilters.disabled;
      this.archivedFilter = this.fieldFilters.archived;
    },
    updateFilters() {
      this.fieldFilters = {
        course_id: this.courseId,
        holiday_id: this.holidayId,
        course_name: this.courseNameFilter,
        days: this.daysFilter,
        start_date: this.startDateFilter,
        end_date: this.endDateFilter,
        time_start: this.timeStartFilter,
        time_end: this.timeEndFilter,
        rate: this.rateFilter,
        internal_memo: this.internalMemoFilter,
        disabled: this.disabledFilter,
        archived: this.archivedFilter,
      };
    },
  },
  computed: {
    rates(): Rate[] {
      return this.rateData;
    },
  },
  watch: {
    sortDesc: {
      handler() {
        this.refreshTable();
      },
    },
    pageNo: {
      handler() {
        this.refreshTable();
      },
    },
    perPage: {
      handler() {
        this.refreshTable();
      },
    },
    endDate: {
      handler() {
        this.endDateFilter = this.endDate;
      },
    },
    startDate: {
      handler() {
        this.startDateFilter = this.startDate;
      },
    },
    startDateFilter: {
      handler() {
        this.startDateFilterFormatted = formatDate(this.startDateFilter);
        const e = this.$refs.startDate as HTMLElement;
        e.focus();
      },
    },
    endDateFilter: {
      handler() {
        this.endDateFilterFormatted = formatDate(this.endDateFilter);
        const e = this.$refs.endDate as HTMLElement;
        e.focus();
      },
    },
  },
});
