import axios from 'axios';
import { RentalClub } from './rental_club';

export interface RentalClubIndexFilters {
  course_id: number;
  course_name: string;
  cost: string;
  rate: string;
  charge: string;
  brand: string;
  description: string;
  handed: string;
  sex: string;
}

export interface RentalClubIndexOptions {
  itemsPerPage: number;
  sortBy: string[];
  sortDesc: boolean[];
  page: number;
  filters: RentalClubIndexFilters;
}

export class RentalClubIndex {
  public static PER_PAGE: number = 30;

  public static async getCount(filters?: RentalClubIndexFilters): Promise<any> {
    return axios.get('/api/rental-clubs/count', {
      params: {
        filters,
      },
    });
  }

  public static async getData(
    rentalClubIndexOptions?: RentalClubIndexOptions,
  ): Promise<any> {
    if (rentalClubIndexOptions == null) {
      return axios.get('/api/rental-clubs/index', {
        params: {
          per_page: this.PER_PAGE,
        },
      });
    }
    const perPage =
      rentalClubIndexOptions.itemsPerPage === undefined ||
      rentalClubIndexOptions.itemsPerPage <= 0
        ? this.PER_PAGE
        : rentalClubIndexOptions.itemsPerPage;
    return axios.get('/api/rental-clubs/index', {
      params: {
        per_page: perPage,
        sort_fields: rentalClubIndexOptions.sortBy,
        sort_desc: rentalClubIndexOptions.sortDesc,
        page: rentalClubIndexOptions.page,
        filters: rentalClubIndexOptions.filters,
      },
    });
  }

  public static parseResponse(response: any): RentalClub[] {
    const rentalClubData = [] as RentalClub[];
    for (const k in response) {
      if (!response[k]) {
        continue;
      }
      const item = response[k];
      const clubRental = {} as RentalClub;
      clubRental.id = item.id;
      clubRental.cost = item.cost;
      clubRental.rate = item.rate;
      clubRental.raw_rate = item.raw_rate;
      clubRental.charge = item.charge;
      clubRental.raw_charge = item.raw_charge;
      clubRental.brand = item.brand;
      clubRental.description = item.description;
      clubRental.handed = item.handed;
      clubRental.sex = item.sex;
      clubRental.course = item.course;
      rentalClubData.push(clubRental);
    }
    return rentalClubData;
  }
}
