import axios from 'axios';
import {
  DefaultQueueSummary,
  DefaultQueueManagement,
  QueueSummary
} from './queue_summary';

export class QueueManagementIndex {
  public static loadQueue(fupId: number): Promise<any> {
    return axios.get(`/api/availability_queue/index`, {
      params: {
        id: fupId,
      },
    });
  }

  public static closeQueue(fupId: number): Promise<any> {
    return axios.get(`/api/availability_queue/close`, {
      params: {
        id: fupId,
      },
    });
  }

  public static parseResponse(response: any): QueueSummary {
    const queueSummaries = DefaultQueueSummary();
    const data = response.data;
    const queues = data.availability_queues;

    queueSummaries.courseName = data.course.course_name;
    queueSummaries.date = data.available_date;
    queueSummaries.humanDate = data.wordy_available_date;
    queueSummaries.humanTime = data.play_time_for_humans;
    queueSummaries.time = data.available_time;
    queueSummaries.spots = data.spots;

    for (let i = 0; i < queues.length; i++) {
      const item = queues[i];
      const queueManagement = DefaultQueueManagement();
      if (!item.lostLead && !item.customer) {
        continue;
      }

      queueManagement.id = item.id;

      queueManagement.queue_position = i + 1;

      if (item.lost_lead) {
        queueManagement.lostLead.id = item.lost_lead.id;
        queueManagement.lostLead.created_at = item.lost_lead.created_at;
        queueManagement.lostLead.players = item.lost_lead.players;
        queueManagement.lostLead.lead_date = item.lost_lead.lead_date;
        queueManagement.lostLead.play_time = item.lost_lead.play_time;
        queueManagement.lostLead.course.id = item.lost_lead.course_id;
      }

      if (item.tee_time) {
        queueManagement.teeTime.id = item.tee_time.id;
      }

      queueManagement.customer.id = item.customer.id;
      queueManagement.customer.first_name = item.customer.first_name ?? '';
      queueManagement.customer.last_name = item.customer.last_name ?? '';
      queueManagement.customer.postcode = item.customer.postcode ?? '';
      queueManagement.customer.email = item.customer.email ?? '';
      queueManagement.customer.primary_phone = item.customer.primary_phone ?? '';

      queueSummaries.queue.push(queueManagement);
    }
    return queueSummaries;
  }
}
