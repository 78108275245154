import axios from 'axios';
import { Discount } from './discount';

export class DiscountShow {
  public static async getData(id: number): Promise<any> {
    return axios.get(`/api/discounts/show/${id}`);
  }

  public static async deleteData(id: number): Promise<any> {
    return axios.delete(`/api/discounts/${id}`);
  }

  public static async saveData(discountData: Discount): Promise<any> {
    if (discountData.id === -1) {
      return axios.post('/api/discounts', {
        discount: discountData,
      });
    }
    return axios.put('/api/discounts/update', {
      id: discountData.id,
      discount: discountData,
    });
  }

  public static parseResponse(response: any): Discount {
    const discount = {} as Discount;
    discount.id = response.data.id;
    discount.code = response.data.code;
    discount.start_date = response.data.start_date;
    discount.end_date = response.data.end_date;
    discount.is_dollars = response.data.is_dollars;
    discount.amount = response.data.amount;
    discount.min_discount = response.data.min_discount;
    discount.max_discount = response.data.max_discount;
    discount.gift_certificate_stack = response.data.gift_certificate_stack;
    discount.enabled = response.data.enabled;
    discount.min_players = response.data.min_players;
    discount.max_players = response.data.max_players;
    discount.courses = response.data.courses;
    return discount;
  }
}
