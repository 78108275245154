export interface ApiUser {
  id: number;
  api_user_name: string;
}

export interface ApiUserItem {
  api_user: ApiUser;
  selected: boolean;
}

const DEFAULT_API_USER: ApiUser = {
  id: 0,
  api_user_name: '',
};

export function DefaultApiUser(): ApiUser {
  return JSON.parse(JSON.stringify(DEFAULT_API_USER));
}
