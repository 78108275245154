export interface CreditCard {
  number: string;
  expmo: string;
  expyr: string;
  cvv: string;
}

const DEFAULT_CREDIT_CARD: CreditCard = {
  number: '',
  expmo: '',
  expyr: '',
  cvv: '',
};

export function DefaultCreditCard(): CreditCard {
  return JSON.parse(JSON.stringify(DEFAULT_CREDIT_CARD));
}
