import axios from 'axios';
import { TemplateItem } from './email_template';

export class EmailTemplateShow {
  public static async getTemplateContent(templateItem: TemplateItem): Promise<any> {
    return axios.post('/api/templates/content', {
      template: templateItem,
    });
  }

  public static async saveTemplateContent(templateItem: TemplateItem, templateContent: string): Promise<any> {
    return axios.post('/api/templates/save', {
      template: templateItem,
      content: templateContent,
    });
  }
}
