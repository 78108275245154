import axios from 'axios';
import { DefaultCourse } from '../courses/course';
import { RentalClub } from './rental_club';

export class RentalClubShow {
  public static async getData(id: number): Promise<any> {
    return axios.get(`/api/rental-clubs/show/${id}`);
  }

  public static async deleteData(id: number): Promise<any> {
    return axios.delete(`/api/rental-clubs/${id}`);
  }

  public static async saveData(rentalClubData: RentalClub): Promise<any> {
    if (rentalClubData.id === -1) {
      return axios.post('/api/rental-clubs', {
        rentalClub: rentalClubData,
      });
    }
    return axios.put('/api/rental-clubs/update', {
      id: rentalClubData.id,
      rentalClub: rentalClubData,
    });
  }

  public static parseResponse(response: any): RentalClub {
    const rentalClub = {} as RentalClub;
    rentalClub.id = response.data.id;
    rentalClub.cost = response.data.cost;
    rentalClub.rate = response.data.rate;
    rentalClub.charge = response.data.charge;
    rentalClub.brand = response.data.brand;
    rentalClub.description = response.data.description;
    rentalClub.handed = response.data.handed;
    rentalClub.sex = response.data.sex;
    rentalClub.course = response.data.course
      ? response.data.course
      : DefaultCourse();
    rentalClub.archived = response.data.archived;
    rentalClub.is_dollars = response.data.is_dollars;
    rentalClub.commission = response.data.commission;
    return rentalClub;
  }
}
