
import {
  LostLeadIndex,
  LostLeadIndexOptions,
} from '../../api/lost_leads/lost_lead_index';
import { LostLead } from '../../api/lost_leads/lost_lead';
import { formatDate, parseDate } from '../../api/dates/date_formatter';
import Vue from 'vue';

export default Vue.component('lost-leads-index', {
  data() {
    return {
      fieldFilters: {},
      startFilter: '',
      startFilterFormatted: '',
      endFilter: '',
      endFilterFormatted: '',
      nameFilter: '',
      courseFilter: '',
      headers: [
        { text: 'Lead Date', value: 'lead_date' },
        { text: 'Course Name', value: 'course.course_name' },
        { text: 'Players', value: 'players' },
        { text: 'Play time', value: 'play_time' },
        { text: 'Tee Time avail summary', value: 'summary_links' },
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'customer.email' },
        { text: 'Phone', value: 'customer.primary_phone' },
      ],
      loading: true,
      loadingText: 'Loading Data...',
      lostLeadCount: -1,
      lostLeads: [] as LostLead[],
      options: {} as LostLeadIndexOptions,
      perPage: LostLeadIndex.PER_PAGE,
      pageNo: 1 as number,
      showFilters: true as boolean,
      sortDesc: [] as boolean[],
      sortBy: [] as string[],
    };
  },
  mounted() {
    this.refreshTable();
  },
  methods: {
    formatBookLink(foreTime: any): string {
      return `${foreTime.course.name}, ${foreTime.available_time}, ${foreTime.spots}`;
    },
    refreshTable() {
      this.options.sortDesc = this.sortDesc;
      this.options.sortBy = this.sortBy;
      this.options.page = this.pageNo;
      this.options.itemsPerPage = this.perPage;
      LostLeadIndex.getData(this.options).then((response) => {
        this.lostLeads = LostLeadIndex.parseResponse(response.data);
        this.loading = false;
      });
      LostLeadIndex.getCount().then((response) => {
        this.lostLeadCount = response.data;
      });
    },
  },
  watch: {
    pageNo: {
      handler() {
        this.refreshTable();
      },
    },
    perPage: {
      handler() {
        this.refreshTable();
      },
    },
    sortDesc: {
      handler() {
        this.refreshTable();
      },
    },
  },
});
