
import { AuthHeader } from '../api/auth/AuthHeader';
import Auth from '../api/auth/Auth';
import Vue from 'vue';
import router from '../router';

export default Vue.extend({
  data() {
    return {
      username: '',
      password: '',
      userNameRules: [(v: any) => !!v || 'User Name is required.'],
      passwordRules: [(v: any) => !!v || 'Password is required.'],
      formError: false,
    };
  },
  computed: {
    form(): Vue & { validate: () => boolean } {
      return this.$refs.submitForm as Vue & { validate: () => boolean };
    },
  },
  methods: {
    submit() {
      const self = this;
      if (!this.form.validate()) {
        return;
      }
      Auth.getToken(this.username, this.password)
        .then((responseHeader) => {
          if (responseHeader == null) {
            this.handleFailure();
            return;
          }
          this.handleSuccess(responseHeader, this.username);
        })
        .catch(() => {
          this.handleFailure();
        });
    },
    handleFailure() {
      this.formError = true;
    },
    handleSuccess(authHeader: AuthHeader, userName: string) {
      this.formError = false;
      Auth.level(userName);
      Auth.setAuthHeader(authHeader, userName);
      this.$router.push({ name: 'home' });
    },
  },
});
