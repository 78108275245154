import axios from 'axios';

export interface AgentFilter {
  sdgr: boolean;
  psgr: boolean;
  hotels: boolean;
}

export interface AgentSummaries {
  dailyRevenue: AgentRevenueSummary[];
  monthlyRevenue: AgentRevenueSummary[];
  dailyRounds: AgentRoundSummary[];
  monthlyRounds: AgentRoundSummary[];
}

export interface AgentRoundSummary {
  name: string;
  rounds: number;
  quantizedRounds: number;
}

export interface AgentRevenueSummary {
  name: string;
  revenue: number;
  quantizedRevenue: number;
}

export class AgentLeaderboard {
  public static async get(date: string, agentFilter: AgentFilter): Promise<AgentSummaries> {
    const agentSummaries = {
      dailyRevenue: [],
      monthlyRevenue: [],
      dailyRounds: [],
      monthlyRounds: [],
    } as AgentSummaries;
    const response = (await axios.post('/api/summary/agent_leaderboard', {
      date,
      agentFilter
    })) as any;

    agentSummaries.dailyRounds = AgentLeaderboard.getRoundSummary(
      response.data.daily.rounds,
    );
    agentSummaries.monthlyRounds = AgentLeaderboard.getRoundSummary(
      response.data.monthly.rounds,
    );
    agentSummaries.dailyRevenue = AgentLeaderboard.getRevenueSummary(
      response.data.daily.revenue,
    );
    agentSummaries.monthlyRevenue = AgentLeaderboard.getRevenueSummary(
      response.data.monthly.revenue,
    );

    return agentSummaries;
  }

  private static getRoundSummary(rounds: any): AgentRoundSummary[] {
    const agentSummaries: AgentRoundSummary[] = [];
    let maxRounds = 0;

    for (const k of Object.keys(rounds)) {
      if (rounds[k].rounds > maxRounds) {
        maxRounds = rounds[k].rounds;
      }
    }

    // Add 10% overhead to the max rounds so the chart doesn't look full.
    maxRounds *= 1.1;

    for (const k of Object.keys(rounds)) {
      const agentSummary = {} as AgentRoundSummary;
      agentSummary.name = k;
      agentSummary.rounds = rounds[k].rounds;
      agentSummary.quantizedRounds = Math.ceil(
        (agentSummary.rounds / maxRounds) * 100,
      );
      agentSummaries.push(agentSummary);
    }

    return agentSummaries;
  }

  private static getRevenueSummary(revenue: any): AgentRevenueSummary[] {
    const agentSummaries: AgentRevenueSummary[] = [];
    let maxRevenue = 0;

    for (const k of Object.keys(revenue)) {
      const revenueDollars = revenue[k].revenue / 100;
      if (revenueDollars > maxRevenue) {
        maxRevenue = revenueDollars;
      }
    }

    // Add 10% overhead to the max revenue so the chart doesn't look full.
    maxRevenue *= 1.1;

    for (const k of Object.keys(revenue)) {
      const revenueDollars = revenue[k].revenue / 100;
      const agentSummary = {} as AgentRevenueSummary;
      agentSummary.name = k;
      agentSummary.revenue = revenueDollars;
      agentSummary.quantizedRevenue = Math.ceil(
        (agentSummary.revenue / maxRevenue) * 100,
      );
      agentSummaries.push(agentSummary);
    }

    return agentSummaries;
  }
}

const DEFAULT_AGENT_FILTER: AgentFilter = {
  sdgr: true,
  psgr: true,
  hotels: true,
};

export function DefaultAgentFilter(): AgentFilter {
  return JSON.parse(JSON.stringify(DEFAULT_AGENT_FILTER));
}