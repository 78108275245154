
import { Reporting } from '../../api/reports/reporting';
import AuthToken from '../../api/auth/Auth';
import CourseCheckBoxes from '../../components/courses/CourseCheckBoxes.vue';
import MonthYearPicker from '../../components/dates/MonthYearPicker.vue';
import Vue from 'vue';
import { CourseListItem } from '../../api/courses/list';
import { formatDate, parseDate } from '../../api/dates/date_formatter';

Vue.component('course-check-boxes', CourseCheckBoxes);
Vue.component('month-year-picker', MonthYearPicker);

export default Vue.extend({
  data() {
    return {
      onlyPlayed: true,
      courseRules: [] as string[],
      selectedCourses: [] as CourseListItem[],
      startDate: '',
      startDateFormatted: '',
      endDate: '',
      endDateFormatted: '',
    };
  },
  methods: {
    handleCourseSelection(selectedCourses: CourseListItem[]) {
      this.selectedCourses = selectedCourses;
    },
    parseDateInternal(date: string): string {
      return parseDate(date);
    },
    runReport() {
      let courseIds: number[] = [];
      const courses = this.selectedCourses.filter((course) => course.selected);
      courseIds = courses.map((course) => Number(course.value));
      Reporting.getApMonthly(this.startDate, this.endDate, courseIds, this.onlyPlayed);
    },
  },
  watch: {
    startDate() {
      this.startDateFormatted = formatDate(this.startDate);
    },
    endDate() {
      this.endDateFormatted = formatDate(this.endDate);
    },
  },
});
