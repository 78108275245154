import axios from 'axios';
import { Maintenance } from './maintenance';

export interface MaintenanceIndexFilters {
  course_id: number;
  course_name: string;
  start_date: string;
  end_date: string;
  description: string;
}

export interface MaintenanceIndexOptions {
  itemsPerPage: number;
  sortBy: string[];
  sortDesc: boolean[];
  page: number;
  filters: MaintenanceIndexFilters;
}

export class MaintenanceIndex {
  public static PER_PAGE: number = 30;

  public static async getCount(
    filters?: MaintenanceIndexFilters,
  ): Promise<any> {
    return axios.get('/api/maintenances/count', {
      params: {
        filters,
      },
    });
  }

  public static async getData(
    maintenanceIndexOptions?: MaintenanceIndexOptions,
  ): Promise<any> {
    if (maintenanceIndexOptions == null) {
      return axios.get('/api/maintenances/index', {
        params: {
          per_page: this.PER_PAGE,
        },
      });
    }

    const perPage =
      maintenanceIndexOptions.itemsPerPage <= 0
        ? this.PER_PAGE
        : maintenanceIndexOptions.itemsPerPage;
    return axios.get('/api/maintenances/index', {
      params: {
        per_page: perPage,
        sort_fields: maintenanceIndexOptions.sortBy,
        sort_desc: maintenanceIndexOptions.sortDesc,
        page: maintenanceIndexOptions.page,
        filters: maintenanceIndexOptions.filters,
      },
    });
  }

  public static parseResponse(response: any): Maintenance[] {
    const maintenanceData = [] as Maintenance[];
    for (const k in response) {
      if (!response[k]) {
        continue;
      }
      const item = response[k];
      const maint = {} as Maintenance;
      maint.id = item.id;
      maint.start_date = item.start_date;
      maint.end_date = item.end_date;
      maint.description = item.description;
      maint.course = item.course;
      maintenanceData.push(maint);
    }
    return maintenanceData;
  }
}
