import axios from 'axios';
import { TeeTime } from './tee_time';

export class TeeTimeCancel {
  public static async getCancelOptions(id: number): Promise<any> {
    return axios.get(`/api/tee_times/cancel/options/${id}`);
  }

  public static async doCancel(
    id: number,
    refund: boolean,
    cancel: boolean,
    forceCancel: boolean,
    refundAmount: number,
  ): Promise<any> {
    return axios.post('/api/tee_times/cancel', {
      id,
      refund,
      cancel,
      force_cancel: forceCancel,
      refund_amount: refundAmount,
    });
  }
}
