var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","loading":_vm.loading,"loading-text":_vm.loadingText,"server-items-length":_vm.lostLeadCount,"headers":_vm.headers,"items":_vm.lostLeads,"items-per-page":_vm.perPage,"page":_vm.pageNo,"sort-desc":_vm.sortDesc,"sort-by":_vm.sortBy,"multi-sort":true,"footer-props":{
        'items-per-page-options': [5, 10, 20, 30, 40, 50],
      }},on:{"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":function($event){_vm.perPage=$event},"update:page":function($event){_vm.pageNo=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_vm._v(" "+_vm._s(item.customer.last_name + ', ' + item.customer.first_name)+" ")]}},{key:"item.summary_links",fn:function({ item }){return _vm._l((item.tee_time_availability_summary),function(tee_time_avail){return _c('router-link',{key:tee_time_avail.id,attrs:{"to":{
            name: 'internal_booking_engine',
            query: {
              providedPlayers: tee_time_avail.spots,
              providedCourseId: tee_time_avail.course.id,
              providedDateString: tee_time_avail.available_date,
              providedFirstName: item.customer.first_name,
              providedLastName: item.customer.last_name,
              providedEmail: item.customer.email,
              providedTime: tee_time_avail.available_time,
            },
          },"target":"_blank"}},[_vm._v("["+_vm._s(_vm.formatBookLink(tee_time_avail))+"],")])})}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }