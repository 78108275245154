
import CourseSelector from '../courses/CourseSelector.vue';
import DefaultButtons from '../forms/DefaultButtons.vue';
import FormAlerts from '../alerts/FormAlerts.vue';
import { CommonRateShow } from './CommonRateShow';
import { FormProcessor } from '../../api/forms/processor';
import { Rate, DefaultRate } from '../../api/rates/rate';
import { RateShow } from '../../api/rates/show';
import Vue from 'vue';

Vue.component('course-selector', CourseSelector);
Vue.component('default-buttons', DefaultButtons);
Vue.component('form-alerts', FormAlerts);

export default Vue.component('api-golf-rate-show', {
  props: ['propRateData'],
  data() {
    return {
      commonRateShow: new CommonRateShow('ApiGolfRate'),
      isNew: false,
      rateData: DefaultRate(),
      processor: new FormProcessor(),
    };
  },
  methods: {
    handleCourseUpdate(courseId: number) {
      this.rateData.course.id = courseId;
      this.processor.setModified();
    },
    loadForm() {
      this.commonRateShow.loadForm(this.rateData);
    },
    deleteForm() {
      this.commonRateShow.deleteForm(this.processor, this.rateData);
    },
    saveForm() {
      this.commonRateShow.saveForm(this.processor, this.$refs.submitForm, this.rateData);
    },
    undoForm() {
      if (this.processor === null || !this.processor.undoForm()) {
        return;
      }
      this.loadForm();
    },
    deleteCallback() {
      this.$router.replace({ name: 'manage-rates' });
    },
    saveCallback(data: any) {
      // Reload the form on save so the serializers can
      // format any $/% data properly.
      if (this.isNew && data.id) {
        this.isNew = false;
      }
      if (data.id) {
        this.rateData.id = data.id;
        this.$router.push({
          name: 'manage-rate',
          params: { id: `${this.rateData.id}` },
        });
      }
      this.loadForm();
    },
    updateCallback(formData: Rate) {
      this.rateData = formData;
    },
    handleNew() {
      this.rateData.id = 0;
      this.isNew = true;
      this.rateData = DefaultRate();
      this.rateData.type = 'ApiGolfRate';
      this.processor.setParams(
        this.rateData.id,
        this.rateData,
        DefaultRate(),
        this.updateCallback,
        this.saveCallback,
        this.deleteCallback,
      );
      this.processor.readOnly = false;
    },
    resetForm() {
      if (this.rateData.id === 0) {
        this.handleNew();
        return;
      }
      if (this.rateData.id < 0) {
        return;
      }
      this.processor.setParams(
        this.rateData.id,
        this.rateData,
        DefaultRate(),
        this.updateCallback,
        this.saveCallback,
        this.deleteCallback,
      );
      this.loadForm();
    },
  },
  mounted() {
    this.rateData = this.propRateData;
    this.rateData.type = 'ApiGolfRate';
    this.resetForm();
  },
  computed: {
    rateMap(): any {
      return this.commonRateShow.rateMap(this.processor);
    },
  },
  watch: {
    $route() {
      this.resetForm();
    },
  },
});
