
import { FilterPersistence } from '../../api/filter/filter_persistence';
import {
  CourseIndex,
  CourseIndexFilters,
  CourseIndexOptions,
} from '../../api/courses/index';
import { Course } from '../../api/courses/course';
import Vue from 'vue';

export default Vue.component('course-index', {
  data() {
    return {
      nameFilter: '',
      addressFilter: '',
      cityFilter: '',
      phoneFilter: '',
      emailFilter: '',
      internalFilter: '',
      inactiveFilter: '',
      loading: true,
      loadingText: 'Loading Data...',
      headers: [
        { text: 'Name', value: 'course_name' },
        { text: 'Inactive?', value: 'inactive' },
        { text: 'Internal?', value: 'internal_only' },
        { text: 'Address', value: 'address' },
        { text: 'City', value: 'city' },
        { text: 'Phone', value: 'phone' },
        { text: 'Email', value: 'email' },
        { text: 'View', value: 'view' },
      ],
      fieldFilters: {} as CourseIndexFilters,
      options: {} as CourseIndexOptions,
      courseData: [] as Course[],
      courseCount: 0 as number,
      pageNo: 1,
      sortDesc: [] as boolean[],
      sortBy: ['course_name'] as string[],
    };
  },
  mounted() {
    this.loadFilters();
    this.refreshTable();
  },
  methods: {
    refreshTable() {
      this.options.sortDesc = this.sortDesc;
      this.options.sortBy = this.sortBy;
      this.options.filters = this.fieldFilters;
      CourseIndex.getData(this.options).then((response) => {
        this.courseData = CourseIndex.parseResponse(response.data);
        this.loading = false;
      });
      CourseIndex.getCount(this.options).then((response) => {
        this.courseCount = response.data;
      });
    },
    search() {
      // A change in the search resets the page.
      this.pageNo = 1;
      this.refreshTable();
    },
    maybeSearch(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        this.updateFilters();
        FilterPersistence.saveFieldFilterState(
          this.$router,
          'manage-courses',
          this.fieldFilters,
        );
        this.search();
      }
    },
    loadFilters() {
      const filter = FilterPersistence.getFieldFiltersFromUrl(this.$route);
      if (!filter) {
        return;
      }
      this.fieldFilters = filter;

      this.nameFilter = this.fieldFilters.course_name;
      this.addressFilter = this.fieldFilters.address;
      this.cityFilter = this.fieldFilters.city;
      this.emailFilter = this.fieldFilters.course_email;
      this.phoneFilter = this.fieldFilters.phone;
      this.internalFilter = this.fieldFilters.internal_only;
      this.inactiveFilter = this.fieldFilters.inactive;
    },
    updateFilters() {
      this.fieldFilters = {
        course_name: this.nameFilter,
        address: this.addressFilter,
        city: this.cityFilter,
        course_email: this.emailFilter,
        phone: this.phoneFilter,
        internal_only: this.internalFilter,
        inactive: this.inactiveFilter,
      };
    },
  },
  computed: {
    courses(): Course[] {
      return this.courseData;
    },
  },
  watch: {
    pageNo: {
      handler() {
        this.refreshTable();
      },
    },
    perPage: {
      handler() {
        this.refreshTable();
      },
    },
    sortDesc: {
      handler() {
        this.refreshTable();
      },
    },
  },
});
