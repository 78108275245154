export interface TeeTimeAvailabilitySummary {
  availabilities: TeeTimeAvailability[];
}

export interface TeeTimeAvailabilityFilter {
  courseName: string;
  courseGroup: string;
  rate: string;
  sortingDate: string;
  time: string;
  slots: string;
}

export interface TeeTimeAvailability {
  id: number;
  courseName: string;
  courseId: number;
  date: string;
  time: number;
  sortingDate: string;
  slots: number[];
  isNew: boolean;
  readDate: string;
  createdAt: string;
  updatedAt: string;
  scrapedAt: string;
  rate: number;
  rateId: number;
  group_name: string;
}

const DEFAULT_TEE_TIME_AVAILABILITY_SUMMARY: TeeTimeAvailabilitySummary = {
  availabilities: [],
};

export function DefaultTeeTimeAvailabilitySummary(): TeeTimeAvailabilitySummary {
  return JSON.parse(JSON.stringify(DEFAULT_TEE_TIME_AVAILABILITY_SUMMARY));
}

const DEFAULT_TEE_TIME_AVAILABILITY_TIME: TeeTimeAvailability = {
  id: 0,
  courseName: '',
  courseId: 0,
  date: '',
  time: 0,
  sortingDate: '',
  slots: [],
  isNew: false,
  readDate: '',
  createdAt: '',
  updatedAt: '',
  scrapedAt: '',
  rate: 0,
  rateId: 0,
  group_name: '',
};

export function DefaultTeeTimeAvailability(): TeeTimeAvailability {
  return JSON.parse(JSON.stringify(DEFAULT_TEE_TIME_AVAILABILITY_TIME));
}
