import {GolfswitchCourse, DefaultGolfswitchCourse} from './golfswitch_course';
import {GolfnowCourse, DefaultGolfnowCourse} from './golfnow_course';
import {ClubProphetCourse, DefaultClubProphetCourse} from './club_prophet_course';

export interface Course {
  id: number;
  cart_rate: number;
  course_name: string;
  display_name?: string;
  address?: string;
  address_2?: string;
  city?: string;
  subdivision?: string;
  country_alpha2?: string;
  phone?: string;
  postcode?: string;
  email?: string;
  rate_required?: boolean;
  internal_only?: boolean;
  is_realtime?: boolean;
  inactive?: boolean;
  golfswitch_course: GolfswitchCourse;
  golfnow_course: GolfnowCourse;
  club_prophet_course: ClubProphetCourse;
  days_out_to_scrape: number;
  round_limit: number;
}

const DEFAULT_COURSE: Course = {
  id: 0,
  cart_rate: 0,
  course_name: '',
  display_name: '',
  address: '',
  address_2: '',
  city: '',
  subdivision: '',
  country_alpha2: '',
  phone: '',
  postcode: '',
  email: '',
  rate_required: true,
  internal_only: false,
  is_realtime: false,
  inactive: false,
  days_out_to_scrape: 0,
  round_limit: 0,
  golfswitch_course: DefaultGolfswitchCourse(),
  golfnow_course: DefaultGolfnowCourse(),
  club_prophet_course: DefaultClubProphetCourse(),
};

export function DefaultCourse(): Course {
  return JSON.parse(JSON.stringify(DEFAULT_COURSE));
}
