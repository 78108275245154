import axios from 'axios';

export interface GiftCertificateSummaryData {
  count: number;
  revenue: string;
  redeemed: number;
  top_courses: string[];
  mean_revenue: string;
  median_revenue: string;
  mode_revenue: string;
  previous_count: number;
  previous_revenue: string;
  previous_redeemed: number;
  previous_top_courses: string[];
  previous_mean_revenue: string;
  previous_median_revenue: string;
  previous_mode_revenue: string;
}

export class GiftCertificateSummary {
  public static async getData(
    isMonthly: boolean,
    date: string,
  ): Promise<GiftCertificateSummaryData> {
    const response = (await axios.get(
      `/api/gift_certificates/summary/${isMonthly}/${date}`,
    )) as any;
    const summary = {} as GiftCertificateSummaryData;

    summary.top_courses = [];
    summary.previous_top_courses = [];
    summary.count = response.data.sold;
    summary.revenue = response.data.revenue;
    summary.redeemed = response.data.redeemed;
    summary.previous_count = response.data.previous_sold;
    summary.previous_revenue = response.data.previous_revenue;
    summary.previous_redeemed = response.data.previous_redeemed;
    if (response.data.hasOwnProperty('averages')) {
      summary.mean_revenue = response.data.averages.mean;
      summary.median_revenue = response.data.averages.median;
      summary.mode_revenue = response.data.averages.mode;
    }

    if (response.data.hasOwnProperty('previous_averages')) {
      summary.previous_mean_revenue = response.data.previous_averages.mean;
      summary.previous_median_revenue = response.data.previous_averages.median;
      summary.previous_mode_revenue = response.data.previous_averages.mode;
    }
    if (response.data.hasOwnProperty('courses')) {
      for (const course in response.data.courses) {
        if (!course) {
          continue;
        }
        summary.top_courses.push(course);
      }
    }
    if (response.data.hasOwnProperty('previous_courses')) {
      for (const course in response.data.previous_courses) {
        if (!course) {
          continue;
        }
        summary.previous_top_courses.push(course);
      }
    }
    return summary;
  }
}
