export class FormErrorHandler {
  public ruleMap: any = {};
  public fatalErrorText: string = '';
  public hasError: boolean = false;
  public hasFatalError: boolean = false;

  public handleErrors(response: any) {
    if (!response) {
      this.hasFatalError = true;
      this.fatalErrorText = 'Undefined response.';

      return;
    }
    if (response.status === 422) {
      this.buildRuleMap();
      for (const [field, messages] of Object.entries(response.data.errors)) {
        this.ruleMap[field] = messages;
      }
      this.hasError = true;
      return;
    }
    this.hasFatalError = true;
    this.fatalErrorText = JSON.stringify(response.data);
  }

  public clearErrors() {
    this.hasError = false;
    this.hasFatalError = false;
  }

  public buildRuleMap() {
    this.ruleMap = {};
  }
}
