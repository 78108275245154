
import { ApiUser, ApiUserItem} from '../../api/api/api_user';
import { ApiList } from '../../api/api/list';
import Vue from 'vue';

export default Vue.component('course-check-boxes', {
  props: ['preExpand', 'readOnly', 'preselectedUsers', 'heading'],
  data() {
    return {
      userList: [] as ApiUserItem[],
      expanded: this.preExpand ? [0] : [],
    };
  },
  methods: {
    selectAll() {
      if (this.readOnly) {
        return;
      }
      this.userList.forEach((action: ApiUserItem) => {
        action.selected = true;
      });
      this.updateValue();
    },
    unselectAll() {
      if (this.readOnly) {
        return;
      }
      this.userList.forEach((action: ApiUserItem) => {
        action.selected = false;
      });
      this.updateValue();
    },
    updateValue() {
      this.$emit('update:selectedApiUsers', this.userList);
    },
    updateFromPreselected() {
      if (!Array.isArray(this.preselectedUsers)) {
        return;
      }
      const userIds = this.preselectedUsers.map((user) => {
        return user.api_user.id;
      });
      this.userList.forEach((user: ApiUserItem) => {
        if (userIds.indexOf(user.api_user.id) > -1) {
          user.selected = true;
        }
      });
    },
  },
  mounted() {
    ApiList.getUserList().then((response) => {
      this.userList = ApiList.parseActionUserResponse(response.data);
      if (
        Array.isArray(this.preselectedUsers) &&
        this.preselectedUsers.length >= 0
      ) {
        this.updateFromPreselected();
      } else {
        this.selectAll();
      }
    });
  },
  watch: {
    preselectedUsers: {
      handler() {
        this.updateFromPreselected();
      },
    },
  },
});
