
import { FilterPersistence } from '../../api/filter/filter_persistence';
import { CustomerIndex, CustomerIndexOptions } from '../../api/customers/index';
import { Customer } from '../../api/customers/customer';
import { CustomerFilter } from '../../api/customers/customer_filter';
import Vue from 'vue';

export default Vue.component('customer-index', {
  props: ['viewMode', 'selectMode', 'typeFilter'],
  data() {
    return {
      loading: true,
      loadingText: 'Loading Data...',
      headers: [
        { text: 'Record Type', value: 'type' },
        { text: 'First Name', value: 'first_name' },
        { text: 'Last Name', value: 'last_name' },
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'primary_phone' },
        { text: 'View', value: 'view' },
      ],
      customerFilter: {} as CustomerFilter,
      options: {} as CustomerIndexOptions,
      customerData: [] as Customer[],
      customerCount: 0 as number,
      perPage: CustomerIndex.PER_PAGE as number,
      pageNo: 1 as number,
      sortDesc: [] as boolean[],
      sortBy: [] as string[],
      mSelectMode: this.selectMode ? this.selectMode : 'normal',
      mViewMode: this.viewMode ? this.viewMode : 'normal',
    };
  },
  mounted() {
    this.perPage = this.mViewMode === 'compact' ? 5 : CustomerIndex.PER_PAGE;
    this.loadFilters();
    this.refreshTable();
  },
  methods: {
    fireSelect(itemId: number) {
      this.$emit('select-customer', itemId);
    },
    refreshTable() {
      this.options.sortDesc = this.sortDesc;
      this.options.sortBy = this.sortBy;
      this.options.page = this.pageNo;
      this.options.itemsPerPage = this.perPage;
      if (this.typeFilter !== '') {
        this.customerFilter.type = this.typeFilter;
      }
      this.options.customerFilter = this.customerFilter;
      CustomerIndex.getData(this.options).then((response) => {
        this.customerData = CustomerIndex.parseResponse(response.data);
        this.loading = false;
      });
      CustomerIndex.getCount(this.options).then((response) => {
        this.customerCount = response.data;
      });
    },
    search() {
      // A change in the search resets the page.
      this.pageNo = 1;
      this.refreshTable();
    },
    maybeSearch(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        FilterPersistence.saveFieldFilterState(this.$router, 'manage-customers', this.customerFilter);
        this.search();
      }
    },
     loadFilters() {
      const filter = FilterPersistence.getFieldFiltersFromUrl(this.$route);
      if (!filter) {
        return;
      }

      this.customerFilter = filter;
    },
  },
  computed: {
    customers(): Customer[] {
      return this.customerData;
    },
  },
  watch: {
    pageNo: {
      handler() {
        this.refreshTable();
      },
    },
    perPage: {
      handler() {
        this.refreshTable();
      },
    },
    sortDesc: {
      handler() {
        this.refreshTable();
      },
    },
  },
});
