
import { Course } from '../../api/courses/course';
import CourseCheckBoxes from '../courses/CourseCheckBoxes.vue';
import DefaultButtons from '../forms/DefaultButtons.vue';
import FormAlerts from '../alerts/FormAlerts.vue';
import { FormProcessor } from '../../api/forms/processor';
import { Holiday, DefaultHoliday } from '../../api/holidays/holiday';
import { HolidayShow } from '../../api/holidays/show';
import Vue from 'vue';
import { CourseListItem } from '../../api/courses/list';
import { formatDate, parseDate } from '../../api/dates/date_formatter';

Vue.component('course-check-boxes', CourseCheckBoxes);
Vue.component('default-buttons', DefaultButtons);
Vue.component('form-alerts', FormAlerts);

export default Vue.component('holiday-show', {
  props: ['holidayId'],
  data() {
    return {
      id: -1 as number,
      isNew: false,
      holidayData: DefaultHoliday() as Holiday,
      processor: new FormProcessor(),
      startDate: '',
      startDateFormatted: '',
      endDate: '',
      endDateFormatted: '',
    };
  },
  methods: {
    parseDateInternal(date: string): string {
      return parseDate(date);
    },
    handleCoursesUpdate(courses: []) {
      this.holidayData.courses = courses
        .filter((c: CourseListItem) => c.selected === true)
        .map((c: CourseListItem) => {
          return {
            id: Number(c.value),
          } as Course;
        });
      this.processor.setModified();
    },
    loadForm() {
      if (this.id <= 0) {
        return;
      }
      HolidayShow.getData(this.id).then((response) => {
        this.holidayData = HolidayShow.parseResponse(response);
        this.startDate = this.holidayData.start_date;
        this.endDate = this.holidayData.end_date;
      });
    },
    deleteForm() {
      if (this.processor === null || !this.processor.shouldDelete()) {
        return;
      }
      this.processor.deleteData(HolidayShow, this.id);
    },
    deleteCallback() {
      this.$router.replace({ name: 'manage-holidays' });
    },
    saveForm() {
      this.holidayData.id = this.id;
      if (this.processor === null || !this.processor.shouldSave()) {
        return;
      }
      this.processor.saveData(
        HolidayShow,
        this.holidayData,
        this.$refs.submitForm,
      );
    },
    undoForm() {
      if (this.processor === null || !this.processor.undoForm()) {
        return;
      }
      this.loadForm();
    },
    handleNew() {
      this.id = -1;
      this.isNew = true;
      // It's possible to preselect at most one course.
      if (this.$route.params.course_id) {
        this.holidayData.courses = [
          {
            id: Number(this.$route.params.course_id),
          } as Course,
        ];
      }
      this.processor.setParams(
        this.id,
        this.holidayData,
        DefaultHoliday(),
        this.updateCallback,
        this.saveCallback,
        this.deleteCallback,
      );
      this.processor.readOnly = false;
    },
    resetForm() {
      const id = this.$route.params.id;
      if (!id || id === 'new') {
        this.handleNew();
        return;
      }
      this.id = Number(id);
      if (this.id <= 0) {
        return;
      }
      this.processor.setParams(
        this.id,
        this.holidayData,
        DefaultHoliday(),
        this.updateCallback,
        this.saveCallback,
        this.deleteCallback,
      );
      this.loadForm();
    },
    saveCallback(data: any) {
      // Reload the form on save so the serializers can
      // format any $/% data properly.
      if (this.isNew && data.id) {
        this.id = data.id;
        this.holidayData.id = data.id;
        this.isNew = false;
      }
      this.loadForm();
    },
    updateCallback(formData: Holiday) {
      this.holidayData = formData;
    },
  },
  mounted() {
    if (this.holidayId) {
      this.id = this.holidayId;
    }
    this.resetForm();
  },
  watch: {
    endDate: {
      handler() {
        this.endDateFormatted = formatDate(this.endDate);
        this.holidayData.end_date = this.endDate;
      },
    },
    startDate: {
      handler() {
        this.startDateFormatted = formatDate(this.startDate);
        this.holidayData.start_date = this.startDate;
      },
    },
  },
});
