export interface ClubProphetCourse {
  id: number;
  club_prophet_id: number;
}

const DEFAULT_CLUB_PROPHET_COURSE: ClubProphetCourse = {
  id: 0,
  club_prophet_id: 0,
};

export function DefaultClubProphetCourse(): ClubProphetCourse {
  return JSON.parse(JSON.stringify(DEFAULT_CLUB_PROPHET_COURSE));
}
