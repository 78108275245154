import axios from 'axios';

export interface RateDay {
  id: number;
  name: string;
}

const DEFAULT_RATE_DAY: RateDay = {
  id: -1,
  name: '',
};

export function DefaultRateDay(): RateDay {
  return JSON.parse(JSON.stringify(DEFAULT_RATE_DAY));
}

export class RateDays {
  public static async getData(): Promise<any> {
    return axios.get('/api/rates/days');
  }
}
