var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-row',[_c('v-btn',{attrs:{"color":"green"},on:{"click":function($event){return _vm.closeQueue()}}},[_vm._v(" Close Queue ")])],1),_c('br'),_c('v-row',[_c('p',[_vm._v(" This queue is for "),_c('b',[_vm._v(_vm._s(_vm.summary.courseName))]),_vm._v(" for "),_c('b',[_vm._v(_vm._s(_vm.summary.spots))]),_vm._v(" slots on "),_c('b',[_vm._v(_vm._s(_vm.summary.humanDate))]),_vm._v(" at "),_c('b',[_vm._v(_vm._s(_vm.summary.humanTime))])])]),_c('br'),_c('v-data-table',{attrs:{"dense":"","loading":_vm.loading,"loading-text":_vm.loadingText,"headers":_vm.headers,"items":_vm.summary.queue,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","multi-sort":"","sort-by":['date']},scopedSlots:_vm._u([{key:"item.book",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
          name: 'internal_booking_engine',
          query: {
            providedCourseId: item.lostLead.course.id,
            providedDateString: item.lostLead.lead_date,
            providedTimeString: _vm.summary.time,
            providedFirstName: item.customer.first_name,
            providedLastName: item.customer.last_name,
            providedPostCode: item.customer.postcode,
            providedEmail: item.customer.email,
            providedPlayers: item.lostLead.players,
            providedQueueId: item.id,
          },
        }}},[_vm._v("Book Link")])]}},{key:"item.teeTime",fn:function({ item }){return [(item.teeTime.id > 0)?_c('router-link',{attrs:{"to":{ name: 'manage-tee-time', params: { id: item.teeTime.id } }}},[_vm._v("View")]):_vm._e(),(item.teeTime.id == -1)?_c('td',{staticClass:"text-start"},[_vm._v("Not booked")]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }