import axios from 'axios';
import { TeeTime } from './tee_time';
import { DefaultCourse } from '../courses/course';
import { DefaultCustomer } from '../customers/customer';
import { DefaultDiscount } from '../discounts/discount';
import { DefaultGiftCertificate } from '../gift_certificates/gift_certificate';

export class TeeTimeShow {
  public static async getData(id: number): Promise<any> {
    return axios.get(`/api/tee_times/show/${id}`);
  }

  // TODO: Move to cancel.ts
  public static async sendCancelEmail(id: number): Promise<any> {
    return axios.get(`/api/tee_times/cancel/email/${id}`);
  }

  public static async cancelTeeTime(id: number): Promise<any> {
    return axios.get(`/api/tee_times/cancel/${id}`);
  }

  public static async sendReceipt(id: number): Promise<any> {
    return axios.get(`/api/tee_times/receipt/${id}`);
  }

  public static async sendVoucher(id: number): Promise<any> {
    return axios.get(`/api/tee_times/voucher/${id}`);
  }

  public static async saveData(teeTimeData: TeeTime): Promise<any> {
    const cleanTeeTimeData = JSON.parse(JSON.stringify(teeTimeData));

    cleanTeeTimeData.course = { id: teeTimeData.course.id };
    delete cleanTeeTimeData.customer;
    delete cleanTeeTimeData.rate;
    delete cleanTeeTimeData.gift_certificate;
    delete cleanTeeTimeData.discount;

    return axios.put('/api/tee_times/update', {
      id: cleanTeeTimeData.id,
      tee_time: cleanTeeTimeData,
      gift_certificate: teeTimeData.gift_certificate,
      discount: teeTimeData.discount,
    });
  }

  public static async updateCustomer(teeTimeId: number, customerId: number) {
    return axios.put('/api/tee_times/update/customer', {
      id: teeTimeId,
      customer_id: customerId,
    });
  }

  public static parseResponse(response: any): TeeTime {
    const teeTime = {} as TeeTime;
    teeTime.id = response.data.id;
    teeTime.confirmation = response.data.confirmation;
    teeTime.play_date = response.data.play_date;
    teeTime.play_time = response.data.play_time;
    teeTime.notes = response.data.notes;
    teeTime.internal_notes = response.data.internal_notes;
    teeTime.folio = response.data.folio;
    teeTime.players = response.data.players;
    teeTime.gift_certificate_amount = response.data.gift_certificate_amount;
    teeTime.discount_amount = response.data.discount_amount;
    teeTime.cost = response.data.cost;
    teeTime.transaction_record = response.data.transaction_record;
    teeTime.status = response.data.status;
    teeTime.pipedrive_deal_id = response.data.pipedrive_deal_id;

    teeTime.customer =
      response.data.customers.length > 0
        ? response.data.customers[0]
        : DefaultCustomer();
    teeTime.course = response.data.course
      ? response.data.course
      : DefaultCourse();

    if (response.data.golf_rate) {
      teeTime.rate = response.data.golf_rate;
    }

    if (response.data.gift_certificate) {
      teeTime.gift_certificate = response.data.gift_certificate;
    } else {
      teeTime.gift_certificate = DefaultGiftCertificate();
    }

    if (response.data.golf_discount) {
      teeTime.discount = response.data.golf_discount;
    } else {
      teeTime.discount = DefaultDiscount();
    }

    return teeTime;
  }
}
