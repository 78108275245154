
import GiftCertificateShow from '../components/gift_certificates/GiftCertificateShow.vue';
import TransactionIndex from '../components/transactions/TransactionIndex.vue';
import Vue from 'vue';

Vue.component('gift-certificate-show', GiftCertificateShow);
Vue.component('transaction-index', TransactionIndex);

export default Vue.extend({
  data() {
    return {
      updateCounter: 0,
    };
  },
  methods: {
    handleGiftCertSave() {
      this.updateCounter++;
    },
  },
});
