import axios from 'axios';
import { RateDateRange } from './rate_date_range';

export class ShowRateDateRange {
  public static async deleteData(id: number): Promise<any> {
    return axios.delete(`/api/rate_date_ranges/${id}`);
  }

  public static async saveData(rateDateRange: RateDateRange): Promise<any> {
    if (!rateDateRange.id || rateDateRange.id <= 0) {
      return axios.post('/api/rate_date_ranges', {
        rate_date_range: rateDateRange,
      });
    }
    return axios.put('/api/rate_date_ranges/update', {
      rate_date_range: rateDateRange,
    });
  }
}
