
import DefaultButtons from '../forms/DefaultButtons.vue';
import FormAlerts from '../alerts/FormAlerts.vue';
import { FormProcessor } from '../../api/forms/processor';
import {
  HelpfulLink, DefaultHelpfulLink,
} from '../../api/helpful_links/HelpfulLink';
import { HelpfulLinkShow } from '../../api/helpful_links/show';
import Vue from 'vue';

Vue.component('default-buttons', DefaultButtons);
Vue.component('form-alerts', FormAlerts);

export default Vue.component('helpful-link-show', {
  data() {
    return {
      id: -1 as number,
      isNew: false,
      helpfulLinkData: {} as HelpfulLink,
      processor: new FormProcessor(),
    };
  },
  methods: {
    loadForm() {
      if (this.id <= 0) {
        return;
      }
      HelpfulLinkShow.getData(this.id).then((response) => {
        this.helpfulLinkData = HelpfulLinkShow.parseResponse(response);
      });
    },
    deleteForm() {
      if (this.processor === null || !this.processor.shouldDelete()) {
        return;
      }
      this.processor.deleteData(HelpfulLinkShow, this.id);
    },
    saveForm() {
      if (this.processor === null || !this.processor.shouldSave()) {
        return;
      }
      this.processor.saveData(HelpfulLinkShow, this.helpfulLinkData, this.$refs.submitForm);
    },
    undoForm() {
      if (this.processor === null || !this.processor.undoForm()) {
        return;
      }
      this.loadForm();
    },
    deleteCallback() {
      this.$router.replace({ name: 'helpful-links' });
    },
    updateCallback(formData: HelpfulLink) {
      this.helpfulLinkData = formData;
    },
    handleNew() {
      this.id = 0;
      this.isNew = true;
      this.helpfulLinkData = DefaultHelpfulLink();
      this.processor.setParams(
        this.id,
        this.helpfulLinkData,
        DefaultHelpfulLink(),
        this.updateCallback,
      );
      this.processor.readOnly = false;
    },
    resetForm() {
      const id = this.$route.params.id;
      if (id === 'new') {
        this.handleNew();
        return;
      }
      this.id = Number(id);
      if (this.id <= 0) {
        return;
      }
      this.processor.setParams(
        this.id,
        this.helpfulLinkData,
        DefaultHelpfulLink(),
        this.updateCallback,
        this.deleteCallback,
      );
      this.loadForm();
    },
  },
  mounted() {
    const id = this.$route.params.id;
    if (id === 'new') {
      this.handleNew();
      return;
    }
    this.id = Number(id);
    if (this.id <= 0) {
      return;
    }
    this.processor.setParams(
      this.id,
      this.helpfulLinkData,
      DefaultHelpfulLink(),
      this.updateCallback,
      undefined,
      this.deleteCallback,
    );
    this.loadForm();

    this.resetForm();
  },
  watch: {
    $route() {
      this.resetForm();
    },
  },
});
