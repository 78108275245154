export interface ApiAction {
  id: number;
  api_action_name: string;
}

export interface ApiActionItem {
  action: ApiAction;
  selected: boolean;
}

const DEFAULT_API_ACTION: ApiAction = {
  id: 0,
  api_action_name: '',
};

export function DefaultApiAction(): ApiAction {
  return JSON.parse(JSON.stringify(DEFAULT_API_ACTION));
}
