import axios from 'axios';
import {Course} from '../courses/course';
import { Deal } from './deal';

export class DealIndex {
  public static async getDeals(
    dealId: number | null,
    course: Course | null,
    email: string | null,
  ): Promise<any> {
    return axios.get('/api/pipedrive/deals', {
      params: {
        dealId,
        course,
        email,
      },
    });
  }

  public static parseDeals(dealsResponse: any) {
    const deals = [];

    const pipeUrl = dealsResponse.data.pipe_url;

    for (const dealObj of dealsResponse.data.deals) {
      if (!dealObj) {
        continue;
      }

      const deal = {} as Deal;

      deal.id = dealObj.id;
      deal.title = dealObj.title;
      deal.pipeUrl = pipeUrl;
      deals.push(deal);
    }
    return deals;
  }
}
