import axios from 'axios';
import {
  DefaultAvailabilityQueue,
  DefaultAvailabilityQueueSummary,
  AvailabilityQueue,
  AvailabilityQueueSummary,
} from './availability_queue_summary';

export class AvailabilityQueueIndex {
  public static async getData(): Promise<any> {
    return axios.get('/api/fore_up_availability/index');
  }

  public static parseResponse(response: any): AvailabilityQueueSummary {
    const AvailabilityQueueSummaries = DefaultAvailabilityQueueSummary();

    for (const k in response) {
      if (k === 'created_at') {
        continue;
      }
      if (!response[k]) {
        continue;
      }
      const time = response[k];
      const keyArray = JSON.parse(k);
      Object.keys(time).forEach((date) => {
        const data = time[date];
        Object.keys(data).forEach((dataKey) => {
          const AvailabilityQueue = DefaultAvailabilityQueue();
          AvailabilityQueue.courseId = Number(keyArray[1]);
          AvailabilityQueue.courseName = keyArray[0];
          AvailabilityQueue.date = date;
          AvailabilityQueue.time = Number(dataKey);
          const availabilityData = data[dataKey] as any;
          AvailabilityQueue.slots = availabilityData.spots;
          AvailabilityQueue.isNew = availabilityData.is_new;
          AvailabilityQueue.readDate = availabilityData.read_date;
          AvailabilityQueue.createdAt = availabilityData.created_at;
          AvailabilityQueue.updatedAt = availabilityData.updated_at;
          AvailabilityQueue.scrapedAt = availabilityData.scraped_at;
          AvailabilityQueueSummaries.availabilityQueue.push(AvailabilityQueue);
        });
      });
    }

    return AvailabilityQueueSummaries;
  }
}
