import axios from 'axios';

// TODO: Replace this and the country list with a generic interface for the vuetify select.
export interface CourseListItem {
  text: string;
  value: string;
  selected: boolean;
}

export class CourseList {
  public static async get(): Promise<any> {
    return axios.get('/api/courses/list');
  }

  public static async getLiveCourses(): Promise<any> {
    return axios.get('/api/courses/live_list');
  }

  public static async getInternalCourses(): Promise<any> {
    return axios.get('/api/courses/internal_list');
  }

  public static parseResponse(response: any): CourseListItem[] {
    const courseData = [] as CourseListItem[];
    response.data.forEach((course: any) => {
      courseData.push({ selected: false, value: course[0], text: course[1] });
    });
    return courseData;
  }
}
