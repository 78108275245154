
import CourseShow from '../components/courses/CourseShow.vue';
import CourseGroupIndex from '../components/course_groups/CourseGroupIndex.vue';
import HolidayIndex from '../components/holidays/HolidayIndex.vue';
import MaintenanceIndex from '../components/maintenances/MaintenanceIndex.vue';
import RateIndex from '../components/rates/RateIndex.vue';
import RateShow from '../components/rates/RicGolfRateShow.vue';
import RentalClubIndex from '../components/rental_clubs/RentalClubIndex.vue';
import Vue from 'vue';

Vue.component('course-group-index', CourseGroupIndex);
Vue.component('course-show', CourseShow);
Vue.component('holiday-index', HolidayIndex);
Vue.component('maintenance-index', MaintenanceIndex);
Vue.component('rate-index', RateIndex);
Vue.component('rate-show', RateShow);
Vue.component('rental-club-index', RentalClubIndex);

export default Vue.extend({
  data() {
    return {
      isNew: false,
    };
  },
  methods: {
    initPage() {
      if (!Number(this.$route.params.id)) {
        this.isNew = true;
      }
    },
  },
  mounted() {
    this.initPage();
  },
  watch: {
    $route() {
      this.initPage();
    },
  },
});
