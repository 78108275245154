import { Course, DefaultCourse } from '../courses/course';

export interface RentalClub {
  id: number;
  cost: string;
  rate: string;
  raw_rate: number;
  charge: string;
  raw_charge: number;
  brand: string;
  description: string;
  handed: string;
  sex: string;
  course: Course;
  quantity: number;
  archived: boolean;
  is_dollars: boolean;
  commission: number;
}

const DEFAULT_RENTAL_CLUB: RentalClub = {
  id: -1,
  cost: '',
  rate: '',
  raw_rate: 0,
  charge: '',
  raw_charge: 0,
  brand: '',
  description: '',
  handed: '',
  sex: '',
  course: DefaultCourse(),
  quantity: 0,
  archived: false,
  is_dollars: true,
  commission: 0,
};

export function DefaultRentalClub(): RentalClub {
  return JSON.parse(JSON.stringify(DEFAULT_RENTAL_CLUB));
}
