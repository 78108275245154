
import { FilterPersistence } from '../../api/filter/filter_persistence';
import { CustomerFilter } from '../../api/customers/customer_filter';
import {
  GiftCertificateIndex,
  GiftCertificateIndexFilters,
  GiftCertificateIndexOptions,
} from '../../api/gift_certificates/index';
import { GiftCertificate } from '../../api/gift_certificates/gift_certificate';
import { TransactionItemFilter } from '../../api/transaction_items/transaction_item_filter';
import { formatDate, parseDate } from '../../api/dates/date_formatter';
import Vue from 'vue';

export default Vue.component('gift-certificate-index', {
  data() {
    return {
      createdAtFilter: '',
      createdAtFilterFormatted: '',
      serialFilter: '',
      internalMemoFilter: '',
      customerFilter: {} as CustomerFilter,
      transactionItemFilter: {} as TransactionItemFilter,
      balanceFilter: '',
      picker: new Date().toISOString().substr(0, 10),
      loading: true,
      loadingText: 'Loading Data...',
      headers: [
        {
          text: 'Created At',
          value: 'created_at',
        },

        { text: 'Customer Name', value: 'customerName' },
        { text: 'Email', value: 'customer.email' },
        { text: 'Serial', value: 'serial' },
        { text: 'Value', value: 'transaction_item.total' },
        { text: 'Balance', value: 'balance' },
        { text: 'Internal Memo', value: 'internal_memo' },
        { text: 'View', value: 'view' },
      ],
      fieldFilters: {} as GiftCertificateIndexFilters,
      options: {} as GiftCertificateIndexOptions,
      giftCertificateData: [] as GiftCertificate[],
      giftCertificateCount: 0 as number,
      perPage: GiftCertificateIndex.PER_PAGE as number,
      pageNo: 1 as number,
      sortDesc: [true] as boolean[],
      sortBy: ['created_at'] as string[],
    };
  },
  mounted() {
    this.loadFilters();
    this.refreshTable();
  },
  methods: {
    customerName(item: any) {
      if (!item.customer) {
        return '';
      }
      return `${item.customer.first_name} ${item.customer.last_name}`;
    },
    refreshTable() {
      this.options.sortDesc = this.sortDesc;
      this.options.sortBy = this.sortBy;
      this.options.page = this.pageNo;
      this.options.itemsPerPage = this.perPage;
      this.options.queryType = this.getQueryType();
      this.options.filters = this.fieldFilters;
      GiftCertificateIndex.getData(this.options).then((response) => {
        this.giftCertificateData = GiftCertificateIndex.parseResponse(
          response.data,
        );
        this.loading = false;
      });
      GiftCertificateIndex.getCount(this.options).then((response) => {
        this.giftCertificateCount = response.data;
      });
    },
    search() {
      this.pageNo = 1;
      this.refreshTable();
    },
    maybeSearch(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        this.updateFilters();
        FilterPersistence.saveFieldFilterState(
          this.$router,
          'manage-gift-certificates',
          this.fieldFilters,
        );
        this.search();
      }
    },
    getQueryType(): string {
      if (this.$route.query.monthly === undefined) {
        return '';
      }
      if (this.$route.query.monthly === 'true') {
        return 'monthly';
      }
      return 'daily';
    },
    loadFilters() {
      const filter = FilterPersistence.getFieldFiltersFromUrl(this.$route);
      if (!filter) {
        return;
      }
      this.fieldFilters = filter;

      this.createdAtFilter = this.fieldFilters.created_at;
      this.serialFilter = this.fieldFilters.serial;
      this.transactionItemFilter= this.fieldFilters.transaction_item;
      this.customerFilter= this.fieldFilters.customer;
      this.balanceFilter= this.fieldFilters.balance;
      this.internalMemoFilter= this.fieldFilters.internal_memo;
    },
    updateFilters() {
      this.fieldFilters = {
        created_at: this.createdAtFilter,
        serial: this.serialFilter,
        transaction_item: this.transactionItemFilter,
        customer: this.customerFilter,
        balance: this.balanceFilter,
        internal_memo: this.internalMemoFilter,
      };
    },
    parseDateInternal(date: string): string {
      return parseDate(date);
    },
  },
  computed: {
    giftCertificates(): GiftCertificate[] {
      return this.giftCertificateData;
    },
  },
  watch: {
    createdAtFilter: {
      handler() {
        this.createdAtFilterFormatted = formatDate(this.createdAtFilter);
        const e = this.$refs.createdAt as HTMLElement;
        e.focus();
      },
    },
    pageNo: {
      handler() {
        this.refreshTable();
      },
    },
    perPage: {
      handler() {
        this.refreshTable();
      },
    },
    sortDesc: {
      handler() {
        this.refreshTable();
      },
    },
  },
});
