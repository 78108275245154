
import CourseSelector from '../courses/CourseSelector.vue';
import { FilterPersistence } from '../../api/filter/filter_persistence';
import { TeeTimeAvailabilityIndex, TeeTimeAvailabilityIndexOptions } from '../../api/tee_time_availability/index';
import { formatDate, parseDate } from '../../api/dates/date_formatter';
import {
  TeeTimeAvailabilitySummary,
  TeeTimeAvailabilityFilter,
  DefaultTeeTimeAvailabilitySummary,
} from '../../api/tee_time_availability/tee_time_availability_summary';
import Vue from 'vue';

Vue.component('course-selector', CourseSelector);

export default Vue.component('fore-up-availability-index', {
  data() {
    return {
      courseNameFilter: 'Torrey',
      courseGroupFilter: '',
      rateFilter: '',
      sortingDateFilter: '',
      timeFilter: '',
      slotsFilter: '',
      searchDate: '',
      searchDateFormatted: '',
      pageNo: 1 as number,
      perPage: 100 as number,
      availabilityCount: 0 as number,
      options: {} as TeeTimeAvailabilityIndexOptions,
      sortDesc: [false] as boolean[],
      sortBy: ['sortingDate', 'time'] as string[],
      fieldFilters: {} as TeeTimeAvailabilityFilter,
      loading: true,
      loadingText: 'Loading Data...',
      headers: [
        { text: 'Course name', value: 'courseName' },
        { text: 'Last scrape', value: 'scrapedAt' },
        { text: 'Rate', value: 'rate' },
        { text: 'Group', value: 'group_name' },
        { text: 'Date', value: 'readDate' },
        { text: 'Sorting date', value: 'sortingDate' },
        { text: 'Time', value: 'time' },
        { text: 'Book', value: 'book' },
      ],
      summary: DefaultTeeTimeAvailabilitySummary() as TeeTimeAvailabilitySummary,
    };
  },
  methods: {
    maybeUpdateDateAndSearch(event: KeyboardEvent) {
      if (event.key !== 'Enter') {
        return;
      }
      this.updateDateAndSearch(this.sortingDateFilter);
    },
    updateDateAndSearch(date: string) {
      this.sortingDateFilter = date;
      this.updateFilters();
      this.refreshTable();
    },
    parseDateInternal(date: string): string {
      return parseDate(date);
    },
    itemRowBackground(item: any): string {
      if (item.courseId === 17) {
        return 'fore-row-gray';
      }
      return 'fore-row';
    },
    maybeSearch(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        this.updateFilters();
        FilterPersistence.saveFieldFilterState(
          this.$router,
          'tee-time-availability',
          this.fieldFilters,
        );
        this.refreshTable();
      }
    },
    updateFilters() {
      // TODO - Convert this to an interface so it's easier to read elsewhere.
      this.fieldFilters = {
        courseName: this.courseNameFilter,
        courseGroup: this.courseGroupFilter,
        rate: this.rateFilter,
        sortingDate: this.sortingDateFilter,
        time: this.timeFilter,
        slots: this.slotsFilter,
      };
    },
    refreshTable() {
      this.loading = true;
      this.options.sortDesc = this.sortDesc;
      this.options.sortBy = this.sortBy;
      this.options.page = this.pageNo;
      this.options.itemsPerPage = this.perPage;
      this.options.filters = this.fieldFilters;

      TeeTimeAvailabilityIndex.getCount(this.options).then((response) => {
        this.availabilityCount = response.data;
      });

      TeeTimeAvailabilityIndex.getData(this.options).then((response) => {
        if (!response.data) {
          return;
        }
        this.summary = TeeTimeAvailabilityIndex.parseForeResponse(
          response.data,
        );
        this.loading = false;
      });
    },
    loadFilters() {
      const filter = FilterPersistence.getFieldFiltersFromUrl(this.$route);
      if (!filter) {
        return;
      }
      this.fieldFilters = filter;

      this.courseNameFilter = this.fieldFilters.courseName;
      this.courseGroupFilter = this.fieldFilters.courseGroup;
      this.rateFilter = this.fieldFilters.rate;
      this.courseNameFilter = this.fieldFilters.courseName;
      this.sortingDateFilter = this.fieldFilters.sortingDate;
      this.timeFilter = this.fieldFilters.time;
      this.slotsFilter = this.fieldFilters.slots;
    },
  },
  mounted() {
    this.updateFilters();
    this.loadFilters();
    this.refreshTable();
  },
  watch: {
    searchDate: {
      handler() {
        this.searchDateFormatted = formatDate(this.searchDate);
      },
    },
    pageNo: {
      handler() {
        this.refreshTable();
      },
    },
    perPage: {
      handler() {
        this.refreshTable();
      },
    },
    sortDesc: {
      handler() {
        this.refreshTable();
      },
    },
  },
});
