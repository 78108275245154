
import HolidayShow from '../components/holidays/HolidayShow.vue';
import RateIndex from '../components/rates/RateIndex.vue';
import Vue from 'vue';

Vue.component('holiday-show', HolidayShow);

Vue.component('rate-index', RateIndex);

export default Vue.extend({});
