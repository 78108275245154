
import { FilterPersistence } from '../../api/filter/filter_persistence';
import {
  RentalClubIndex,
  RentalClubIndexFilters,
  RentalClubIndexOptions,
} from '../../api/rental_clubs/rental_club_index';
import { RentalClub } from '../../api/rental_clubs/rental_club';

import Vue from 'vue';

export default Vue.component('rental-club-index', {
  props: ['courseId'],
  data() {
    return {
      fieldFilters: {} as RentalClubIndexFilters,
      costFilter: '',
      rateFilter: '',
      courseFilter: '',
      chargeFilter: '',
      brandFilter: '',
      descriptionFilter: '',
      handedFilter: '',
      sexFilter: '',
      headers: [
        { text: 'Cost', value: 'cost' },
        { text: 'Rate', value: 'rate' },
        { text: 'Charge', value: 'charge' },
        { text: 'Brand', value: 'brand' },
        { text: 'Description', value: 'description' },
        { text: 'Handed', value: 'handed' },
        { text: 'Sex', value: 'sex' },
        { text: 'Course', value: 'course.course_name' },
        { text: 'View', value: 'view' },
      ],
      loading: true,
      loadingText: 'Loading Data...',
      rentalClubCount: -1,
      rentalClubs: [] as RentalClub[],
      options: {} as RentalClubIndexOptions,
      perPage: RentalClubIndex.PER_PAGE,
      pageNo: 1 as number,
      showFilters: true as boolean,
      sortDesc: [] as boolean[],
      sortBy: [] as string[],
    };
  },
  mounted() {
    this.loadFilters();
    // We call this after loadFilters since some field filters are provided as props.
    this.updateFilters();
    this.refreshTable();
  },
  methods: {
    refreshTable() {
      if (this.courseId) {
        this.showFilters = false;
      }
      this.options.sortDesc = this.sortDesc;
      this.options.sortBy = this.sortBy;
      this.options.page = this.pageNo;
      this.options.itemsPerPage = this.perPage;
      this.options.filters = this.fieldFilters;
      RentalClubIndex.getData(this.options).then((response) => {
        this.rentalClubs = RentalClubIndex.parseResponse(response.data);
        this.loading = false;
      });
      RentalClubIndex.getCount(this.fieldFilters).then((response) => {
        this.rentalClubCount = response.data;
      });
    },
    maybeSearch(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        this.updateFilters();
        FilterPersistence.saveFieldFilterState(
          this.$router,
          'manage-rental-clubs',
          this.fieldFilters,
        );
        this.search();
      }
    },
    search() {
      this.pageNo = 1;
      this.refreshTable();
    },
    loadFilters() {
      const filter = FilterPersistence.getFieldFiltersFromUrl(this.$route);
      if (!filter) {
        return;
      }
      this.fieldFilters = filter;
      this.costFilter = this.fieldFilters.cost;
      this.rateFilter = this.fieldFilters.rate;
      this.chargeFilter = this.fieldFilters.charge;
      this.brandFilter = this.fieldFilters.brand;
      this.descriptionFilter = this.fieldFilters.description;
      this.courseFilter = this.fieldFilters.course_name;
      this.handedFilter = this.fieldFilters.handed;
      this.sexFilter = this.fieldFilters.sex;
    },
    updateFilters() {
      this.fieldFilters = {
        cost: this.costFilter,
        rate: this.rateFilter,
        charge: this.chargeFilter,
        brand: this.brandFilter,
        description: this.descriptionFilter,
        course_id: this.courseId,
        course_name: this.courseFilter,
        handed: this.handedFilter,
        sex: this.sexFilter,
      };
    },
  },
  watch: {
    pageNo: {
      handler() {
        this.refreshTable();
      },
    },
    perPage: {
      handler() {
        this.refreshTable();
      },
    },
    sortDesc: {
      handler() {
        this.refreshTable();
      },
    },
  },
});
