export interface HelpfulLink {
  id: number;
  url: string;
  type: string;
  description: string;
  order: number;
}

const DEFAULT_HELPFUL_LINK: HelpfulLink = {
  id: -1,
  url: '',
  type: 'Helpful',
  description: '',
  order: 0,
};

export function DefaultHelpfulLink(): HelpfulLink {
  return JSON.parse(JSON.stringify(DEFAULT_HELPFUL_LINK));
}
