export interface TransactionItem {
  created_at: string;
  total: string;
  transaction_itemable_type: string;
  transaction_itemable_id: number;
}

const DEFAULT_TRANSACTION_ITEM: TransactionItem = {
  created_at: '',
  total: '',
  transaction_itemable_type: '',
  transaction_itemable_id: 0,
};

export function DefaultTransactionItem(): TransactionItem {
  return JSON.parse(JSON.stringify(DEFAULT_TRANSACTION_ITEM));
}
