import axios from 'axios';
import { Customer } from './customer';

export class CustomerShow {
  public static async getData(id: number): Promise<any> {
    return axios.get(`/api/customers/show/${id}`);
  }

  public static async saveData(customerData: Customer): Promise<any> {
    if (customerData.id === 0) {
      return axios.post('/api/customers', {
        customer: customerData,
      });
    }
    return axios.put('/api/customers/update', {
      id: customerData.id,
      customer: customerData,
    });
  }

  public static parseResponse(response: any): Customer {
    const customer = {} as Customer;
    customer.id = response.data.id;
    customer.type = response.data.type;
    customer.first_name = response.data.first_name;
    customer.last_name = response.data.last_name;
    customer.email = response.data.email;
    customer.address = response.data.address;
    customer.address_2 = response.data.address_2;
    customer.address_3 = response.data.address_3;
    customer.postcode = response.data.postcode;
    customer.city = response.data.city;
    customer.subdivision = response.data.subdivision;
    customer.country_alpha2 = response.data.country_alpha2;
    customer.mobile_phone = response.data.mobile_phone;
    customer.primary_phone = response.data.primary_phone;
    customer.legacy_address = response.data.legacy_address;
    return customer;
  }
}
