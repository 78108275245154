
import CountrySelector from '../countries/CountrySelector.vue';
import DefaultButtons from '../forms/DefaultButtons.vue';
import FormAlerts from '../alerts/FormAlerts.vue';
import { FormProcessor } from '../../api/forms/processor';
import { Customer, DefaultCustomer, CUSTOMER_TYPES} from '../../api/customers/customer';
import { CustomerShow } from '../../api/customers/show';
import Vue from 'vue';

Vue.component('country-selector', CountrySelector);
Vue.component('default-buttons', DefaultButtons);
Vue.component('form-alerts', FormAlerts);

export default Vue.component('customer-show', {
  data() {
    return {
      id: -1 as number,
      customerData: {} as Customer,
      customerTypes: CUSTOMER_TYPES,
      isNew: false,
      processor: new FormProcessor(),
      showDuplicateConfirmation: false,
    };
  },
  methods: {
    handleCountryUpdate(country: string) {
      this.customerData.country_alpha2 = country;
      this.processor.setModified();
    },
    loadForm() {
      if (this.id <= 0) {
        return;
      }
      CustomerShow.getData(this.id).then((response) => {
        this.customerData = CustomerShow.parseResponse(response);
      });
    },
    resetForm() {
      this.customerData = DefaultCustomer();
      if (!Number(this.$route.params.id)) {
        this.isNew = true;
        this.id = -1;
      } else {
        this.id = Number(this.$route.params.id);
      }
      this.processor.setParams(
        this.id,
        this.customerData,
        DefaultCustomer(),
        this.updateCallback,
        this.saveCallback,
      );
      this.loadForm();
      if (this.isNew) {
        this.processor.enableEdit();
      }
    },
    saveForm() {
      if (this.processor === null || !this.processor.shouldSave()) {
        return;
      }
      this.processor.saveData(
        CustomerShow,
        this.customerData,
        this.$refs.submitForm,
      );
    },
    undoForm() {
      if (this.processor === null || !this.processor.undoForm()) {
        return;
      }
      this.loadForm();
    },
    saveCallback(data: any) {
      if (data.duplicate) {
        this.showDuplicateConfirmation = true;
        this.processor.clearAlerts();
        return;
      }
      if (this.isNew && data.id) {
        this.customerData.id = data.id;
        this.isNew = false;
        this.showDuplicateConfirmation = false;
      }
      this.loadForm();
    },
    updateCallback(formData: Customer) {
      this.customerData = formData;
    },
  },
  mounted() {
    this.resetForm();
  },
  watch: {
    $route() {
      this.resetForm();
    },
  },
});
