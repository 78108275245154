
import { TeeTimeCancel } from '../../api/tee_times/cancel';
import Vue from 'vue';

export default Vue.component('tee-time-cancel', {
  props: ['id'],
  data() {
    return {
      confirmation: '',
      customRefundAmount: 0,
      error: false,
      errorMessage: '',
      forceCancel: false,
      isCanceled: false,
      isInternal: false,
      isProcessing: false,
      loading: true,
      shouldRefund: true,
      shouldCancel: true,
      successMessage: '',
      refundMode: 'standard',
      refundTotal: 0,
      transactionTotal: 0,
    };
  },
  mounted() {
    this.loadForm();
  },
  methods: {
    loadData() {
      this.loading = true;
      TeeTimeCancel.getCancelOptions(Number(this.id)).then((response) => {
        if (!response.data.valid) {
          this.error = true;
          return;
        }
        this.isInternal = response.data.internal;
        this.loading = false;
        this.refundTotal = response.data.refund_total;
        this.isCanceled = response.data.is_canceled;
        if (this.isCanceled) {
          this.shouldCancel = false;
        }
        this.transactionTotal = response.data.transaction_total;
      });
    },
    loadForm() {
      this.confirmation = '';
      this.loading = true;
      this.error = false;
      this.errorMessage = '';
      this.refundTotal = 0;
      this.transactionTotal = 0;
      this.isInternal = false;
      this.shouldRefund = false;
      this.shouldCancel = false;
      this.successMessage = '';
      this.loadData();
    },
    processRefund() {
      this.isProcessing = true;
      if (!this.shouldRefund && !this.shouldCancel) {
        return;
      }
      let refundTotal = 0;
      if (this.shouldRefund) {
        switch (this.refundMode) {
          case 'custom':
            refundTotal = this.customRefundAmount * 100;
            break;
          case 'full':
            refundTotal = this.transactionTotal;
            break;
          default:
            refundTotal = this.refundTotal;
            break;
        }
      }
      TeeTimeCancel.doCancel(
        this.id,
        this.shouldRefund,
        this.shouldCancel,
        this.forceCancel,
        refundTotal,
      ).then((response) => {
        this.isProcessing = false;
        if (!response.data.valid) {
          this.error = true;
          this.errorMessage = response.data.message;
          return;
        }
        this.confirmation = response.data.confirmation;
        this.successMessage = response.data.message;
      });
    },
  },
  computed: {},
  watch: {},
});
