
import { Reporting } from '../../api/reports/reporting';
import { formatDate, parseDate } from '../../api/dates/date_formatter';
import AuthToken from '../../api/auth/Auth';
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      startDate: '',
      startDateFormatted: '',
      endDate: '',
      endDateFormatted: '',
    };
  },
  methods: {
    parseDateInternal(date: string): string {
      return parseDate(date);
    },
    runReport() {
      Reporting.getNewSales(this.startDate, this.endDate);
    },
  },
  watch: {
    startDate() {
      this.startDateFormatted = formatDate(this.startDate);
    },
    endDate() {
      this.endDateFormatted = formatDate(this.endDate);
    },
  },
});
