import axios from 'axios';
import { RicUser } from './ric_user';

export class RicUserShow {
  public static async getData(id: number): Promise<any> {
    return axios.get(`/api/ric_users/show/${id}`);
  }

  public static async deleteData(id: number): Promise<any> {
    return axios.delete(`/api/ric_users/${id}`);
  }

  public static async saveData(ricUserData: RicUser): Promise<any> {
    const dupRicUserData = JSON.parse(JSON.stringify(ricUserData));

    delete dupRicUserData.groups;

    if (ricUserData.id === -1) {
      return axios.post('/api/ric_users', {
        ric_user: dupRicUserData,
        groups: ricUserData.groups,
      });
    }
    return axios.put('/api/ric_users/update', {
      ric_user: dupRicUserData,
      groups: ricUserData.groups,
    });
  }

  public static async allGroups(): Promise<any> {
    return axios.get('/api/ric_users/all_groups');
  }

  public static parseResponse(response: any): RicUser {
    const ricUser = {} as RicUser;
    ricUser.id = response.data.id;
    ricUser.email = response.data.email;
    ricUser.name = response.data.name;
    ricUser.groups = response.data.admin_user_groups.map((group: any) => group.level);
    return ricUser;
  }
}
