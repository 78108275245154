import axios from 'axios';
import { HelpfulLink } from './HelpfulLink';

export interface HelpfulLinkIndexFilters {
  url?: string;
  description?: string;
  type?: string;
}

export interface HelpfulLinkIndexOptions {
  sortBy?: string[];
  sortDesc?: boolean[];
  filters?: HelpfulLinkIndexFilters;
}

export class HelpfulLinkIndex {
  public static moveUp(order: number) {
    return axios.get(`/api/helpful_links/move_up/${order}`);
  }

  public static moveDown(order: number) {
    return axios.get(`/api/helpful_links/move_down/${order}`);
  }

  public static async getCount(): Promise<any> {
    return axios.get('/api/helpful_links/count');
  }

  public static async getData(
    helpfulLinkIndexOptions?: HelpfulLinkIndexOptions,
  ): Promise<any> {
    if (helpfulLinkIndexOptions == null) {
      return axios.get('/api/helpful_links/index');
    }

    return axios.get('/api/helpful_links/index', {
      params: {
        sort_fields: helpfulLinkIndexOptions.sortBy,
        sort_desc: helpfulLinkIndexOptions.sortDesc,
        filters: helpfulLinkIndexOptions.filters,
      },
    });
  }

  public static async updateType(id: number, type: string) {
    return axios.put('/api/helpful_links/update_type', {
      id,
      type,
    });
  }

  public static parseResponse(response: any): HelpfulLink[] {
    const helpfulLinkData = [] as HelpfulLink[];
    for (const k in response) {
      if (!response[k]) {
        continue;
      }
      const item = response[k];
      const helpfulLink = {} as HelpfulLink;
      helpfulLink.id = item.id;
      helpfulLink.url = item.url;
      helpfulLink.type = item.type;
      helpfulLink.description = item.description;
      helpfulLink.order = item.order;
      helpfulLinkData.push(helpfulLink);
    }
    return helpfulLinkData;
  }
}
