export interface GolfswitchCourse {
  id: number;
  gs_id: string;
  gs_xid: string;
}

const DEFAULT_GOLFSWITCH_COURSE: GolfswitchCourse = {
  id: 0,
  gs_id: '',
  gs_xid: '',
};

export function DefaultGolfswitchCourse(): GolfswitchCourse {
  return JSON.parse(JSON.stringify(DEFAULT_GOLFSWITCH_COURSE));
}
