import axios from 'axios';
import { CourseGroup } from './course_group';
import { CourseIndex } from '../courses/index';
import {ApiList} from '../api/list';

export class CourseGroupShow {
  public static async getData(id: number): Promise<any> {
    return axios.get(`/api/course_groups/show/${id}`);
  }

  public static async deleteData(id: number): Promise<any> {
    return axios.delete(`/api/course_groups/${id}`);
  }

  public static async saveData(courseGroupData: CourseGroup): Promise<any> {
    if (courseGroupData.id === -1) {
      return axios.post('/api/course_groups', {
        course_group: courseGroupData,
      });
    }

    return axios.put('/api/course_groups/update', {
      id: courseGroupData.id,
      course_group: courseGroupData,
    });
  }

  public static parseResponse(response: any): CourseGroup {
    const courseGroup = {} as CourseGroup;
    courseGroup.id = response.data.id;
    courseGroup.group_name = response.data.group_name;
    courseGroup.courses = CourseIndex.parseResponse(response.data.courses);
    courseGroup.actions = ApiList.parseActionItemResponse(response.data.api_actions);
    courseGroup.users = ApiList.parseActionUserResponse(response.data.api_users);
    return courseGroup;
  }
}
