import axios from 'axios';
import { CourseGroup } from './course_group';
import { Course} from '../courses/course';
import { CourseIndex } from '../courses/index';

export interface CourseGroupIndexFilters {
  group_name: string;
  course_id: number;
  course_name: string;
}

export interface CourseGroupIndexOptions {
  sortBy: string[];
  sortDesc: boolean[];
  filters: CourseGroupIndexFilters;
}

export class CourseGroupIndex {
  public static async getCount(
    courseGroupIndexOptions?: CourseGroupIndexOptions,
  ): Promise<any> {
    return axios.get('/api/course_groups/count', {
      params: {
        filters: courseGroupIndexOptions?.filters,
      },
    });
  }

  public static async getData(
    courseGroupIndexOptions?: CourseGroupIndexOptions,
  ): Promise<any> {
    if (courseGroupIndexOptions == null) {
      return axios.get('/api/course_groups/index');
    }

    return axios.get('/api/course_groups/index', {
      params: {
        sort_fields: courseGroupIndexOptions.sortBy,
        sort_desc: courseGroupIndexOptions.sortDesc,
        filters: courseGroupIndexOptions.filters,
      },
    });
  }

  public static parseResponse(response: any): CourseGroup[] {
    const courseGroupData = [] as CourseGroup[];
    for (const k in response) {
      if (!response[k]) {
        continue;
      }
      const item = response[k];
      const courseGroup = {} as CourseGroup;
      courseGroup.id = item.id;
      courseGroup.group_name = item.group_name;
      courseGroup.courses = CourseIndex.parseResponse(item.courses);
      const courseNames = [];
      for (const course of courseGroup.courses) {
        courseNames.push(course.course_name);
      }
      courseGroup.course_names = courseNames.join(', ');
      courseGroupData.push(courseGroup);
    }
    return courseGroupData;
  }
}
