export interface Customer {
  id: number;
  type: string;
  first_name: string;
  last_name: string;
  address: string;
  address_2: string;
  address_3: string;
  postcode: string;
  city: string;
  subdivision: string;
  country_alpha2: string;
  primary_phone: string;
  mobile_phone: string;
  email: string;
  legacy_address: string;
  ignore_duplicate: boolean;
}

export const CUSTOMER_TYPES: string[] = [
  'Customer',
  'GiftCertificateRecipient',
];

const DEFAULT_CUSTOMER: Customer = {
  id: 0,
  type: '',
  first_name: '',
  last_name: '',
  address: '',
  address_2: '',
  address_3: '',
  postcode: '',
  city: '',
  subdivision: '',
  country_alpha2: '',
  primary_phone: '',
  mobile_phone: '',
  email: '',
  legacy_address: '',
  ignore_duplicate: false,
};

export function DefaultCustomer(): Customer {
  return JSON.parse(JSON.stringify(DEFAULT_CUSTOMER));
}
