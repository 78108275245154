
import {
  GiftCertificateSummary,
  GiftCertificateSummaryData,
} from '../../api/gift_certificates/summary';
import Vue from 'vue';

export default Vue.component('gift-certificate-summary', {
  props: ['isMonthly', 'title', 'date'],
  data() {
    return {
      revenue: '',
      data: {} as GiftCertificateSummaryData,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      if (!this.date) {
        return;
      }
      GiftCertificateSummary.getData(this.isMonthly, this.date).then((response) => {
        this.data = response;
        setTimeout(() => {
          this.load();
        }, 500000); // Five minutes.
      });
    },
    getLink() {
      return `/manage-gift-certificates?monthly=${this.isMonthly}`;
    },
  },
});
