
import { Course } from '../../api/courses/course';
import { FilterPersistence } from '../../api/filter/filter_persistence';
import {
  CourseGroupIndex,
  CourseGroupIndexFilters,
  CourseGroupIndexOptions,
} from '../../api/course_groups/index';
import { CourseGroup } from '../../api/course_groups/course_group';
import Vue from 'vue';

export default Vue.component('course-group-index', {
  props: ['courseId'],
  data() {
    return {
      courseNameFilter: '',
      groupNameFilter: '',
      showFilters: true,
      loading: true,
      loadingText: 'Loading Data...',
      headers: [
        { text: 'Group Name', value: 'group_name' },
        { text: 'View', value: 'view' },
      ],
      fieldFilters: {} as CourseGroupIndexFilters,
      options: {} as CourseGroupIndexOptions,
      courseGroupData: [] as CourseGroup[],
      courseGroupCount: 0 as number,
      pageNo: 1,
      sortDesc: [] as boolean[],
      sortBy: ['course_name'] as string[],
    };
  },
  mounted() {
    this.loadFilters();
    // We call this after loadFilters since some field filters are provided as props.
    this.updateFilters();
    this.refreshTable();
  },
  methods: {
    refreshTable() {
      if (this.courseId) {
        this.showFilters = false;
      }
      this.options.sortDesc = this.sortDesc;
      this.options.sortBy = this.sortBy;
      this.options.filters = this.fieldFilters;
      CourseGroupIndex.getData(this.options).then((response) => {
        this.courseGroupData = CourseGroupIndex.parseResponse(response.data);
        this.loading = false;
      });
      CourseGroupIndex.getCount(this.options).then((response) => {
        this.courseGroupCount = response.data;
      });
    },
    search() {
      // A change in the search resets the page.
      this.pageNo = 1;
      this.refreshTable();
    },
    maybeSearch(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        this.updateFilters();
        FilterPersistence.saveFieldFilterState(
          this.$router,
          'manage-course-groups',
          this.fieldFilters,
        );
        this.search();
      }
    },
    loadFilters() {
      const filter = FilterPersistence.getFieldFiltersFromUrl(this.$route);
      if (!filter) {
        return;
      }
      this.fieldFilters = filter;

      this.courseNameFilter = this.fieldFilters.course_name;
      this.groupNameFilter = this.fieldFilters.group_name;
    },
    updateFilters() {
      this.fieldFilters = {
        course_name: this.courseNameFilter,
        group_name: this.groupNameFilter,
        course_id: this.courseId,
      };
    },
  },
  computed: {
    course_groups(): CourseGroup[] {
      return this.courseGroupData;
    },
  },
  watch: {
    pageNo: {
      handler() {
        this.refreshTable();
      },
    },
    perPage: {
      handler() {
        this.refreshTable();
      },
    },
    sortDesc: {
      handler() {
        this.refreshTable();
      },
    },
  },
});
