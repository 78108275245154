import { FormErrorHandler } from './error_handler';

export class FormProcessor {
  public errorHandler: FormErrorHandler;
  public formData: any;
  public readOnly: boolean = true;

  private id: number = 0;
  private isSaved: boolean = false;
  private modified: boolean = false;
  private defaultFormData: any;
  // Delete callback is called when the form is successfully deleted.
  private deleteCallback?: () => void;
  // Update callback is called when the form is cleared.
  // TODO: Rename it.
  private updateCallback?: (data: any) => void;
  // Save callback is called when the form is successfully saved.
  private saveCallback?: (data: any) => void;

  constructor() {
    this.errorHandler = new FormErrorHandler();
  }

  public setParams(
    id: number,
    formData: any,
    defaultFormData: any,
    updateCallback?: (data: any) => void,
    saveCallback?: (data: any) => void,
    deleteCallback?: () => void,
  ) {
    this.id = id;
    this.formData = formData;
    this.defaultFormData = defaultFormData;
    this.deleteCallback = deleteCallback;
    this.updateCallback = updateCallback;
    this.saveCallback = saveCallback;
  }

  // Clears any alerts shown after processing a form.
  public clearAlerts() {
    this.errorHandler.clearErrors();
    this.isSaved = false;
  }

  // Clears all data in the form, and passes the empty data to the caller.
  // This way the prop gets updated and the form is refreshed with empty data.
  public clearForm() {
    if (!confirm('Clear?')) {
      return;
    }
    this.formData = JSON.parse(JSON.stringify(this.defaultFormData));
    this.formData.id = this.id;
    this.modified = true;
    this.maybeUpdateCallerData();
  }

  // Rebuilds the ruleMap used for individual field validation.
  public clearRules() {
    this.errorHandler.buildRuleMap();
  }

  // Unlocks the form. The form listens to this value.
  public enableEdit() {
    this.readOnly = false;
  }

  // Locks the form.
  public lockEdit() {
    this.readOnly = true;
  }

  // Resets the form by clearing any errors and field rules, locking it, and
  // resetting its state to not modified.
  public resetForm(shouldLock: boolean = false) {
    if (shouldLock) {
      this.lockEdit();
    }
    this.errorHandler.hasError = false;
    this.errorHandler.hasFatalError = false;
    this.errorHandler.fatalErrorText = '';
    this.modified = false;
    this.clearRules();
  }

  // Updated when a field is changed so we know to attempt a save.
  public setModified() {
    this.modified = true;
  }

  public isModified(): boolean {
    if (this.modified) {
      return true;
    }
    return false;
  }

  // Whether or not we should attempt to delete.
  public shouldDelete(): boolean {
    if (!confirm('Delete?')) {
      return false;
    }
    return true;
  }

  // Whether or not we should attempt to save the form.
  public shouldSave(): boolean {
    if (!this.modified) {
      return false;
    }
    this.clearRules();
    return true;
  }

  // Tries to save the form values, and handles errors if it fails.
  public deleteData(dataProcessor: any, id: number) {
    dataProcessor
      .deleteData(id)
      .then((response: any) => {
        if (!response || response.status !== 200) {
          this.handleErrors(response);
          return;
        }
        this.handleDelete();
      })
      .catch((error: any) => {
        this.handleErrors(error);
      });
  }

  // Tries to save the form values, and handles errors if it fails.
  public saveData(
    dataProcessor: any,
    formData: any,
    form: any,
    saveDataFn?: (formData: any) => Promise<any>,
  ) {
    this.isSaved = false;
    const saveData =
      saveDataFn === undefined ? dataProcessor.saveData : saveDataFn;
    saveData(formData)
      .then((response: any) => {
        if (!response || response.status !== 200) {
          this.handleErrors(response);
          // Forces the rules to run and show.
          form.validate();
          return;
        }
        this.isSaved = true;
        this.handleFormSave(response.data);
      })
      .catch((error: any) => {
        this.handleErrors(error);
        // Forces the rules to run and show.
        form.validate();
      });
  }

  // If a form is saved, we want to ensure no more old errors are shown.
  public handleFormSave(responseData: any) {
    this.resetForm(/* lockEdit = */ true);
    if (this.saveCallback) {
      this.saveCallback(responseData);
    }
  }

  public handleDelete() {
    if (this.deleteCallback) {
      this.deleteCallback();
    }
  }

  // Displayed fatal and non fatal errors via the errorHandler.
  public handleErrors(response: any) {
    this.resetForm();
    this.errorHandler.handleErrors(response);
  }

  // Confirms that the user wants to reload the form data, and
  // resets the form before the caller reloads.
  public undoForm(): boolean {
    if (!confirm('Reload?')) {
      return false;
    }
    this.resetForm(/* lockEdit= */ true);
    return true;
  }

  // If a callback is provided, pass the updated form data
  // back to the caller so the form will update.
  private maybeUpdateCallerData() {
    if (this.updateCallback) {
      this.updateCallback(this.formData);
    }
  }
}
