
import { ApiAction, ApiActionItem } from '../../api/api/api_action';
import { ApiList } from '../../api/api/list';
import Vue from 'vue';

export default Vue.component('course-check-boxes', {
  props: ['preExpand', 'readOnly', 'preselectedActions', 'heading'],
  data() {
    return {
      actionList: [] as ApiActionItem[],
      expanded: this.preExpand ? [0] : [],
    };
  },
  methods: {
    selectAll() {
      if (this.readOnly) {
        return;
      }
      this.actionList.forEach((action: ApiActionItem) => {
        action.selected = true;
      });
      this.updateValue();
    },
    unselectAll() {
      if (this.readOnly) {
        return;
      }
      this.actionList.forEach((action: ApiActionItem) => {
        action.selected = false;
      });
      this.updateValue();
    },
    updateValue() {
      this.$emit('update:selectedApiActions', this.actionList);
    },
    updateFromPreselected() {
      if (!Array.isArray(this.preselectedActions)) {
        return;
      }
      const actionIds = this.preselectedActions.map((action) => {
        return action.action.id;
      });
      this.actionList.forEach((action: ApiActionItem) => {
        if (actionIds.indexOf(action.action.id) > -1) {
          action.selected = true;
        }
      });
    },
  },
  mounted() {
    ApiList.getActionList().then((response) => {
      this.actionList = ApiList.parseActionItemResponse(response.data);
      if (
        Array.isArray(this.preselectedActions) &&
        this.preselectedActions.length >= 0
      ) {
        this.updateFromPreselected();
      } else {
        this.selectAll();
      }
    });
  },
  watch: {
    preselectedActions: {
      handler() {
        this.updateFromPreselected();
      },
    },
  },
});
