import { AxiosResponse } from 'axios';
import { AuthHeader } from './AuthHeader';
import axios from 'axios';

const AUTH_TOKEN = 'authToken';
export const AUTH_NAME = 'authName';
export const AUTH_LEVEL = 'level';

export default class Auth {
  public static hasToken(): boolean {
    return localStorage.getItem(AUTH_TOKEN) !== null;
  }

  public static isAdmin(): boolean {
    if (this.isAdminBit === undefined) {
      this.isAdminBit = localStorage.getItem(AUTH_LEVEL) === 'admin';
    }
    return this.isAdminBit;
  }

  // TODO: Define an interface type for the auth header object.
  public static getAuthHeader(): any | null {
    const authObject = localStorage.getItem(AUTH_TOKEN);
    if (authObject === null) {
      return null;
    }
    return JSON.parse(authObject);
  }

  public static async getToken(
    email: string,
    password: string,
  ): Promise<AuthHeader | null> {
    const host = window.location.host;
    let url = `${host == 'localhost' ? 'http' : 'https'}://${host}/api/auth/sign_in`;
    return await axios
      .post(url, {
        email,
        password,
      })
      .then((response) => {
        if (response.status === 200) {
          return this.pruneAuthHeader(response.headers);
        }
        return null;
      })
      .catch((error) => {
        return null;
      });
  }

  public static async level(email: string) {
    const response = await axios.post('/api/ric_users/level', { email });
    localStorage.setItem(AUTH_LEVEL, response.data.level);
  }

  public static pruneAuthHeader(inAuthHeader: AuthHeader): AuthHeader {
    const authHeader = {} as AuthHeader;
    authHeader['access-token'] = inAuthHeader['access-token'];
    authHeader.uid = inAuthHeader.uid;
    authHeader.client = inAuthHeader.client;
    authHeader.expiry = inAuthHeader.expiry;
    authHeader['token-type'] = inAuthHeader['token-type'];
    return authHeader;
  }

  public static clearAuthHeader() {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(AUTH_LEVEL);
    localStorage.removeItem(AUTH_NAME);
  }

  public static setAuthHeader(authHeader: AuthHeader, userName: string) {
    localStorage.setItem(AUTH_TOKEN, JSON.stringify(authHeader));
    localStorage.setItem(AUTH_NAME, userName);
  }

  private static isAdminBit?: boolean;
}
