
import { FilterPersistence } from '../../api/filter/filter_persistence';
import {
  HelpfulLinkIndex,
  HelpfulLinkIndexFilters,
  HelpfulLinkIndexOptions,
} from '../../api/helpful_links/index';
import Vue from 'vue';
import { HelpfulLink } from '../../api/helpful_links/HelpfulLink';

export default Vue.component('helpful-link-index', {
  data() {
    return {
      urlFilter: '',
      descriptionFilter: '',
      linkTypes: ['Helpful', 'Hotel', 'PSGR'],
      link_type: 'Helpful',
      loading: true,
      loadingText: 'Loading Data...',
      headers: [
        { text: 'Description', value: 'description' },
        { text: 'Order', value: 'order' },
        { text: 'Type', value: 'type', width: '118px' },
        { text: 'View', value: 'view' },
        { text: 'URL', value: 'url' },
      ],
      fieldFilters: {} as HelpfulLinkIndexFilters,
      options: {} as HelpfulLinkIndexOptions,
      helpfulLinkData: [] as HelpfulLink[],
      helpfulLinkCount: 0 as number,
      pageNo: 1,
      sortDesc: [false] as boolean[],
      sortBy: ['order'] as string[],
    };
  },
  mounted() {
    this.loadFilters();
    this.refreshTable();
    HelpfulLinkIndex.getCount().then((response) => {
      this.helpfulLinkCount = response.data;
      this.loading = false;
    });
  },
  methods: {
    updateLink(id: number, type: string) {
      HelpfulLinkIndex.updateType(id, type).then(() => {
        this.refreshTable();
      });
    },
    updateLinkFilter() {
      this.refreshTable();
    },
    moveUp(order: number) {
      HelpfulLinkIndex.moveUp(order).then(() => {
        this.refreshTable();
      });
    },
    moveDown(order: number) {
      HelpfulLinkIndex.moveDown(order).then(() => {
        this.refreshTable();
      });
    },
    refreshTable() {
      this.options.sortDesc = this.sortDesc;
      this.options.sortBy = this.sortBy;
      this.options.filters = this.fieldFilters;
      HelpfulLinkIndex.getData(this.options).then((response) => {
        this.helpfulLinkData = HelpfulLinkIndex.parseResponse(response.data);
      });
    },
    search() {
      this.pageNo = 1;
      this.refreshTable();
    },
    maybeSearch(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        this.updateFilters();
        FilterPersistence.saveFieldFilterState(
          this.$router,
          'helpful-links',
          this.fieldFilters,
        );
        this.search();
      }
    },
    loadFilters() {
      const filter = FilterPersistence.getFieldFiltersFromUrl(this.$route);
      if (!filter) {
        return;
      }
      this.fieldFilters = filter;

      this.urlFilter = this.fieldFilters.url ? this.fieldFilters.url : '';
      this.descriptionFilter = this.fieldFilters.description
        ? this.fieldFilters.description
        : '';
      this.link_type = this.fieldFilters.type ? this.fieldFilters.type : '';
    },
    updateFilters() {
      this.fieldFilters = {
        url: this.urlFilter,
        description: this.descriptionFilter,
        type: this.link_type,
      };
    },
  },
  computed: {
    helpfulLinks(): HelpfulLink[] {
      return this.helpfulLinkData;
    },
  },
  watch: {
    sortDesc: {
      handler() {
        this.refreshTable();
      },
    },
  },
});
