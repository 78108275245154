import { Course, DefaultCourse } from '../courses/course';

export interface Discount {
  id: number;
  code: string;
  start_date: string;
  end_date: string;
  is_dollars: boolean;
  is_dollars_display: string;
  amount: number;
  raw_amount: number;
  min_discount: number;
  max_discount: number;
  gift_certificate_stack: boolean;
  enabled: boolean;
  min_players: number;
  max_players: number;
  courses: Course[];
}

const DEFAULT_DISCOUNT: Discount = {
  id: -1,
  code: '',
  start_date: '',
  end_date: '',
  is_dollars: false,
  is_dollars_display: '',
  amount: 0,
  raw_amount: 0,
  min_discount: 0,
  max_discount: 0,
  gift_certificate_stack: false,
  enabled: false,
  min_players: 0,
  max_players: 0,
  courses: [],
};

export function DefaultDiscount(): Discount {
  return JSON.parse(JSON.stringify(DEFAULT_DISCOUNT));
}

export function ComputeDiscountAmount(
  discount: Discount,
  chargeAmount: number,
): number {
  if (chargeAmount === 0 || discount.raw_amount === 0) {
    return 0;
  }

  const amount = discount.raw_amount / 100;
  if (discount.is_dollars) {
    if (chargeAmount > amount) {
      return amount;
    }
    return chargeAmount;
  }

  // %
  return amount * chargeAmount;
}
