import { TransactionItem } from './transaction_item';

export class TransactionItemIndex {
  public static parseResponse(response: any): TransactionItem[] {
    const items: TransactionItem[] = [];
    for (const data of response) {
      items.push(data);
    }
    return items;
  }
}
