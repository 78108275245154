
import { FilterPersistence } from '../../api/filter/filter_persistence';
import {
  RicUserIndex,
  RicUserIndexFilters,
  RicUserIndexOptions,
} from '../../api/ric_users/index';
import { RicUser } from '../../api/ric_users/ric_user';

import Vue from 'vue';

export default Vue.component('ric-user-index', {
  data() {
    return {
      emailFilter: '',
      groupsFilter: '',
      loading: true,
      loadingText: 'Loading Data...',
      headers: [
        { text: 'Email', value: 'email' },
        { text: 'Groups', value: 'groups' },
        { text: 'View', value: 'view' },
      ],
      fieldFilters: {} as RicUserIndexFilters,
      options: {} as RicUserIndexOptions,
      ricUserData: [] as RicUser[],
      ricUserCount: 0 as number,
      perPage: RicUserIndex.PER_PAGE as number,
      pageNo: 1 as number,
      sortDesc: [] as boolean[],
      sortBy: [] as string[],
    };
  },
  mounted() {
    this.loadFilters();
    this.refreshTable();
  },
  methods: {
    refreshTable() {
      this.options.sortDesc = this.sortDesc;
      this.options.sortBy = this.sortBy;
      this.options.page = this.pageNo;
      this.options.itemsPerPage = this.perPage;
      this.updateFilters();
      this.options.filters = this.fieldFilters;
      RicUserIndex.getData(this.options).then((response) => {
        this.ricUserData = RicUserIndex.parseResponse(response.data);
        this.loading = false;
      });
      RicUserIndex.getCount(this.options).then((response) => {
        this.ricUserCount = response.data;
      });
    },
    maybeSearch(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        this.updateFilters();
        FilterPersistence.saveFieldFilterState(
          this.$router,
          'ric-users',
          this.fieldFilters,
        );
        this.search();
      }
    },
    search() {
      this.pageNo = 1;
      this.refreshTable();
    },
    loadFilters() {
      const filter = FilterPersistence.getFieldFiltersFromUrl(this.$route);
      if (!filter) {
        return;
      }
      this.fieldFilters = filter;

      this.emailFilter = this.fieldFilters.email;
      this.groupsFilter = this.fieldFilters.groups.join(',');
    },
    updateFilters() {
      this.fieldFilters = {
        email: this.emailFilter,
        groups: this.groupsFilter.split(','),
      };
    },
  },
  computed: {
    ricUsers(): RicUser[] {
      return this.ricUserData;
    },
  },
  watch: {
    pageNo: {
      handler() {
        this.refreshTable();
      },
    },
    perPage: {
      handler() {
        this.refreshTable();
      },
    },
    sortDesc: {
      handler() {
        this.refreshTable();
      },
    },
  },
});
