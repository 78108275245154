
import { Country, CountryList } from '../../api/countries/list';
import Vue from 'vue';

export default Vue.component('country-selector', {
  props: ['countryRules', 'selectedCountry', 'readOnly'],
  data() {
    return {
      countryList: [] as Country[],
      selectedCountryInternal: '',
    };
  },
  methods: {
    updateValue(country: string) {
      this.$emit('update:selectedCountry', country);
    },
  },
  mounted() {
    CountryList.get().then((response) => {
      this.countryList = CountryList.parseResponse(response);
    });
  },
  watch: {
    selectedCountry() {
      this.selectedCountryInternal = this.selectedCountry;
    },
  },
});
