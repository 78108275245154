
import CourseCheckBoxes from '../courses/CourseCheckBoxes.vue';
import DefaultButtons from '../forms/DefaultButtons.vue';
import FormAlerts from '../alerts/FormAlerts.vue';
import { Course } from '../../api/courses/course';
import { CourseListItem } from '../../api/courses/list';
import { FormProcessor } from '../../api/forms/processor';
import { Discount, DefaultDiscount } from '../../api/discounts/discount';
import { DiscountShow } from '../../api/discounts/show';
import { formatDate, parseDate } from '../../api/dates/date_formatter';
import Vue from 'vue';

Vue.component('course-check-boxes', CourseCheckBoxes);
Vue.component('default-buttons', DefaultButtons);
Vue.component('form-alerts', FormAlerts);

export default Vue.component('discount-show', {
  data() {
    return {
      id: -1 as number,
      isNew: false,
      discountData: {} as Discount,
      processor: new FormProcessor(),
      startDate: '',
      startDateFormatted: '',
      endDate: '',
      endDateFormatted: '',
    };
  },
  methods: {
    parseDateInternal(date: string): string {
      return parseDate(date);
    },
    handleCoursesUpdate(courseListItems: []) {
      this.discountData.courses = courseListItems
        .filter((c: CourseListItem) => c.selected === true)
        .map((c: CourseListItem) => {
          return {
            id: Number(c.value),
          } as Course;
        });

      this.processor.setModified();
    },
    loadForm() {
      if (this.id <= 0) {
        return;
      }
      DiscountShow.getData(this.id).then((response) => {
        this.discountData = DiscountShow.parseResponse(response);
        this.startDate = this.discountData.start_date;
        this.endDate = this.discountData.end_date;
      });
    },
    deleteForm() {
      if (this.processor === null || !this.processor.shouldDelete()) {
        return;
      }
      this.processor.deleteData(DiscountShow, this.id);
    },
    saveForm() {
      if (this.processor === null || !this.processor.shouldSave()) {
        return;
      }
      this.processor.saveData(
        DiscountShow,
        this.discountData,
        this.$refs.submitForm,
      );
    },
    undoForm() {
      if (this.processor === null || !this.processor.undoForm()) {
        return;
      }
      this.loadForm();
    },
    deleteCallback() {
      this.$router.replace({ name: 'manage-discounts' });
    },
    saveCallback(data: any) {
      // Reload the form on save so the serializers can
      // format any $/% data properly.
      if (this.isNew && data.id) {
        this.id = data.id;
        this.discountData.id = data.id;
        this.isNew = false;
      }
      this.loadForm();
      this.$emit('update:discountSaved');
    },
    updateCallback(formData: Discount) {
      this.discountData = formData;
      this.$emit('update:discountSaved');
    },
    handleNew() {
      this.id = 0;
      this.isNew = true;
      this.discountData = DefaultDiscount();
      this.processor.setParams(
        this.id,
        this.discountData,
        DefaultDiscount(),
        this.updateCallback,
        this.saveCallback,
      );
      this.processor.readOnly = false;
    },
    resetForm() {
      const id = this.$route.params.id;
      if (id === 'new') {
        this.handleNew();
        return;
      }
      this.id = Number(id);
      if (this.id <= 0) {
        return;
      }
      this.processor.setParams(
        this.id,
        this.discountData,
        DefaultDiscount(),
        this.updateCallback,
        this.saveCallback,
        this.deleteCallback,
      );
      this.loadForm();
    },
  },
  mounted() {
    this.resetForm();
  },
  watch: {
    endDate: {
      handler() {
        this.endDateFormatted = formatDate(this.endDate);
        this.discountData.end_date = this.endDate;
      },
    },
    startDate: {
      handler() {
        this.startDateFormatted = formatDate(this.startDate);
        this.discountData.start_date = this.startDate;
      },
    },
    $route() {
      this.resetForm();
    },
  },
});
