
import Vue from 'vue';

const CUR_YEAR = new Date().getFullYear();
const CUR_MON = new Date().getMonth();
const MIN_YEAR = 2007;

export default Vue.component('month-year-picker', {
  props: ['label', 'monthLabel', 'yearLabel'],
  data() {
    return {
      currentMonth: 0,
      currentYear: 0,
      months: [
        { text: 'Jan', value: 0 },
        { text: 'Feb', value: 1 },
        { text: 'Mar', value: 2 },
        { text: 'Apr', value: 3 },
        { text: 'May', value: 4 },
        { text: 'Jun', value: 5 },
        { text: 'Jul', value: 6 },
        { text: 'Aug', value: 7 },
        { text: 'Sep', value: 8 },
        { text: 'Oct', value: 9 },
        { text: 'Nov', value: 10 },
        { text: 'Dec', value: 11 },
      ],
    };
  },
  methods: {
    updateMonth(month: string) {
      this.$emit('update:currentMonth', month);
    },
    updateYear(year: string) {
      this.$emit('update:currentYear', year);
    },
  },
  mounted() {
    this.currentMonth = CUR_MON;
    this.currentYear = CUR_YEAR;
    this.updateMonth(this.currentMonth + '');
    this.updateYear(this.currentYear + '');
  },
  computed: {
    formLabel() {
      if (this.label) {
        return this.label;
      }
      return 'Please select a month and year.';
    },
    years() {
      const years = [];

      for (let i = CUR_YEAR; i >= MIN_YEAR; i--) {
        years.push(i);
      }

      return years;
    },
  },
});
