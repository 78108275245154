
import { Reporting } from '../../api/reports/reporting';
import AuthToken from '../../api/auth/Auth';
import MonthYearPicker from '../../components/dates/MonthYearPicker.vue';
import Vue from 'vue';

Vue.component('month-year-picker', MonthYearPicker);

export default Vue.extend({
  data() {
    return {
      month: 0,
      year: 0,
    };
  },
  methods: {
    handleMonthUpdate(month: number) {
      this.month = month;
    },
    handleYearUpdate(year: number) {
      this.year = year;
    },
    runReport() {
      Reporting.getSalesReport(this.month, this.year);
    },
  },
});
