
import DefaultButtons from '../forms/DefaultButtons.vue';
import FormAlerts from '../alerts/FormAlerts.vue';
import { FormProcessor } from '../../api/forms/processor';
import { RicUser, DefaultRicUser } from '../../api/ric_users/ric_user';
import { RicUserShow } from '../../api/ric_users/show';
import Vue from 'vue';

Vue.component('default-buttons', DefaultButtons);
Vue.component('form-alerts', FormAlerts);

export default Vue.component('ric-user-show', {
  data() {
    return {
      id: -1 as number,
      isNew: false,
      ricUserData: {} as RicUser,
      processor: new FormProcessor(),
      userGroups: [] as string[],
    };
  },
  methods: {
    loadForm() {
      if (this.id <= 0) {
        return;
      }
      RicUserShow.getData(this.id).then((response) => {
        this.ricUserData = RicUserShow.parseResponse(response);
      });
    },
    deleteForm() {
      if (this.processor === null || !this.processor.shouldDelete()) {
        return;
      }
      this.processor.deleteData(RicUserShow, this.id);
    },
    saveForm() {
      if (this.processor === null || !this.processor.shouldSave()) {
        return;
      }
      this.processor.saveData(RicUserShow, this.ricUserData, this.$refs.submitForm);
    },
    undoForm() {
      if (this.processor === null || !this.processor.undoForm()) {
        return;
      }
      this.loadForm();
    },
    deleteCallback() {
      this.$router.replace({ name: 'ric-users' });
    },
    updateCallback(formData: RicUser) {
      this.ricUserData = formData;
    },
    handleNew() {
      this.id = 0;
      this.isNew = true;
      this.ricUserData = DefaultRicUser();
      this.processor.setParams(
        this.id,
        this.ricUserData,
        DefaultRicUser(),
        this.updateCallback,
      );
      this.processor.readOnly = false;
    },
    loadUserGroups() {
      // Load the RIC user groups for the form.
      RicUserShow.allGroups().then((response) => {
        this.userGroups = response.data;
      });
    },
  },
  mounted() {
    this.loadUserGroups();

    const id = this.$route.params.id;
    if (id === 'new') {
      this.handleNew();
      return;
    }
    this.id = Number(id);
    if (this.id <= 0) {
      return;
    }
    this.processor.setParams(
      this.id,
      this.ricUserData,
      DefaultRicUser(),
      this.updateCallback,
      undefined,
      this.deleteCallback,
    );
    this.loadForm();
  },
  computed: {
    userGroupErrors(): string[] {
      let errors = [] as string[];
      if (!this.processor.errorHandler.ruleMap) {
        return errors;
      }

      const ruleMap = this.processor.errorHandler.ruleMap;

      if (ruleMap['admin_user_groups.level']) {
        errors = ruleMap['admin_user_groups.level'];
      }

      return errors;
    },
  },
});
