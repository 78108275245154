
import FormAlerts from '../alerts/FormAlerts.vue';
import { Rate } from '../../api/rates/rate';
import { formatDate, parseDate } from '../../api/dates/date_formatter';
import { RateDay } from '../../api/rates/rate_days';
import Vue from 'vue';
import {
  RateDateRange,
  DefaultRateDateRange,
} from '../../api/rates/rate_date_range';
import { FormProcessor } from '../../api/forms/processor';
import { ShowRateDateRange } from '../../api/rates/show_rate_date_range';

Vue.component('form-alerts', FormAlerts);

// Keep in sync with the RateDay model from the API server. These are too static to query.
const RATE_DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'M', 'H'];

export default Vue.component('rate-dates', {
  props: ['deleteCallback', 'rateId', 'index', 'showButtons', 'rateDateRange'],
  data() {
    return {
      copy: true,
      formProcessor: new FormProcessor(),
      internalError: '',
      rateDays: RATE_DAYS as string[],
      rateDateRangeData: {} as RateDateRange,
      selectedRateDayNames: [] as string[],
      startDate: '',
      startDateFormatted: '',
      endDate: '',
      endDateFormatted: '',
    };
  },
  methods: {
    parseDateInternal(date: string): string {
      return parseDate(date);
    },
    deleteDateRange() {
      if (this.rateDateRange.id > 0) {
        ShowRateDateRange.deleteData(this.rateDateRange.id);
      }
      this.deleteCallback(this.index);
    },
    processRateDateRange() {
      this.rateDateRange.rate_days.forEach((rateDay: RateDay) => {
        this.selectedRateDayNames.push(rateDay.name);
      });
      this.rateDateRange.should_copy = this.copy;
      this.startDate = this.rateDateRange.start;
      this.endDate = this.rateDateRange.end;

      this.formProcessor.setParams(
        this.rateDateRange.id,
        this.rateDateRange,
        DefaultRateDateRange(),
        this.updateCallback,
        this.saveCallback,
        this.deleteCallback,
      );

    },
    updateCallback(formData: any) {
      this.maybeRedirectToNewRate(formData);
    },
    saveCallback(formData: any) {
      this.maybeRedirectToNewRate(formData);
    },
    maybeRedirectToNewRate(formData: any) {
      const newRateId = Number(formData.rate_id);
      if (newRateId > 0 && newRateId !== Number(this.rateId)) {
        this.$router.push({
          name: 'manage-rate',
          params: { id: `${newRateId}` },
        });
        this.$emit('rate-change', newRateId);
      }
    },
    saveDateRange() {
      this.internalError = '';
      if (isNaN(this.rateId) || Number(this.rateId) <= 0) {
        this.internalError = 'You must save the golf rate first.';
        return;
      }
      if (this.formProcessor === null || !this.formProcessor.shouldSave()) {
        return;
      }
      this.rateDateRange.golf_rate_id = this.rateId;
      this.formProcessor.saveData(
        ShowRateDateRange,
        this.rateDateRange,
        this.$refs.submitForm,
      );
    },
  },
  mounted() {
    this.rateDateRangeData = this.rateDateRange;
    this.processRateDateRange();
  },
  watch: {
    copy() {
      this.rateDateRange.should_copy = this.copy;
    },
    rateDateRange() {
      this.processRateDateRange();
    },
    selectedRateDayNames() {
      this.rateDateRange.rate_days = this.selectedRateDayNames.map(
        (name: string) => {
          return { name } as RateDay;
        },
      );
    },
    startDate: {
      handler() {
        this.startDateFormatted = formatDate(this.startDate);
        this.rateDateRange.start = this.startDate;
      },
    },
    endDate: {
      handler() {
        this.endDateFormatted = formatDate(this.endDate);
        this.rateDateRange.end = this.endDate;
      },
    },
  },
});
