import axios from 'axios';
import {
  DefaultCreditCard,
 CreditCard 
} from '../credit_card/credit_card';

export class QueueIbeUtil {
  public static getCard(queueId: number): Promise<any> {
    return axios.get(`/api/availability/fetch_card/${queueId}`);
  }

  public static processCard(cardResponse: any) {
    const card = DefaultCreditCard();
    if (!cardResponse.data.id) {
      return card;
    }

    card.number = cardResponse.data.number;
    card.cvv = cardResponse.data.cvv;
    card.expmo = cardResponse.data.expmo;
    card.expyr = cardResponse.data.expyr;

    return card;
  }
}
