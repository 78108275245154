
import { AvailabilityQueueIndex } from '../../api/availability_queue/index';
import {
  AvailabilityQueueSummary,
  AvailabilityQueue,
  DefaultAvailabilityQueueSummary,
} from '../../api/availability_queue/availability_queue_summary';
import Vue from 'vue';
export default Vue.component('availability-queue-index', {
  data() {
    return {
      itemsPerPage: -1,
      loading: true,
      loadingText: 'Loading Data...',
      headers: [
        { text: 'Name', value: 'first_name' }, // Just show the name to prove it works this way.
        { text: 'Phone', value: 'phone' },
        { text: 'Email', value: 'email' },
        { text: 'Queue position', value: 'queuePosition' }, // queuePosition probably needs to be calculated from create date/time.
        { text: 'Booked?', value: 'is_booked' }, // Allow manual ticking? Or is this just a display that's automatically ticked upon booking?
        { text: 'Book', value: 'book' }, // Actual book link.
        // { text: 'Course name', value: 'courseName' },
        // { text: 'Last scrape', value: 'scrapedAt' },
        // { text: 'Date', value: 'readDate' },
        // { text: 'Sorting date', value: 'date' },
        // { text: 'Time', value: 'time' },
        // { text: 'Book', value: 'book' },
      ],
      summary: DefaultAvailabilityQueueSummary() as AvailabilityQueueSummary,
    };
  },
  methods: {
    itemRowBackground(item: any): string {
      if (item.courseId === 17) {
        return 'fore-row-gray';
      }
      return 'fore-row';
    },
  },
  mounted() {
    AvailabilityQueueIndex.getData().then((response) => {
      if (!response.data) {
        return;
      }
      this.summary = AvailabilityQueueIndex.parseResponse(response.data);
      this.loading = false;
    });
  },
});
