
import { EmailTemplateShow } from '../../api/email_templates/show';
import { DefaultTemplateItem } from '../../api/email_templates/email_template';
import Vue from 'vue';

export default Vue.extend({
  props: ['templateItem'],
  data() {
    return {
      templateText: '',
    };
  },
  methods: {
    loadTemplate() {
      EmailTemplateShow.getTemplateContent(this.templateItem).then(
        (response) => {
          this.templateText = response.data.content;
        },
      );
    },
    saveTemplate() {
      EmailTemplateShow.saveTemplateContent(
        this.templateItem,
        this.templateText,
      ).then((response) => {
        this.$emit('saved', response.data.message);
      });
    },
  },
  watch: {
    templateItem() {
      this.loadTemplate();
    },
  },
});
