
import { CourseList, CourseListItem } from '../../api/courses/list';
import Vue from 'vue';

export default Vue.component('course-selector', {
  props: [
    'courseRules',
    'listType',
    'preselected',
    'selectedCourse',
    'readOnly',
  ],
  data() {
    return {
      courseList: [] as CourseListItem[],
      selectedCourseInternal: 0,
    };
  },
  methods: {
    postProcess() {
      if (!this.preselected) {
        return;
      }
      for (const course of this.courseList) {
        // Maybe a course ID
        if (!Number.isNaN(Number(this.preselected))) {
          if (course.value == this.preselected) {
            this.selectedCourseInternal = Number(course.value);
            this.updateValue(course.value);
            return;
          }
        }
        // Maybe the course name
        if (
          course.text === this.preselected ||
          course.value === this.preselected
        ) {
          this.selectedCourseInternal = Number(course.value);
          this.updateValue(course.value);
          return;
        }
      }
    },
    updateValue(course: string) {
      this.$emit('update:selectedCourse', course);
    },
  },
  mounted() {
    if (this.listType === 'internal') {
      CourseList.getInternalCourses().then((response) => {
        this.courseList = CourseList.parseResponse(response);
        this.postProcess();
      });
      return;
    }

    CourseList.get().then((response) => {
      this.courseList = CourseList.parseResponse(response);
      this.postProcess();
    });
  },
  watch: {
    selectedCourse() {
      this.selectedCourseInternal = this.selectedCourse;
    },
  },
});
