import axios from 'axios';
import { HelpfulLink } from './HelpfulLink';

export class HelpfulLinkShow {
  public static async getData(id: number): Promise<any> {
    return axios.get(`/api/helpful_links/show/${id}`);
  }

  public static async deleteData(id: number): Promise<any> {
    return axios.delete(`/api/helpful_links/${id}`);
  }

  public static async saveData(helpfulLinkData: HelpfulLink): Promise<any> {
    if (helpfulLinkData.id === -1) {
      return axios.post('/api/helpful_links', {
        helpful_link: helpfulLinkData,
      });
    }
    return axios.put('/api/helpful_links/update', {
      id: helpfulLinkData.id,
      helpful_link: helpfulLinkData,
    });
  }

  public static parseResponse(response: any): HelpfulLink {
    const helpfulLink = {} as HelpfulLink;
    helpfulLink.id = response.data.id;
    helpfulLink.url = response.data.url;
    helpfulLink.description = response.data.description;
    helpfulLink.type = response.data.type;
    return helpfulLink;
  }
}
