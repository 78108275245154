export interface RicUser {
  id: number;
  email: string;
  name: string;
  password?: string;
  password_confirmation?: string;
  groups: string[];
}

const DEFAULT_RIC_USER: RicUser = {
  id: -1,
  email: '',
  name: '',
  groups: [],
};

export function DefaultRicUser(): RicUser {
  return JSON.parse(JSON.stringify(DEFAULT_RIC_USER));
}
